import React from 'react'
import { routerType } from '../../PublicNavigation'
import { KaryonModules } from '../../../../../../redux/user/userTypes'
import { PagesName } from '../../../types/pages'
import { CustomersPagePassionFroid } from '../../../../../../pages/private/Foodservice/PassionFroid/CustomersPage'
import { WarehousesPagePassionFroid } from '../../../../../../pages/private/Foodservice/PassionFroid/WarehousesPage'
import { ProductsPagePassionFroid } from '../../../../../../pages/private/Foodservice/PassionFroid/ProductsPage'
import { ViewPage } from '../../../../views/ViewPage'
import DashboardPage from '../../../../../../pages/private/Foodservice/Pages/Dashboard/DashboardPage'

export const PassionFroidRouter = (modules: KaryonModules[]): routerType[] => [
    {
        path: 'dashboard/',
        element: <DashboardPage />,
        title: PagesName.DASHBOARD,
    },
    {
        path: 'clients/',
        element: <CustomersPagePassionFroid />,
        title: PagesName.CLIENTS,
    },
    {
        path: 'depots/',
        element: <WarehousesPagePassionFroid />,
        title: PagesName.WAREHOUSES,
    },
    {
        path: 'produits/',
        element: <ProductsPagePassionFroid />,
        title: PagesName.PRODUCTS,
    },
    {
        path: 'vues/:viewId/',
        element: <ViewPage />,
        title: PagesName.VIEWS,
    },
]
