import React from 'react'
import PrivatePage from '../../../../components/features/GlobalPages/Private/PrivatePage'
import SellInMaquettes from '../../../../assets/maquette-retail-sell-in.png'
import { Grid } from '@mui/material'

export const SellInRetailMaquettes = () => {

    return (
        <PrivatePage >
            <Grid container>
                <img
                    src={SellInMaquettes}
                    style={{ width: "100%" }}
                    alt="maquettes sell in retail"
                />
            </Grid>
        </PrivatePage>
    )
}
