import React from 'react'
import PrivatePage from '../../../../components/features/GlobalPages/Private/PrivatePage'
import FranceboissonsMaquettes from '../../../../assets/maquette-franceboissons2.png'
import { Grid } from '@mui/material'

export const FranceBoissons = () => {

    return (
        <PrivatePage >
            <Grid container>
                <img
                    src={FranceboissonsMaquettes}
                    style={{ width: "100%" }}
                    alt="maquettes distriboissons"
                />
            </Grid>
        </PrivatePage>
    )
}
