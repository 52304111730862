import React, { useCallback, useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../../../redux/hooks'
import { Box, Grid } from '@mui/material'
import Kpis from '../../../../../../components/features/Kpis'
import { BasicCard } from '../../../../../../components/features/BasicCard';
import { TertiaryButton } from '../../../../../../components/ui/Button';
import ContactDialog from '../../../../Global/Import/dialog/contact/ContactDialog';
import { DialogData } from '../../../../Global/Import/Import';
import { EnvName, EnvStatus } from '../../../../../../redux/user/userTypes';
import { LoadingWithoutText } from '../../../../../../components/features/screens/LoadingScreen';
import { DataTableComponentNavigable } from '../../../../DataTableComponent';
import CampaignDialog from '../../../../../../components/features/campaign/CampaignDialog';
import { useNavigate } from 'react-router-dom';
import { fetchCampaigns, setupCampaign } from '../../../../../../redux/views/viewsSlice';
import { columnsCampaignsTab } from './campaigns/configsCampaigns';
import { costCardConfig, dataGridConfig, impactCardConfig, KPIsConfig, performanceCardConfig } from './configsPromoPage';
import { Units } from '../../../../../../redux/global/globalTypes';
import { TopFlopCampaigns } from './campaigns/TopFlopCampaigns';
import CampaignEmptyStateLogo from "../../../../../../assets/campaign_empty_state.svg"
import { PrimaryText } from '../../../../../../components/ui/Text';
import { ViewConfig, ViewsSteps } from '../../../../../../redux/views/viewsTypes';
import { DetailTab } from '../../../../../../components/ui/TabsForDataTableComponent';

const resetState = {
    isOpen: false,
    envName: EnvName.MYDISTRIB,
    status: EnvStatus.INACTIVATED,
}


const CampaignEmptyState = () => {
    const dispatch = useAppDispatch()

    const _setupCampaign = useCallback(
        (viewConfig: ViewConfig) =>
            dispatch(setupCampaign({ config: viewConfig })),
        [dispatch]
    )

    const handleCampaignClick = () => {
        _setupCampaign({
            steps: [ViewsSteps.CampaignFS]
        })
    }

    return (
        <Grid alignItems="center" textAlign="center" padding={4}>
            <PrimaryText>Bouhhh aucune campagne encore enregistrée...
                <br></br>
                Créez votre première en cliquant sur + Ajouter une campagne
            </PrimaryText>
            <Box
                component="img"
                sx={{ width: { xs: '150px', md: '12vw', cursor: "pointer" } }}
                src={CampaignEmptyStateLogo}
                alt="No Campaign"
                onClick={handleCampaignClick}
            />
        </Grid>
    )
}


export const PromoPageV2 = () => {
    const { campaigns } = useAppSelector((state) => state.views)
    const { env } = useAppSelector((state) => state.global)
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const _getCampaign = useCallback(
        async (distributor: string) => await dispatch(fetchCampaigns(distributor)),
        [dispatch]
    )
    //For ContactDialog
    const [dialogContactData, setDialogContactData] =
        useState<DialogData>(resetState)
    const openModaleContact = () => {
        setDialogContactData({ // TODO : Valider l'intérêt de reset l'env
            isOpen: true,
            envName: EnvName.MYDISTRIB,
            status: EnvStatus.INACTIVATED,
        })
    }
    const { promoKpis, impact, performance, cost, assortment, warehouses } = useAppSelector((state) => state.promotions)

    const kpis = promoKpis && KPIsConfig(promoKpis)
    const impactBloc = impact && impactCardConfig(impact)
    const perfBloc = performance && performanceCardConfig(performance)
    const costBloc = cost && costCardConfig(cost)
    const dataGridBloc = assortment && warehouses && dataGridConfig(assortment, warehouses)

    useEffect(() => {
        _getCampaign(env.name)
    }, [env.name])

    const handleNavigation = (row) => {
        navigate(`/${row.row.environment}/${row.row.distributor}/promo/${row.row._id}`)
    }

    return (
        <Grid
            container
            direction="column"
            p="1vw"
        >
            <CampaignDialog />
            <Grid container mb="2vw">
                <Kpis kpis={kpis} />
            </Grid>
            <Grid container flexWrap="nowrap" justifyContent="space-between">
                <Grid direction="column" flex="1" mr="1vw">
                    <BasicCard title={'Impact'} subtitle="Quel est l'impact de la promo sur les ventes globales ?" flex={"0 1 50%"} >
                        <Grid container flexWrap="wrap">
                            {impactBloc ? impactBloc.map((el) =>
                                <BasicCard title={el.title} subtitle={el.subtitle} flex={el.flex} >
                                    {el.component}
                                </BasicCard>
                            ) : <LoadingWithoutText />}
                        </Grid>
                    </BasicCard>
                    <BasicCard title={'Coûts'} subtitle="Est-ce que mes investissements contribuent à ma croissance financière ?" flex={"0 1 50%"} >
                        <Grid container flexWrap="wrap">
                            {costBloc ? costBloc.map((el) =>
                                <BasicCard title={el.title} subtitle={el.subtitle} flex={el.flex}>
                                    {el.component}
                                </BasicCard>
                            ) : <LoadingWithoutText />}
                        </Grid>
                    </BasicCard>
                    <TertiaryButton onClick={(e) => {
                        openModaleContact()
                    }} >
                        Demander un audit promo personnalisé
                    </TertiaryButton>
                    <ContactDialog
                        isOpen={dialogContactData.isOpen}
                        handleClose={() => {
                            setDialogContactData(resetState)
                        }}
                    />
                </Grid>
                <Grid direction="column" flex="1">
                    <BasicCard title={'Performance'} subtitle="Comment ma promo contribue à ma croissance et quels sont mes leviers ?" flex={"1 1 "} >
                        <Grid container flexWrap="wrap" >
                            {perfBloc ? perfBloc.map((el) =>
                                <BasicCard title={el.title} subtitle={el.subtitle} flex={el.flex}>
                                    {el.component}
                                </BasicCard>
                            ) : <LoadingWithoutText />}
                        </Grid>
                    </BasicCard>
                    <BasicCard title={'Campagnes'} subtitle="Quelles sont les campagnes les plus et les moins performantes ?" flex={"1 1"}  >
                        {campaigns.data.length >= 2 && (<TopFlopCampaigns campaigns={campaigns.data} />)}
                        <Grid mb={"1.5vh"}>
                            <Box
                                sx={{
                                    height: '100%',
                                    width: '100%',
                                    '& .MuiDataGrid-virtualScroller': {
                                        marginTop: '4vh !important',
                                        height: 'calc(100% - 4vh) !important',
                                    },
                                    '& .MuiDataGrid-virtualScrollerRenderZone': {
                                        height: '100%',
                                    },
                                    '& .MuiDataGrid-row': {
                                        height: '33% !important',
                                        maxHeight: '33% !important',
                                        minHeight: 'unset !important',
                                    },
                                    '& .MuiDataGrid-cell--withRenderer': {
                                        height: '100%',
                                        maxHeight: 'unset !important',
                                        minHeight: 'unset !important',
                                        width: 200,
                                    },
                                    '& .MuiDataGrid-row:last-child .MuiDataGrid-cell--withRenderer':
                                    {
                                        borderBottom: 'none',
                                    },
                                    '& .MuiDataGrid-cell': {
                                        height: 'auto !important',
                                        width: '20vw !important',
                                    },
                                    '& .MuiGrid-container': {
                                        justifyContent: 'center',
                                    },
                                }}
                            >
                                <DataTableComponentNavigable
                                    columns={columnsCampaignsTab(Units.kg, campaigns.unityType)}
                                    rows={campaigns.data}
                                    hideFooter
                                    getRowId={(row) => row._id}
                                    navigate={handleNavigation}

                                />
                            </Box>
                        </Grid>
                        {!campaigns.data.length && (<CampaignEmptyState />)}
                    </BasicCard>
                </Grid>
            </Grid >
            <Grid pt="2vh">
                <BasicCard flex={"1 1"}  >
                    {dataGridBloc ? <DetailTab data={dataGridBloc} /> : <LoadingWithoutText />}
                </BasicCard>
            </Grid>
        </Grid >
    )
}
