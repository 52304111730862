import { Grid, Box } from '@mui/material'
import {
    GridColumnHeaderParams,
    type GridColDef,
    type GridRenderCellParams,
} from '@mui/x-data-grid'
import React from 'react'
import LocatedDataGrid from './ImportsDataGrid'
import DoneIcon from '@mui/icons-material/Done'
import CloseIcon from '@mui/icons-material/Close'
import ChartBlock from '../../../../components/features/ChartBlock'
import { HistoricList } from '../../../../redux/imports/importsTypes'
import { BLACK, GREEN, RED } from '../../../../components/ui/Colors'
import { PrimaryText, SecondaryText } from '../../../../components/ui/Text'
import { periodsList } from '../../../../components/features/export/FsExportRawSelectStep'

interface OwnProps {
    list: HistoricList[]
}

const columns: GridColDef[] = [
    {
        field: 'filename',
        headerName: 'Nom du fichier',
        flex: 1,
        renderHeader: (params: GridColumnHeaderParams) => {
            return (
                <PrimaryText color={BLACK}>
                    {params.colDef.headerName}
                </PrimaryText>
            )
        },
        renderCell: (params: GridRenderCellParams) => {
            return <SecondaryText color={BLACK}>{params.value}</SecondaryText>
        },
    },
    {
        field: 'distributorName',
        headerName: 'Enseigne',
        flex: 1,
        renderHeader: (params: GridColumnHeaderParams) => {
            return (
                <PrimaryText color={BLACK}>
                    {params.colDef.headerName}
                </PrimaryText>
            )
        },
        renderCell: (params: GridRenderCellParams) => {
            return <SecondaryText color={BLACK}>{params.value}</SecondaryText>
        },
    },
    {
        field: 'period',
        headerName: 'Période',
        flex: 1,
        renderHeader: (params: GridColumnHeaderParams) => {
            return (
                <PrimaryText color={BLACK}>
                    {params.colDef.headerName}
                </PrimaryText>
            )
        },
        renderCell: (params: GridRenderCellParams) => {
            if (params.value) {
                const months = params.value.slice(0, 2)
                const year = params.value.slice(2, 6)

                return (
                    <SecondaryText color={BLACK}>
                        {`${periodsList[months]} ${year}`}
                    </SecondaryText>
                )
            }
        },
        sortable: false,
    },
    {
        field: 'status',
        headerName: 'Statut',
        flex: 1,
        renderHeader: (params: GridColumnHeaderParams) => {
            return (
                <PrimaryText color={BLACK}>
                    {params.colDef.headerName}
                </PrimaryText>
            )
        },
        renderCell: (params: GridRenderCellParams) => {
            const { status } = params.row
            if (status === 'error') {
                return <CloseIcon sx={{ color: RED }} />
            } else {
                return <DoneIcon sx={{ color: GREEN }} />
            }
        },
    },
]

const NoRows = () => {
    return (
        <Grid
            container
            justifyContent="center"
            alignContent="center"
            sx={{ height: '100%' }}
        >
            <PrimaryText color={BLACK} >
                Pas de résultat, importez un fichier.
            </PrimaryText>
        </Grid>
    )
}

const ImportHistoric = ({ list }: OwnProps) => {
    return (
        <ChartBlock title="Historique">
            <Box sx={{ height: '34vh' }}>
                <LocatedDataGrid
                    getRowId={(row) => row._id}
                    rows={list}
                    columns={columns}
                    components={{
                        NoRowsOverlay: () => <NoRows />,
                    }}
                />
            </Box>
        </ChartBlock>
    )
}

export default ImportHistoric
