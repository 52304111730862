import { Popover } from "@mui/material"
import React from "react"

export interface ActionMenuProps {
    open: boolean
    anchorEl: Element | null
    onClose: (e: React.MouseEvent) => void
    children: JSX.Element
}

export const ActionMenu = ({ open, anchorEl, onClose, children }: ActionMenuProps) => {
    return (
        <Popover
            anchorOrigin={{
                vertical: 'center',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'center',
                horizontal: 'left',
            }}
            open={open}
            anchorEl={anchorEl}
            onClose={onClose}
        >
            {children}
        </Popover>
    )
}