import React from 'react'
import { Grid, Tooltip } from '@mui/material'
import {
    GridColDef,
    GridColumnHeaderParams,
    GridRenderCellParams,
    GridRowsProp,
} from '@mui/x-data-grid'
import { DrillDownType } from './ProductsDrillDownContent'
import ShowChartIcon from '@mui/icons-material/ShowChart'
import { PrimaryText, SecondaryText } from '../../ui/Text'
import { BLACK } from '../../ui/Colors'
import {
    CategoriesItem,
    ClientItem,
    ProductItemFS,
    PromoItem,
    WarehouseItem,
} from '../../../redux/fsProducts/fsProductsTypes'
import {
    ProductItemRetail,
    StoreItem,
} from '../../../redux/retailProducts/retailProductsTypes'
import Tendency, { TooltipMessage } from '../../ui/Tendency'
import { Units } from '../../../redux/global/globalTypes'
import NumberWithSpace from '../../ui/NumberWithSpace'
import { roundIfNotZero } from '../../../helpers/roundIfNotZero'
import { useAppSelector } from '../../../redux/hooks'

export interface TabPanelProps {
    children: any
    value: number
    index: number
}
interface DataFS {
    id?: number
    warehouse?: string
    productName?: string
    warehouses: string
    products?: number
    type?: string
    clients: number
    volume: number
    volumeLastYear: number
    differenceWeight: number
}

interface DataRetail {
    VMM: number
    difference: number
    differenceVMM: number
    oldVMM: number
    values: number
    oldValues: number
    storeName?: string
    productName?: string
}

export const CellTooltip = ({ text }: { text: string }) => {
    return text.length > 17 ? (
        <Tooltip title={text}>
            <Grid textOverflow="ellipsis" overflow="hidden">
                <SecondaryText color={BLACK}>{text}</SecondaryText>
            </Grid>
        </Tooltip>
    ) : (
        <SecondaryText color={BLACK}>{text}</SecondaryText>
    )
}

export const ColumnHeaderTextTooltip = ({ text, tooltipIsNeeded = false }: { text: string, tooltipIsNeeded?: boolean }) => {
    return tooltipIsNeeded ? (
        <Tooltip title={text}>
            <Grid textOverflow="ellipsis" overflow="hidden">
                <PrimaryText color={BLACK}>{text}</PrimaryText>
            </Grid>
        </Tooltip>
    ) : (
        <PrimaryText color={BLACK}>{text}</PrimaryText>
    )
}


export const getFSRows = (
    data:
        | ClientItem[]
        | ProductItemFS[]
        | WarehouseItem[]
        | PromoItem[]
        | CategoriesItem[]
) => {
    let filteredData: DataFS[]
    if (data)
        filteredData = data.map((el, index) => {
            return {
                id: index,
                productName: el.productName,
                type: el.type,
                warehouse: el.warehouse,
                clients: el.clients,
                products: el.products,
                volume: el.volume,
                volumeLastYear: el.volumeLastYear,
                VMM: el.VMM,
                VMMLastYear: el.VMMLastYear,
                differenceVMM: el.differenceVMM,
                warehouses: el.warehouses,
                differenceWeight: el.differenceWeight,
                differenceClients: el.differenceClients,
                differenceWarehouses: el.differenceWarehouses,
                differenceProducts: el.differenceProducts,
            }
        })
    return filteredData
}

export const getRetailRows = (
    data: ProductItemRetail[] | StoreItem[] | string[]
) => {
    let filteredData: DataRetail[]

    if (data)
        filteredData = data.map((el, index) => {
            return {
                id: index,
                storeName: el.storeName,
                values: el.values,
                oldValues: el.oldValues,
                VMM: el.VMM,
                oldVMM: el.oldVMM,
                difference: el.difference,
                differenceVMM: el.differenceVMM,
                productName: el.productName,
            }
        })

    return filteredData
}

export const getWarehousesNotDistributorsColumns = (): GridColDef[] => {
    return [
        {
            field: 'warehouse',
            headerName: 'Dépôts',
            editable: false,
            flex: 1,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <PrimaryText color={BLACK}>
                        {params.colDef.headerName}
                    </PrimaryText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return <CellTooltip text={params.value} />
            },
        },
        {
            field: 'VMM',
            headerName: `VMM (${Units.kg})`,
            flex: 1,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <PrimaryText color={BLACK}>
                        {params.colDef.headerName}
                    </PrimaryText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
        {
            field: 'products',
            editable: false,
            headerName: 'Références',
            flex: 0.8,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <PrimaryText color={BLACK}>
                        {params.colDef.headerName}
                    </PrimaryText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
    ]
}


export const getWarehousesColumns = (type: DrillDownType, unit?: Units): GridColDef[] => {
    switch (type) {
        case DrillDownType.PRODUCTSPF:
            return [
                {
                    field: 'warehouse',
                    headerName: 'Dépôts',
                    editable: false,
                    flex: 1,
                    renderHeader: (params: GridColumnHeaderParams) => {
                        return (
                            <PrimaryText color={BLACK}>
                                {params.colDef.headerName}
                            </PrimaryText>
                        )
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return <CellTooltip text={params.value} />
                    },
                },
                {
                    field: 'volume',
                    headerName: `Volume (${Units.kg})`,
                    flex: 0.8,
                    editable: false,
                    renderHeader: (params: GridColumnHeaderParams) => {
                        return (
                            <PrimaryText color={BLACK}>
                                {params.colDef.headerName}
                            </PrimaryText>
                        )
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return (
                            <SecondaryText color={BLACK}>
                                <NumberWithSpace
                                    number={roundIfNotZero(params.value)}
                                />
                            </SecondaryText>
                        )
                    },
                },
                {
                    field: 'differenceWeight',
                    headerName: `Différence de volume`,
                    flex: 0.8,
                    editable: false,
                    renderHeader: () => {
                        return <ShowChartIcon fontSize="small" />
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return (
                            <Tendency
                                difference={params.row.differenceWeight}
                                unit={Units.kg}
                                tooltip={TooltipMessage.VOLUME}
                            />
                        )
                    },
                },
                {
                    field: 'volumeLastYear',
                    editable: false,
                    headerName: `Volume A-1 (${Units.kg})`,
                    flex: 0.8,
                    renderHeader: (params: GridColumnHeaderParams) => {
                        return (
                            <PrimaryText color={BLACK}>
                                {params.colDef.headerName}
                            </PrimaryText>
                        )
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return (
                            <SecondaryText color={BLACK}>
                                <NumberWithSpace
                                    number={roundIfNotZero(params.value)}
                                />
                            </SecondaryText>
                        )
                    },
                },
                {
                    field: 'VMM',
                    headerName: `VMM (${Units.kg})`,
                    flex: 1,
                    editable: false,
                    renderHeader: (params: GridColumnHeaderParams) => {
                        return (
                            <PrimaryText color={BLACK}>
                                {params.colDef.headerName}
                            </PrimaryText>
                        )
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return (
                            <SecondaryText color={BLACK}>
                                <NumberWithSpace
                                    number={roundIfNotZero(params.value)}
                                />
                            </SecondaryText>
                        )
                    },
                },
                {
                    field: 'differenceVMM',
                    headerName: `Différence de VMM`,
                    flex: 1,
                    editable: false,
                    renderHeader: () => {
                        return <ShowChartIcon fontSize="small" />
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return (
                            <SecondaryText color={BLACK}>
                                <Tendency
                                    difference={params.value}
                                    unit={Units.none}
                                    tooltip={TooltipMessage.VMM}
                                />
                            </SecondaryText>
                        )
                    },
                },
                {
                    field: 'VMMLastYear',
                    editable: false,
                    headerName: `VMM A-1 (${Units.kg})`,
                    flex: 1,
                    renderHeader: (params: GridColumnHeaderParams) => {
                        return (
                            <PrimaryText color={BLACK}>
                                {params.colDef.headerName}
                            </PrimaryText>
                        )
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return (
                            <SecondaryText color={BLACK}>
                                <NumberWithSpace
                                    number={roundIfNotZero(params.value)}
                                />
                            </SecondaryText>
                        )
                    },
                },
            ]
        case DrillDownType.PRODUCTSEPISAVEURS:
            return [
                {
                    field: 'warehouse',
                    headerName: 'Dépôts',
                    editable: false,
                    flex: 1,
                    renderHeader: (params: GridColumnHeaderParams) => {
                        return (
                            <PrimaryText color={BLACK}>
                                {params.colDef.headerName}
                            </PrimaryText>
                        )
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return <CellTooltip text={params.value} />
                    },
                },
                {
                    field: 'volume',
                    headerName: `Volume (${Units.kg})`,
                    flex: 0.8,
                    editable: false,
                    renderHeader: (params: GridColumnHeaderParams) => {
                        return (
                            <PrimaryText color={BLACK}>
                                {params.colDef.headerName}
                            </PrimaryText>
                        )
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return (
                            <SecondaryText color={BLACK}>
                                <NumberWithSpace
                                    number={roundIfNotZero(params.value)}
                                />
                            </SecondaryText>
                        )
                    },
                },
                {
                    field: 'differenceWeight',
                    headerName: `Différence de volume`,
                    flex: 0.8,
                    editable: false,
                    renderHeader: () => {
                        return <ShowChartIcon fontSize="small" />
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return (
                            <Tendency
                                difference={params.row.differenceWeight}
                                unit={Units.kg}
                                tooltip={TooltipMessage.VOLUME}
                            />
                        )
                    },
                },
                {
                    field: 'volumeLastYear',
                    editable: false,
                    headerName: `Volume A-1 (${Units.kg})`,
                    flex: 0.8,
                    renderHeader: (params: GridColumnHeaderParams) => {
                        return (
                            <PrimaryText color={BLACK}>
                                {params.colDef.headerName}
                            </PrimaryText>
                        )
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return (
                            <SecondaryText color={BLACK}>
                                <NumberWithSpace
                                    number={roundIfNotZero(params.value)}
                                />
                            </SecondaryText>
                        )
                    },
                },
                {
                    field: 'VMM',
                    headerName: `VMM (${Units.kgPerWarehouse})`,
                    flex: 1,
                    editable: false,
                    renderHeader: (params: GridColumnHeaderParams) => {
                        return (
                            <PrimaryText color={BLACK}>
                                {params.colDef.headerName}
                            </PrimaryText>
                        )
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return (
                            <SecondaryText color={BLACK}>
                                <NumberWithSpace
                                    number={roundIfNotZero(params.value)}
                                />
                            </SecondaryText>
                        )
                    },
                },
                {
                    field: 'differenceVMM',
                    headerName: `Différence de VMM`,
                    flex: 1,
                    editable: false,
                    renderHeader: () => {
                        return <ShowChartIcon fontSize="small" />
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return (
                            <SecondaryText color={BLACK}>
                                <Tendency
                                    difference={params.value}
                                    unit={Units.none}
                                    tooltip={TooltipMessage.VMM}
                                />
                            </SecondaryText>
                        )
                    },
                },
                {
                    field: 'VMMLastYear',
                    editable: false,
                    headerName: `VMM A-1 (${Units.kgPerWarehouse})`,
                    flex: 1,
                    renderHeader: (params: GridColumnHeaderParams) => {
                        return (
                            <PrimaryText color={BLACK}>
                                {params.colDef.headerName}
                            </PrimaryText>
                        )
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return (
                            <SecondaryText color={BLACK}>
                                <NumberWithSpace
                                    number={roundIfNotZero(params.value)}
                                />
                            </SecondaryText>
                        )
                    },
                },
            ]
        case DrillDownType.PRODUCTSMETRO:
            return [
                {
                    field: 'warehouse',
                    headerName: 'Dépôts',
                    editable: false,
                    flex: 1,
                    renderHeader: (params: GridColumnHeaderParams) => {
                        return (
                            <PrimaryText color={BLACK}>
                                {params.colDef.headerName}
                            </PrimaryText>
                        )
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return <CellTooltip text={params.value} />
                    },
                },
                {
                    field: 'volume',
                    headerName: `Volume (${unit})`,
                    flex: 0.8,
                    editable: false,
                    renderHeader: (params: GridColumnHeaderParams) => {
                        return (
                            <PrimaryText color={BLACK}>
                                {params.colDef.headerName}
                            </PrimaryText>
                        )
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return (
                            <SecondaryText color={BLACK}>
                                <NumberWithSpace
                                    number={roundIfNotZero(params.value)}
                                />
                            </SecondaryText>
                        )
                    },
                },
                {
                    field: 'differenceWeight',
                    headerName: `Différence de volume`,
                    flex: 0.8,
                    editable: false,
                    renderHeader: () => {
                        return <ShowChartIcon fontSize="small" />
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return (
                            <Tendency
                                difference={params.row.differenceWeight}
                                unit={unit}
                                tooltip={TooltipMessage.VOLUME}
                            />
                        )
                    },
                },
                {
                    field: 'volumeLastYear',
                    editable: false,
                    headerName: `Volume A-1 (${unit})`,
                    flex: 0.8,
                    renderHeader: (params: GridColumnHeaderParams) => {
                        return (
                            <PrimaryText color={BLACK}>
                                {params.colDef.headerName}
                            </PrimaryText>
                        )
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return (
                            <SecondaryText color={BLACK}>
                                <NumberWithSpace
                                    number={roundIfNotZero(params.value)}
                                />
                            </SecondaryText>
                        )
                    },
                },
                {
                    field: 'VMM',
                    headerName: `VMM (${unit === Units.uvc ? Units.uvcPerWarehouse : Units.kgPerWarehouse})`,
                    flex: 1,
                    editable: false,
                    renderHeader: (params: GridColumnHeaderParams) => {
                        return (
                            <PrimaryText color={BLACK}>
                                {params.colDef.headerName}
                            </PrimaryText>
                        )
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return (
                            <SecondaryText color={BLACK}>
                                <NumberWithSpace
                                    number={roundIfNotZero(params.value)}
                                />
                            </SecondaryText>
                        )
                    },
                },
                {
                    field: 'differenceVMM',
                    headerName: `Différence de VMM`,
                    flex: 1,
                    editable: false,
                    renderHeader: () => {
                        return <ShowChartIcon fontSize="small" />
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return (
                            <SecondaryText color={BLACK}>
                                <Tendency
                                    difference={params.value}
                                    unit={Units.none}
                                    tooltip={TooltipMessage.VMM}
                                />
                            </SecondaryText>
                        )
                    },
                },
                {
                    field: 'VMMLastYear',
                    editable: false,
                    headerName: `VMM A-1 (${unit === Units.uvc ? Units.uvcPerWarehouse : Units.kgPerWarehouse})`,
                    flex: 1,
                    renderHeader: (params: GridColumnHeaderParams) => {
                        return (
                            <PrimaryText color={BLACK}>
                                {params.colDef.headerName}
                            </PrimaryText>
                        )
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return (
                            <SecondaryText color={BLACK}>
                                <NumberWithSpace
                                    number={roundIfNotZero(params.value)}
                                />
                            </SecondaryText>
                        )
                    },
                },
            ]
        case DrillDownType.CLIENTSPF:
            return [
                {
                    field: 'warehouse',
                    headerName: 'Dépôts',
                    editable: false,
                    flex: 1,
                    renderHeader: (params: GridColumnHeaderParams) => {
                        return (
                            <PrimaryText color={BLACK}>
                                {params.colDef.headerName}
                            </PrimaryText>
                        )
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return <CellTooltip text={params.value} />
                    },
                },
                {
                    field: 'products',
                    editable: false,
                    headerName: 'Références',
                    flex: 0.8,
                    renderHeader: (params: GridColumnHeaderParams) => {
                        return (
                            <PrimaryText color={BLACK}>
                                {params.colDef.headerName}
                            </PrimaryText>
                        )
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return (
                            <SecondaryText color={BLACK}>
                                <NumberWithSpace
                                    number={roundIfNotZero(params.value)}
                                />
                            </SecondaryText>
                        )
                    },
                },
                {
                    field: 'volume',
                    headerName: `Volume (${Units.kg})`,
                    flex: 0.8,
                    editable: false,
                    renderHeader: (params: GridColumnHeaderParams) => {
                        return (
                            <PrimaryText color={BLACK}>
                                {params.colDef.headerName}
                            </PrimaryText>
                        )
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return (
                            <SecondaryText color={BLACK}>
                                <NumberWithSpace
                                    number={roundIfNotZero(params.value)}
                                />
                            </SecondaryText>
                        )
                    },
                },
                {
                    field: 'differenceWeight',
                    headerName: `Différence de volume`,
                    flex: 0.8,
                    editable: false,
                    renderHeader: () => {
                        return <ShowChartIcon fontSize="small" />
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return (
                            <Tendency
                                difference={params.row.differenceWeight}
                                unit={Units.kg}
                                tooltip={TooltipMessage.VOLUME}
                            />
                        )
                    },
                },
                {
                    field: 'volumeLastYear',
                    editable: false,
                    headerName: `Volume A-1 (${Units.kg})`,
                    flex: 0.8,
                    renderHeader: (params: GridColumnHeaderParams) => {
                        return (
                            <PrimaryText color={BLACK}>
                                {params.colDef.headerName}
                            </PrimaryText>
                        )
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return (
                            <SecondaryText color={BLACK}>
                                <NumberWithSpace
                                    number={roundIfNotZero(params.value)}
                                />
                            </SecondaryText>
                        )
                    },
                },
            ]
        case DrillDownType.CLIENTSEPISAVEURS:
            return [
                {
                    field: 'warehouse',
                    headerName: 'Dépôts',
                    editable: false,
                    flex: 1,
                    renderHeader: (params: GridColumnHeaderParams) => {
                        return (
                            <PrimaryText color={BLACK}>
                                {params.colDef.headerName}
                            </PrimaryText>
                        )
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return <CellTooltip text={params.value} />
                    },
                },
                {
                    field: 'products',
                    editable: false,
                    headerName: 'Références',
                    flex: 0.8,
                    renderHeader: (params: GridColumnHeaderParams) => {
                        return (
                            <PrimaryText color={BLACK}>
                                {params.colDef.headerName}
                            </PrimaryText>
                        )
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return (
                            <SecondaryText color={BLACK}>
                                <NumberWithSpace
                                    number={roundIfNotZero(params.value)}
                                />
                            </SecondaryText>
                        )
                    },
                },
                {
                    field: 'volume',
                    headerName: `Volume (${Units.kg})`,
                    flex: 0.8,
                    editable: false,
                    renderHeader: (params: GridColumnHeaderParams) => {
                        return (
                            <PrimaryText color={BLACK}>
                                {params.colDef.headerName}
                            </PrimaryText>
                        )
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return (
                            <SecondaryText color={BLACK}>
                                <NumberWithSpace
                                    number={roundIfNotZero(params.value)}
                                />
                            </SecondaryText>
                        )
                    },
                },
                {
                    field: 'differenceWeight',
                    headerName: `Différence de volume`,
                    flex: 0.8,
                    editable: false,
                    renderHeader: () => {
                        return <ShowChartIcon fontSize="small" />
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return (
                            <Tendency
                                difference={params.row.differenceWeight}
                                unit={Units.kg}
                                tooltip={TooltipMessage.VOLUME}
                            />
                        )
                    },
                },
                {
                    field: 'volumeLastYear',
                    editable: false,
                    headerName: `Volume A-1 (${Units.kg})`,
                    flex: 0.8,
                    renderHeader: (params: GridColumnHeaderParams) => {
                        return (
                            <PrimaryText color={BLACK}>
                                {params.colDef.headerName}
                            </PrimaryText>
                        )
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return (
                            <SecondaryText color={BLACK}>
                                <NumberWithSpace
                                    number={roundIfNotZero(params.value)}
                                />
                            </SecondaryText>
                        )
                    },
                },
                {
                    field: 'VMM',
                    headerName: `VMM (${Units.kgPerWarehouse})`,
                    flex: 1,
                    editable: false,
                    renderHeader: (params: GridColumnHeaderParams) => {
                        return (
                            <PrimaryText color={BLACK}>
                                {params.colDef.headerName}
                            </PrimaryText>
                        )
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return (
                            <SecondaryText color={BLACK}>
                                <NumberWithSpace
                                    number={roundIfNotZero(params.value)}
                                />
                            </SecondaryText>
                        )
                    },
                },
                {
                    field: 'differenceVMM',
                    headerName: `Différence de VMM`,
                    flex: 1,
                    editable: false,
                    renderHeader: () => {
                        return <ShowChartIcon fontSize="small" />
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return (
                            <SecondaryText color={BLACK}>
                                <Tendency
                                    difference={params.value}
                                    unit={Units.none}
                                    tooltip={TooltipMessage.VMM}
                                />
                            </SecondaryText>
                        )
                    },
                },
                {
                    field: 'VMMLastYear',
                    editable: false,
                    headerName: `VMM A-1 (${Units.kgPerWarehouse})`,
                    flex: 1,
                    renderHeader: (params: GridColumnHeaderParams) => {
                        return (
                            <PrimaryText color={BLACK}>
                                {params.colDef.headerName}
                            </PrimaryText>
                        )
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return (
                            <SecondaryText color={BLACK}>
                                <NumberWithSpace
                                    number={roundIfNotZero(params.value)}
                                />
                            </SecondaryText>
                        )
                    },
                },
            ]
        default:
            return [
                {
                    field: 'warehouse',
                    headerName: 'Dépôts',
                    editable: false,
                    flex: 1,
                    renderHeader: (params: GridColumnHeaderParams) => {
                        return (
                            <PrimaryText color={BLACK}>
                                {params.colDef.headerName}
                            </PrimaryText>
                        )
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return <CellTooltip text={params.value} />
                    },
                },
                {
                    field: 'clients',
                    editable: false,
                    headerName: 'Utilisateurs finaux',
                    flex: 0.8,
                    renderHeader: (params: GridColumnHeaderParams) => {
                        return (
                            <PrimaryText color={BLACK}>
                                {params.colDef.headerName}
                            </PrimaryText>
                        )
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return (
                            <SecondaryText color={BLACK}>
                                <NumberWithSpace
                                    number={roundIfNotZero(params.value)}
                                />
                            </SecondaryText>
                        )
                    },
                },
                {
                    field: 'differenceClients',
                    headerName: `Différence d'utilisateurs finaux`,
                    flex: 0.8,
                    editable: false,
                    renderHeader: () => {
                        return <ShowChartIcon fontSize="small" />
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return (
                            <Tendency
                                difference={params.row.differenceClients}
                                unit={Units.none}
                                tooltip={TooltipMessage.CUSTOMERS}
                            />
                        )
                    },
                },
                {
                    field: 'products',
                    editable: false,
                    headerName: 'Références',
                    flex: 0.8,
                    hide: type === DrillDownType.PRODUCTS,
                    renderHeader: (params: GridColumnHeaderParams) => {
                        return (
                            <PrimaryText color={BLACK}>
                                {params.colDef.headerName}
                            </PrimaryText>
                        )
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return (
                            <SecondaryText color={BLACK}>
                                <NumberWithSpace
                                    number={roundIfNotZero(params.value)}
                                />
                            </SecondaryText>
                        )
                    },
                },
                {
                    field: 'differenceProducts',
                    headerName: `Différence de références`,
                    flex: 0.8,
                    editable: false,
                    hide: type === DrillDownType.PRODUCTS,
                    renderHeader: () => {
                        return <ShowChartIcon fontSize="small" />
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return (
                            <Tendency
                                difference={params.row.differenceProducts}
                                unit={Units.none}
                                tooltip={TooltipMessage.PRODUCTS}
                            />
                        )
                    },
                },
                {
                    field: 'volume',
                    headerName: `Volume (${Units.kg})`,
                    flex: 0.8,
                    editable: false,
                    renderHeader: (params: GridColumnHeaderParams) => {
                        return (
                            <PrimaryText color={BLACK}>
                                {params.colDef.headerName}
                            </PrimaryText>
                        )
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return (
                            <SecondaryText color={BLACK}>
                                <NumberWithSpace
                                    number={roundIfNotZero(params.value)}
                                />
                            </SecondaryText>
                        )
                    },
                },
                {
                    field: 'differenceWeight',
                    headerName: `Différence de volume`,
                    flex: 0.8,
                    editable: false,
                    renderHeader: () => {
                        return <ShowChartIcon fontSize="small" />
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return (
                            <Tendency
                                difference={params.row.differenceWeight}
                                unit={Units.kg}
                                tooltip={TooltipMessage.VOLUME}
                            />
                        )
                    },
                },
                {
                    field: 'volumeLastYear',
                    editable: false,
                    headerName: `Volume A-1 (${Units.kg})`,
                    flex: 0.8,
                    renderHeader: (params: GridColumnHeaderParams) => {
                        return (
                            <PrimaryText color={BLACK}>
                                {params.colDef.headerName}
                            </PrimaryText>
                        )
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return (
                            <SecondaryText color={BLACK}>
                                <NumberWithSpace
                                    number={roundIfNotZero(params.value)}
                                />
                            </SecondaryText>
                        )
                    },
                },
                {
                    field: 'VMM',
                    headerName: `VMM (${Units.kg})`,
                    flex: 1,
                    editable: false,
                    renderHeader: (params: GridColumnHeaderParams) => {
                        return (
                            <PrimaryText color={BLACK}>
                                {params.colDef.headerName}
                            </PrimaryText>
                        )
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return (
                            <SecondaryText color={BLACK}>
                                <NumberWithSpace
                                    number={roundIfNotZero(params.value)}
                                />
                            </SecondaryText>
                        )
                    },
                },
                {
                    field: 'differenceVMM',
                    headerName: `Différence de VMM`,
                    flex: 1,
                    editable: false,
                    renderHeader: () => {
                        return <ShowChartIcon fontSize="small" />
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return (
                            <SecondaryText color={BLACK}>
                                <Tendency
                                    difference={params.value}
                                    unit={Units.none}
                                    tooltip={TooltipMessage.VMM}
                                />
                            </SecondaryText>
                        )
                    },
                },
                {
                    field: 'VMMLastYear',
                    editable: false,
                    headerName: `VMM A-1 (${Units.kg})`,
                    flex: 1,
                    renderHeader: (params: GridColumnHeaderParams) => {
                        return (
                            <PrimaryText color={BLACK}>
                                {params.colDef.headerName}
                            </PrimaryText>
                        )
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return (
                            <SecondaryText color={BLACK}>
                                <NumberWithSpace
                                    number={roundIfNotZero(params.value)}
                                />
                            </SecondaryText>
                        )
                    },
                },
            ]
    }
}

export const getProductsNotDistributedColumns = (): GridColDef[] => [
    {
        field: 'productName',
        headerName: 'Références',
        editable: false,
        flex: 1,
        renderHeader: (params: GridColumnHeaderParams) => {
            return (
                <PrimaryText color={BLACK}>
                    {params.colDef.headerName}
                </PrimaryText>
            )
        },
        renderCell: (params: GridRenderCellParams) => {
            return <CellTooltip text={params.value} />
        },
    },
    {
        field: 'VMM',
        headerName: `VMM (${Units.uvc})`,
        flex: 1,
        editable: false,
        renderHeader: (params: GridColumnHeaderParams) => {
            return (
                <PrimaryText color={BLACK}>
                    {params.colDef.headerName}
                </PrimaryText>
            )
        },
        renderCell: (params: GridRenderCellParams) => {
            return (
                <SecondaryText color={BLACK}>
                    <NumberWithSpace number={roundIfNotZero(params.value)} />
                </SecondaryText>
            )
        },
    },
    /*  {
         field: 'stores',
         editable: false,
         headerName: `PDV distributeurs`,
         flex: 1,
         renderHeader: (params: GridColumnHeaderParams) => {
             return (
                 <PrimaryText color={BLACK}>
                     {params.colDef.headerName}
                 </PrimaryText>
             )
         },
         renderCell: (params: GridRenderCellParams) => {
             return (
                 <SecondaryText color={BLACK}>
                     <NumberWithSpace number={roundIfNotZero(params.value)} />
                 </SecondaryText>
             )
         },
     }, */
]

export const getFsProductsNotDistributedColumns = (): GridColDef[] => [
    {
        field: 'productName',
        headerName: 'Références',
        editable: false,
        flex: 1,
        renderHeader: (params: GridColumnHeaderParams) => {
            return (
                <PrimaryText color={BLACK}>
                    {params.colDef.headerName}
                </PrimaryText>
            )
        },
        renderCell: (params: GridRenderCellParams) => {
            return <CellTooltip text={params.value} />
        },
    },
    {
        field: 'vmm',
        headerName: `VMM A (${Units.kg})`,
        flex: 1,
        editable: false,
        renderHeader: (params: GridColumnHeaderParams) => {
            return (
                <PrimaryText color={BLACK}>
                    {params.colDef.headerName}
                </PrimaryText>
            )
        },
        renderCell: (params: GridRenderCellParams) => {
            return (
                <SecondaryText color={BLACK}>
                    <NumberWithSpace
                        number={roundIfNotZero(params.value)}
                    />
                </SecondaryText>
            )
        },
    },
    {
        field: 'warehouses',
        editable: false,
        headerName: 'Dépôts',
        flex: 0.9,
        renderHeader: (params: GridColumnHeaderParams) => {
            return (
                <PrimaryText color={BLACK}>
                    {params.colDef.headerName}
                </PrimaryText>
            )
        },
        renderCell: (params: GridRenderCellParams) => {
            return (
                <SecondaryText color={BLACK}>
                    <NumberWithSpace
                        number={roundIfNotZero(params.value)}
                    />
                </SecondaryText>
            )
        },
    },
]

export const getProductsColumns = (type: DrillDownType, unit?: Units): GridColDef[] => {
    const vmmUnit =
        type === DrillDownType.WAREHOUSES
            ? `${Units.kg}`
            : `${Units.kgPerWarehouse}`
    switch (type) {
        case DrillDownType.STORES:
            return [
                {
                    field: 'productName',
                    headerName: 'Références',
                    editable: false,
                    flex: 4,
                    renderHeader: (params: GridColumnHeaderParams) => {
                        return (
                            <PrimaryText color={BLACK}>
                                {params.colDef.headerName}
                            </PrimaryText>
                        )
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return <CellTooltip text={params.value} />
                    },
                },
                {
                    field: 'values',
                    headerName: `Volume (${Units.uvc})`,
                    flex: 1,
                    editable: false,
                    renderHeader: (params: GridColumnHeaderParams) => {
                        return (
                            <PrimaryText color={BLACK}>
                                {params.colDef.headerName}
                            </PrimaryText>
                        )
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return (
                            <SecondaryText color={BLACK}>
                                <NumberWithSpace
                                    number={roundIfNotZero(params.value)}
                                />
                            </SecondaryText>
                        )
                    },
                },
                {
                    field: 'difference',
                    headerName: `Différence de volume`,
                    flex: 1,
                    editable: false,
                    renderHeader: () => {
                        return <ShowChartIcon fontSize="small" />
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return (
                            <SecondaryText color={BLACK}>
                                <Tendency
                                    difference={params.value}
                                    unit={Units.uvc}
                                    tooltip={TooltipMessage.VOLUME}
                                />
                            </SecondaryText>
                        )
                    },
                },
                {
                    field: 'oldValues',
                    headerName: `Volume A-1 (${Units.uvc})`,
                    editable: false,
                    flex: 1,
                    renderHeader: (params: GridColumnHeaderParams) => {
                        return (
                            <PrimaryText color={BLACK}>
                                {params.colDef.headerName}
                            </PrimaryText>
                        )
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return (
                            <SecondaryText color={BLACK}>
                                <NumberWithSpace
                                    number={roundIfNotZero(params.value)}
                                />
                            </SecondaryText>
                        )
                    },
                },
                {
                    field: 'VMM',
                    headerName: `VMM (${Units.uvc})`,
                    flex: 1,
                    editable: false,
                    renderHeader: (params: GridColumnHeaderParams) => {
                        return (
                            <PrimaryText color={BLACK}>
                                {params.colDef.headerName}
                            </PrimaryText>
                        )
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return (
                            <SecondaryText color={BLACK}>
                                <NumberWithSpace
                                    number={roundIfNotZero(params.value)}
                                />
                            </SecondaryText>
                        )
                    },
                },
                {
                    field: 'differenceVMM',
                    headerName: `Différence de VMM`,
                    flex: 1,
                    editable: false,
                    renderHeader: () => {
                        return <ShowChartIcon fontSize="small" />
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return (
                            <SecondaryText color={BLACK}>
                                <Tendency
                                    difference={params.value}
                                    unit={Units.uvc}
                                    tooltip={TooltipMessage.VMM}
                                />
                            </SecondaryText>
                        )
                    },
                },
                {
                    field: 'oldVMM',
                    editable: false,
                    headerName: `VMM A-1 (${Units.uvc})`,
                    flex: 1,
                    renderHeader: (params: GridColumnHeaderParams) => {
                        return (
                            <PrimaryText color={BLACK}>
                                {params.colDef.headerName}
                            </PrimaryText>
                        )
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return (
                            <SecondaryText color={BLACK}>
                                <NumberWithSpace
                                    number={roundIfNotZero(params.value)}
                                />
                            </SecondaryText>
                        )
                    },
                },
            ]
        case DrillDownType.WAREHOUSESEPISAVEURS:
            return [
                {
                    field: 'productName',
                    headerName: 'Références',
                    editable: false,
                    flex: 1,
                    renderHeader: (params: GridColumnHeaderParams) => {
                        return (
                            <PrimaryText color={BLACK}>
                                {params.colDef.headerName}
                            </PrimaryText>
                        )
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return <CellTooltip text={params.value} />
                    },
                },
                {
                    field: 'volume',
                    headerName: `Volume (${Units.kg})`,
                    flex: 1,
                    editable: false,
                    renderHeader: (params: GridColumnHeaderParams) => {
                        return (
                            <PrimaryText color={BLACK}>
                                {params.colDef.headerName}
                            </PrimaryText>
                        )
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return (
                            <SecondaryText color={BLACK}>
                                <NumberWithSpace
                                    number={roundIfNotZero(params.value)}
                                />
                            </SecondaryText>
                        )
                    },
                },
                {
                    field: 'differenceWeight',
                    headerName: `Différence de volume`,
                    flex: 1,
                    editable: false,
                    renderHeader: () => {
                        return <ShowChartIcon fontSize="small" />
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return (
                            <SecondaryText color={BLACK}>
                                <Tendency
                                    difference={params.value}
                                    unit={Units.kg}
                                    tooltip={TooltipMessage.VOLUME}
                                />
                            </SecondaryText>
                        )
                    },
                },
                {
                    field: 'volumeLastYear',
                    headerName: `Volume A-1 (${Units.kg})`,
                    editable: false,
                    flex: 1,
                    renderHeader: (params: GridColumnHeaderParams) => {
                        return (
                            <PrimaryText color={BLACK}>
                                {params.colDef.headerName}
                            </PrimaryText>
                        )
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return (
                            <SecondaryText color={BLACK}>
                                <NumberWithSpace
                                    number={roundIfNotZero(params.value)}
                                />
                            </SecondaryText>
                        )
                    },
                },
                {
                    field: 'VMM',
                    headerName: `VMM (${Units.kg})`,
                    flex: 1,
                    editable: false,
                    renderHeader: (params: GridColumnHeaderParams) => {
                        return (
                            <PrimaryText color={BLACK}>
                                {params.colDef.headerName}
                            </PrimaryText>
                        )
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return (
                            <SecondaryText color={BLACK}>
                                <NumberWithSpace
                                    number={roundIfNotZero(params.value)}
                                />
                            </SecondaryText>
                        )
                    },
                },
                {
                    field: 'differenceVMM',
                    headerName: `Différence de VMM`,
                    flex: 1,
                    editable: false,
                    renderHeader: () => {
                        return <ShowChartIcon fontSize="small" />
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return (
                            <SecondaryText color={BLACK}>
                                <Tendency
                                    difference={params.value}
                                    unit={Units.none}
                                    tooltip={TooltipMessage.VMM}
                                />
                            </SecondaryText>
                        )
                    },
                },
                {
                    field: 'VMMLastYear',
                    editable: false,
                    headerName: `VMM A-1 (${Units.kg})`,
                    flex: 1,
                    renderHeader: (params: GridColumnHeaderParams) => {
                        return (
                            <PrimaryText color={BLACK}>
                                {params.colDef.headerName}
                            </PrimaryText>
                        )
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return (
                            <SecondaryText color={BLACK}>
                                <NumberWithSpace
                                    number={roundIfNotZero(params.value)}
                                />
                            </SecondaryText>
                        )
                    },
                },
            ]
        case DrillDownType.WAREHOUSESPF:
            return [
                {
                    field: 'productName',
                    headerName: 'Références',
                    editable: false,
                    flex: 1,
                    renderHeader: (params: GridColumnHeaderParams) => {
                        return (
                            <PrimaryText color={BLACK}>
                                {params.colDef.headerName}
                            </PrimaryText>
                        )
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return <CellTooltip text={params.value} />
                    },
                },
                {
                    field: 'volume',
                    headerName: `Volume (${Units.kg})`,
                    flex: 1,
                    editable: false,
                    renderHeader: (params: GridColumnHeaderParams) => {
                        return (
                            <PrimaryText color={BLACK}>
                                {params.colDef.headerName}
                            </PrimaryText>
                        )
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return (
                            <SecondaryText color={BLACK}>
                                <NumberWithSpace
                                    number={roundIfNotZero(params.value)}
                                />
                            </SecondaryText>
                        )
                    },
                },
                {
                    field: 'differenceWeight',
                    headerName: `Différence de volume`,
                    flex: 1,
                    editable: false,
                    renderHeader: () => {
                        return <ShowChartIcon fontSize="small" />
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return (
                            <SecondaryText color={BLACK}>
                                <Tendency
                                    difference={params.value}
                                    unit={Units.kg}
                                    tooltip={TooltipMessage.VOLUME}
                                />
                            </SecondaryText>
                        )
                    },
                },
                {
                    field: 'volumeLastYear',
                    headerName: `Volume A-1 (${Units.kg})`,
                    editable: false,
                    flex: 1,
                    renderHeader: (params: GridColumnHeaderParams) => {
                        return (
                            <PrimaryText color={BLACK}>
                                {params.colDef.headerName}
                            </PrimaryText>
                        )
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return (
                            <SecondaryText color={BLACK}>
                                <NumberWithSpace
                                    number={roundIfNotZero(params.value)}
                                />
                            </SecondaryText>
                        )
                    },
                },
                {
                    field: 'VMM',
                    headerName: `VMM (${Units.kgPerWarehouse})`,
                    flex: 1,
                    editable: false,
                    renderHeader: (params: GridColumnHeaderParams) => {
                        return (
                            <PrimaryText color={BLACK}>
                                {params.colDef.headerName}
                            </PrimaryText>
                        )
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return (
                            <SecondaryText color={BLACK}>
                                <NumberWithSpace
                                    number={roundIfNotZero(params.value)}
                                />
                            </SecondaryText>
                        )
                    },
                },
                {
                    field: 'differenceVMM',
                    headerName: `Différence de VMM`,
                    flex: 1,
                    editable: false,
                    renderHeader: () => {
                        return <ShowChartIcon fontSize="small" />
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return (
                            <SecondaryText color={BLACK}>
                                <Tendency
                                    difference={params.value}
                                    unit={Units.none}
                                    tooltip={TooltipMessage.VMM}
                                />
                            </SecondaryText>
                        )
                    },
                },
                {
                    field: 'VMMLastYear',
                    editable: false,
                    headerName: `VMM A-1 (${Units.kgPerWarehouse})`,
                    flex: 1,
                    renderHeader: (params: GridColumnHeaderParams) => {
                        return (
                            <PrimaryText color={BLACK}>
                                {params.colDef.headerName}
                            </PrimaryText>
                        )
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return (
                            <SecondaryText color={BLACK}>
                                <NumberWithSpace
                                    number={roundIfNotZero(params.value)}
                                />
                            </SecondaryText>
                        )
                    },
                },
            ]
        case DrillDownType.CLIENTSSODEXO:
            return [
                {
                    field: 'productName',
                    headerName: 'Références',
                    editable: false,
                    flex: 1.8,
                    renderHeader: (params: GridColumnHeaderParams) => {
                        return (
                            <PrimaryText color={BLACK}>
                                {params.colDef.headerName}
                            </PrimaryText>
                        )
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return <CellTooltip text={params.value} />
                    },
                },
                {
                    field: 'volume',
                    headerName: `Volume A (${Units.kg})`,
                    flex: 0.9,
                    editable: false,
                    renderHeader: (params: GridColumnHeaderParams) => {
                        return (
                            <PrimaryText color={BLACK}>
                                {params.colDef.headerName}
                            </PrimaryText>
                        )
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return (
                            <SecondaryText color={BLACK}>
                                <NumberWithSpace
                                    number={roundIfNotZero(params.value)}
                                />
                            </SecondaryText>
                        )
                    },
                },
                {
                    field: 'differenceWeight',
                    headerName: `Différence de volume`,
                    flex: 0.9,
                    editable: false,
                    renderHeader: () => {
                        return <ShowChartIcon fontSize="small" />
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return (
                            <Tendency
                                difference={params.row.differenceWeight}
                                unit={Units.kg}
                                tooltip={TooltipMessage.VOLUME}
                            />
                        )
                    },
                },
                {
                    field: 'volumeLastYear',
                    editable: false,
                    headerName: `Volume A-1 (${Units.kg})`,
                    flex: 0.9,
                    renderHeader: (params: GridColumnHeaderParams) => {
                        return (
                            <PrimaryText color={BLACK}>
                                {params.colDef.headerName}
                            </PrimaryText>
                        )
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return (
                            <SecondaryText color={BLACK}>
                                <NumberWithSpace
                                    number={roundIfNotZero(params.value)}
                                />
                            </SecondaryText>
                        )
                    },
                },
                {
                    field: 'VMM',
                    headerName: `VMM (${Units.kg})`,
                    flex: 1,
                    editable: false,
                    renderHeader: (params: GridColumnHeaderParams) => {
                        return (
                            <PrimaryText color={BLACK}>
                                {params.colDef.headerName}
                            </PrimaryText>
                        )
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return (
                            <SecondaryText color={BLACK}>
                                <NumberWithSpace
                                    number={roundIfNotZero(params.value)}
                                />
                            </SecondaryText>
                        )
                    },
                },
                {
                    field: 'differenceVMM',
                    headerName: `Différence de VMM`,
                    flex: 1,
                    editable: false,
                    renderHeader: () => {
                        return <ShowChartIcon fontSize="small" />
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return (
                            <SecondaryText color={BLACK}>
                                <Tendency
                                    difference={params.row.differenceVMM}
                                    unit={Units.kg}
                                    tooltip={TooltipMessage.VMM}
                                />
                            </SecondaryText>
                        )
                    },
                },
                {
                    field: 'VMMLastYear',
                    editable: false,
                    headerName: `VMM (${Units.kg})`,
                    flex: 1,
                    renderHeader: (params: GridColumnHeaderParams) => {
                        return (
                            <PrimaryText color={BLACK}>
                                {params.colDef.headerName}
                            </PrimaryText>
                        )
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return (
                            <SecondaryText color={BLACK}>
                                <NumberWithSpace
                                    number={roundIfNotZero(params.value)}
                                />
                            </SecondaryText>
                        )
                    },
                },
            ]
        case DrillDownType.CLIENTSPF:
            return [
                {
                    field: 'productName',
                    headerName: 'Références',
                    editable: false,
                    flex: 1.8,
                    renderHeader: (params: GridColumnHeaderParams) => {
                        return (
                            <PrimaryText color={BLACK}>
                                {params.colDef.headerName}
                            </PrimaryText>
                        )
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return <CellTooltip text={params.value} />
                    },
                },
                {
                    field: 'warehouses',
                    editable: false,
                    headerName: 'Dépôts',
                    flex: 0.9,
                    renderHeader: (params: GridColumnHeaderParams) => {
                        return (
                            <PrimaryText color={BLACK}>
                                {params.colDef.headerName}
                            </PrimaryText>
                        )
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return (
                            <SecondaryText color={BLACK}>
                                <NumberWithSpace
                                    number={roundIfNotZero(params.value)}
                                />
                            </SecondaryText>
                        )
                    },
                },
                {
                    field: 'differenceWarehouses',
                    headerName: `Différence de dépôts`,
                    flex: 0.9,
                    editable: false,
                    renderHeader: () => {
                        return <ShowChartIcon fontSize="small" />
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return (
                            <Tendency
                                difference={params.row.differenceWarehouses}
                                unit={Units.none}
                                tooltip={TooltipMessage.WAREHOUSES}
                            />
                        )
                    },
                },
                {
                    field: 'volume',
                    headerName: `Volume A`,
                    flex: 0.9,
                    editable: false,
                    renderHeader: (params: GridColumnHeaderParams) => {
                        return (
                            <PrimaryText color={BLACK}>
                                {params.colDef.headerName}
                            </PrimaryText>
                        )
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return (
                            <SecondaryText color={BLACK}>
                                <NumberWithSpace
                                    number={roundIfNotZero(params.value)}
                                />
                            </SecondaryText>
                        )
                    },
                },
                {
                    field: 'differenceWeight',
                    headerName: `Différence de volume`,
                    flex: 0.9,
                    editable: false,
                    renderHeader: () => {
                        return <ShowChartIcon fontSize="small" />
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return (
                            <Tendency
                                difference={params.row.differenceWeight}
                                unit={Units.kg}
                                tooltip={TooltipMessage.VOLUME}
                            />
                        )
                    },
                },
                {
                    field: 'volumeLastYear',
                    editable: false,
                    headerName: `Volume A-1`,
                    flex: 0.9,
                    renderHeader: (params: GridColumnHeaderParams) => {
                        return (
                            <PrimaryText color={BLACK}>
                                {params.colDef.headerName}
                            </PrimaryText>
                        )
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return (
                            <SecondaryText color={BLACK}>
                                <NumberWithSpace
                                    number={roundIfNotZero(params.value)}
                                />
                            </SecondaryText>
                        )
                    },
                },
            ]
        case DrillDownType.CLIENTSEPISAVEURS:
            return [
                {
                    field: 'productName',
                    headerName: 'Références',
                    editable: false,
                    flex: 1.8,
                    renderHeader: (params: GridColumnHeaderParams) => {
                        return (
                            <PrimaryText color={BLACK}>
                                {params.colDef.headerName}
                            </PrimaryText>
                        )
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return <CellTooltip text={params.value} />
                    },
                },
                {
                    field: 'warehouses',
                    editable: false,
                    headerName: 'Dépôts',
                    flex: 0.9,
                    renderHeader: (params: GridColumnHeaderParams) => {
                        return (
                            <PrimaryText color={BLACK}>
                                {params.colDef.headerName}
                            </PrimaryText>
                        )
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return (
                            <SecondaryText color={BLACK}>
                                <NumberWithSpace
                                    number={roundIfNotZero(params.value)}
                                />
                            </SecondaryText>
                        )
                    },
                },
                {
                    field: 'differenceWarehouses',
                    headerName: `Différence de dépôts`,
                    flex: 0.9,
                    editable: false,
                    renderHeader: () => {
                        return <ShowChartIcon fontSize="small" />
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return (
                            <Tendency
                                difference={params.row.differenceWarehouses}
                                unit={Units.none}
                                tooltip={TooltipMessage.WAREHOUSES}
                            />
                        )
                    },
                },
                {
                    field: 'volume',
                    headerName: `Volume A`,
                    flex: 0.9,
                    editable: false,
                    renderHeader: (params: GridColumnHeaderParams) => {
                        return (
                            <PrimaryText color={BLACK}>
                                {params.colDef.headerName}
                            </PrimaryText>
                        )
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return (
                            <SecondaryText color={BLACK}>
                                <NumberWithSpace
                                    number={roundIfNotZero(params.value)}
                                />
                            </SecondaryText>
                        )
                    },
                },
                {
                    field: 'differenceWeight',
                    headerName: `Différence de volume`,
                    flex: 0.9,
                    editable: false,
                    renderHeader: () => {
                        return <ShowChartIcon fontSize="small" />
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return (
                            <Tendency
                                difference={params.row.differenceWeight}
                                unit={Units.kg}
                                tooltip={TooltipMessage.VOLUME}
                            />
                        )
                    },
                },
                {
                    field: 'volumeLastYear',
                    editable: false,
                    headerName: `Volume A-1`,
                    flex: 0.9,
                    renderHeader: (params: GridColumnHeaderParams) => {
                        return (
                            <PrimaryText color={BLACK}>
                                {params.colDef.headerName}
                            </PrimaryText>
                        )
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return (
                            <SecondaryText color={BLACK}>
                                <NumberWithSpace
                                    number={roundIfNotZero(params.value)}
                                />
                            </SecondaryText>
                        )
                    },
                },
                {
                    field: 'VMM',
                    headerName: `VMM (${Units.kgPerWarehouse})`,
                    flex: 1,
                    editable: false,
                    renderHeader: (params: GridColumnHeaderParams) => {
                        return (
                            <PrimaryText color={BLACK}>
                                {params.colDef.headerName}
                            </PrimaryText>
                        )
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return (
                            <SecondaryText color={BLACK}>
                                <NumberWithSpace
                                    number={roundIfNotZero(params.value)}
                                />
                            </SecondaryText>
                        )
                    },
                },
                {
                    field: 'differenceVMM',
                    headerName: `Différence de VMM`,
                    flex: 1,
                    editable: false,
                    renderHeader: () => {
                        return <ShowChartIcon fontSize="small" />
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return (
                            <SecondaryText color={BLACK}>
                                <Tendency
                                    difference={params.row.differenceVMM}
                                    unit={Units.none}
                                    tooltip={TooltipMessage.VMM}
                                />
                            </SecondaryText>
                        )
                    },
                },
                {
                    field: 'VMMLastYear',
                    editable: false,
                    headerName: `VMM (${Units.kgPerWarehouse})`,
                    flex: 1,
                    renderHeader: (params: GridColumnHeaderParams) => {
                        return (
                            <PrimaryText color={BLACK}>
                                {params.colDef.headerName}
                            </PrimaryText>
                        )
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return (
                            <SecondaryText color={BLACK}>
                                <NumberWithSpace
                                    number={roundIfNotZero(params.value)}
                                />
                            </SecondaryText>
                        )
                    },
                },
            ]
        case DrillDownType.CLIENTSCERCLEVERT:
            return [
                {
                    field: 'productName',
                    headerName: 'Références',
                    editable: false,
                    flex: 1.8,
                    renderHeader: (params: GridColumnHeaderParams) => {
                        return (
                            <PrimaryText color={BLACK}>
                                {params.colDef.headerName}
                            </PrimaryText>
                        )
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return <CellTooltip text={params.value} />
                    },
                },
                {
                    field: 'volume',
                    headerName: `Volume A`,
                    flex: 0.9,
                    editable: false,
                    renderHeader: (params: GridColumnHeaderParams) => {
                        return (
                            <PrimaryText color={BLACK}>
                                {params.colDef.headerName}
                            </PrimaryText>
                        )
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return (
                            <SecondaryText color={BLACK}>
                                <NumberWithSpace
                                    number={roundIfNotZero(params.value)}
                                />
                            </SecondaryText>
                        )
                    },
                },
                {
                    field: 'differenceWeight',
                    headerName: `Différence de volume`,
                    flex: 0.9,
                    editable: false,
                    renderHeader: () => {
                        return <ShowChartIcon fontSize="small" />
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return (
                            <Tendency
                                difference={params.row.differenceWeight}
                                unit={Units.kg}
                                tooltip={TooltipMessage.VOLUME}
                            />
                        )
                    },
                },
                {
                    field: 'volumeLastYear',
                    editable: false,
                    headerName: `Volume A-1`,
                    flex: 0.9,
                    renderHeader: (params: GridColumnHeaderParams) => {
                        return (
                            <PrimaryText color={BLACK}>
                                {params.colDef.headerName}
                            </PrimaryText>
                        )
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return (
                            <SecondaryText color={BLACK}>
                                <NumberWithSpace
                                    number={roundIfNotZero(params.value)}
                                />
                            </SecondaryText>
                        )
                    },
                },
            ]
        case DrillDownType.WAREHOUSESMETRO:
            return [
                {
                    field: 'productName',
                    headerName: 'Références',
                    editable: false,
                    flex: 1.8,
                    renderHeader: (params: GridColumnHeaderParams) => {
                        return (
                            <PrimaryText color={BLACK}>
                                {params.colDef.headerName}
                            </PrimaryText>
                        )
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return <CellTooltip text={params.value} />
                    },
                },
                {
                    field: 'warehouses',
                    editable: false,
                    headerName: 'Dépôts',
                    flex: 0.9,
                    renderHeader: (params: GridColumnHeaderParams) => {
                        return (
                            <PrimaryText color={BLACK}>
                                {params.colDef.headerName}
                            </PrimaryText>
                        )
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return (
                            <SecondaryText color={BLACK}>
                                <NumberWithSpace
                                    number={roundIfNotZero(params.value)}
                                />
                            </SecondaryText>
                        )
                    },
                },
                {
                    field: 'differenceWarehouses',
                    headerName: `Différence de dépôts`,
                    flex: 0.9,
                    editable: false,
                    renderHeader: () => {
                        return <ShowChartIcon fontSize="small" />
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return (
                            <Tendency
                                difference={params.row.differenceWarehouses}
                                unit={Units.none}
                                tooltip={TooltipMessage.WAREHOUSES}
                            />
                        )
                    },
                },
                {
                    field: 'volume',
                    headerName: `Volume A (${unit})`,
                    flex: 0.9,
                    editable: false,
                    renderHeader: (params: GridColumnHeaderParams) => {
                        return (
                            <PrimaryText color={BLACK}>
                                {params.colDef.headerName}
                            </PrimaryText>
                        )
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return (
                            <SecondaryText color={BLACK}>
                                <NumberWithSpace
                                    number={roundIfNotZero(params.value)}
                                />
                            </SecondaryText>
                        )
                    },
                },
                {
                    field: 'differenceWeight',
                    headerName: `Différence de volume`,
                    flex: 0.9,
                    editable: false,
                    renderHeader: () => {
                        return <ShowChartIcon fontSize="small" />
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return (
                            <Tendency
                                difference={params.row.differenceWeight}
                                unit={Units.kg}
                                tooltip={TooltipMessage.VOLUME}
                            />
                        )
                    },
                },
                {
                    field: 'volumeLastYear',
                    editable: false,
                    headerName: `Volume A-1 (${unit})`,
                    flex: 0.9,
                    renderHeader: (params: GridColumnHeaderParams) => {
                        return (
                            <PrimaryText color={BLACK}>
                                {params.colDef.headerName}
                            </PrimaryText>
                        )
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return (
                            <SecondaryText color={BLACK}>
                                <NumberWithSpace
                                    number={roundIfNotZero(params.value)}
                                />
                            </SecondaryText>
                        )
                    },
                },
                {
                    field: 'VMM',
                    headerName: `VMM (${unit}) `,
                    flex: 1,
                    editable: false,
                    renderHeader: (params: GridColumnHeaderParams) => {
                        return (
                            <PrimaryText color={BLACK}>
                                {params.colDef.headerName}
                            </PrimaryText>
                        )
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return (
                            <SecondaryText color={BLACK}>
                                <NumberWithSpace
                                    number={roundIfNotZero(params.value)}
                                />
                            </SecondaryText>
                        )
                    },
                },
                {
                    field: 'differenceVMM',
                    headerName: `Différence de VMM`,
                    flex: 1,
                    editable: false,
                    renderHeader: () => {
                        return <ShowChartIcon fontSize="small" />
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return (
                            <SecondaryText color={BLACK}>
                                <Tendency
                                    difference={params.row.differenceVMM}
                                    unit={Units.none}
                                    tooltip={TooltipMessage.VMM}
                                />
                            </SecondaryText>
                        )
                    },
                },
                {
                    field: 'VMMLastYear',
                    editable: false,
                    headerName: `VMM (${unit})`,
                    flex: 1,
                    renderHeader: (params: GridColumnHeaderParams) => {
                        return (
                            <PrimaryText color={BLACK}>
                                {params.colDef.headerName}
                            </PrimaryText>
                        )
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return (
                            <SecondaryText color={BLACK}>
                                <NumberWithSpace
                                    number={roundIfNotZero(params.value)}
                                />
                            </SecondaryText>
                        )
                    },
                },
            ]
        default:
            return [
                {
                    field: 'productName',
                    headerName: 'Références',
                    editable: false,
                    flex: 1.8,
                    renderHeader: (params: GridColumnHeaderParams) => {
                        return (
                            <PrimaryText color={BLACK}>
                                {params.colDef.headerName}
                            </PrimaryText>
                        )
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return <CellTooltip text={params.value} />
                    },
                },
                {
                    field: 'warehouses',
                    editable: false,
                    headerName: 'Dépôts',
                    flex: 0.9,
                    hide: type === DrillDownType.WAREHOUSES,
                    renderHeader: (params: GridColumnHeaderParams) => {
                        return (
                            <PrimaryText color={BLACK}>
                                {params.colDef.headerName}
                            </PrimaryText>
                        )
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return (
                            <SecondaryText color={BLACK}>
                                <NumberWithSpace
                                    number={roundIfNotZero(params.value)}
                                />
                            </SecondaryText>
                        )
                    },
                },
                {
                    field: 'differenceWarehouses',
                    headerName: `Différence de dépôts`,
                    flex: 0.9,
                    editable: false,
                    hide: type === DrillDownType.WAREHOUSES,
                    renderHeader: () => {
                        return <ShowChartIcon fontSize="small" />
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return (
                            <Tendency
                                difference={params.row.differenceWarehouses}
                                unit={Units.none}
                                tooltip={TooltipMessage.WAREHOUSES}
                            />
                        )
                    },
                },
                {
                    field: 'clients',
                    editable: false,
                    headerName: 'Utilisateurs finaux',
                    flex: 0.9,
                    renderHeader: (params: GridColumnHeaderParams) => {
                        return (
                            <PrimaryText color={BLACK}>
                                {params.colDef.headerName}
                            </PrimaryText>
                        )
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return (
                            <SecondaryText color={BLACK}>
                                <NumberWithSpace
                                    number={roundIfNotZero(params.value)}
                                />
                            </SecondaryText>
                        )
                    },
                },
                {
                    field: 'differenceClients',
                    headerName: `Différence de clients`,
                    flex: 0.9,
                    editable: false,
                    renderHeader: () => {
                        return <ShowChartIcon fontSize="small" />
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return (
                            <Tendency
                                difference={params.row.differenceClients}
                                unit={Units.none}
                                tooltip={TooltipMessage.CUSTOMERS}
                            />
                        )
                    },
                },
                {
                    field: 'volume',
                    headerName: `Volume A`,
                    flex: 0.9,
                    editable: false,
                    renderHeader: (params: GridColumnHeaderParams) => {
                        return (
                            <PrimaryText color={BLACK}>
                                {params.colDef.headerName}
                            </PrimaryText>
                        )
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return (
                            <SecondaryText color={BLACK}>
                                <NumberWithSpace
                                    number={roundIfNotZero(params.value)}
                                />
                            </SecondaryText>
                        )
                    },
                },
                {
                    field: 'differenceWeight',
                    headerName: `Différence de volume`,
                    flex: 0.9,
                    editable: false,
                    renderHeader: () => {
                        return <ShowChartIcon fontSize="small" />
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return (
                            <Tendency
                                difference={params.row.differenceWeight}
                                unit={Units.kg}
                                tooltip={TooltipMessage.VOLUME}
                            />
                        )
                    },
                },
                {
                    field: 'volumeLastYear',
                    editable: false,
                    headerName: `Volume A-1`,
                    flex: 0.9,
                    renderHeader: (params: GridColumnHeaderParams) => {
                        return (
                            <PrimaryText color={BLACK}>
                                {params.colDef.headerName}
                            </PrimaryText>
                        )
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return (
                            <SecondaryText color={BLACK}>
                                <NumberWithSpace
                                    number={roundIfNotZero(params.value)}
                                />
                            </SecondaryText>
                        )
                    },
                },
                {
                    field: 'VMM',
                    headerName: `VMM (${vmmUnit})`,
                    flex: 1,
                    editable: false,
                    renderHeader: (params: GridColumnHeaderParams) => {
                        return (
                            <PrimaryText color={BLACK}>
                                {params.colDef.headerName}
                            </PrimaryText>
                        )
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return (
                            <SecondaryText color={BLACK}>
                                <NumberWithSpace
                                    number={roundIfNotZero(params.value)}
                                />
                            </SecondaryText>
                        )
                    },
                },
                {
                    field: 'differenceVMM',
                    headerName: `Différence de VMM`,
                    flex: 1,
                    editable: false,
                    renderHeader: () => {
                        return <ShowChartIcon fontSize="small" />
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return (
                            <SecondaryText color={BLACK}>
                                <Tendency
                                    difference={params.row.differenceVMM}
                                    unit={vmmUnit}
                                    tooltip={TooltipMessage.VMM}
                                />
                            </SecondaryText>
                        )
                    },
                },
                {
                    field: 'VMMLastYear',
                    editable: false,
                    headerName: `VMM (${vmmUnit})`,
                    flex: 1,
                    renderHeader: (params: GridColumnHeaderParams) => {
                        return (
                            <PrimaryText color={BLACK}>
                                {params.colDef.headerName}
                            </PrimaryText>
                        )
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return (
                            <SecondaryText color={BLACK}>
                                <NumberWithSpace
                                    number={roundIfNotZero(params.value)}
                                />
                            </SecondaryText>
                        )
                    },
                },
            ]
    }
}

export const getProductsColumnsForTGM = (type: DrillDownType): GridColDef[] => {
    return [
        {
            field: 'productName',
            headerName: 'Références',
            editable: false,
            flex: 1.8,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <PrimaryText color={BLACK}>
                        {params.colDef.headerName}
                    </PrimaryText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return <CellTooltip text={params.value} />
            },
        },

        {
            field: 'clients',
            editable: false,
            headerName: 'Transactions',
            flex: 0.9,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <PrimaryText color={BLACK}>
                        {params.colDef.headerName}
                    </PrimaryText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace number={params.value} />
                    </SecondaryText>
                )
            },
        },
        {
            field: 'volume',
            headerName: `Volume A`,
            flex: 0.9,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <PrimaryText color={BLACK}>
                        {params.colDef.headerName}
                    </PrimaryText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
        {
            field: 'differenceWeight',
            headerName: `Différence de volume`,
            flex: 0.9,
            editable: false,
            renderHeader: () => {
                return <ShowChartIcon fontSize="small" />
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <Tendency
                        difference={params.row.differenceWeight}
                        unit={Units.kg}
                        tooltip={TooltipMessage.VOLUME}
                    />
                )
            },
        },
        {
            field: 'volumeLastYear',
            editable: false,
            headerName: `Volume A-1`,
            flex: 0.9,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <PrimaryText color={BLACK}>
                        {params.colDef.headerName}
                    </PrimaryText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
    ]
}

export const getCategoriesSodexoColumns = (
    type: DrillDownType
): GridColDef[] => {
    return [
        {
            field: 'type',
            headerName: 'Entitée',
            editable: false,
            flex: 1.8,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <PrimaryText color={BLACK}>
                        {params.colDef.headerName}
                    </PrimaryText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return <CellTooltip text={params.value} />
            },
        },
        {
            field: 'volume',
            headerName: `Volume (${Units.kg})`,
            flex: 0.9,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <PrimaryText color={BLACK}>
                        {params.colDef.headerName}
                    </PrimaryText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
        {
            field: 'differenceWeight',
            headerName: `Différence de volume`,
            flex: 0.9,
            editable: false,
            renderHeader: () => {
                return <ShowChartIcon fontSize="small" />
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <Tendency
                        difference={params.row.differenceWeight}
                        unit={Units.kg}
                        tooltip={TooltipMessage.VOLUME}
                    />
                )
            },
        },
        {
            field: 'volumeLastYear',
            headerName: `Volume A-1 (${Units.kg})`,
            flex: 0.9,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <PrimaryText color={BLACK}>
                        {params.colDef.headerName}
                    </PrimaryText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
        {
            field: 'VMM',
            headerName: `VMM (${Units.kg})`,
            flex: 1,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <PrimaryText color={BLACK}>
                        {params.colDef.headerName}
                    </PrimaryText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
        {
            field: 'differenceVMM',
            headerName: `Différence de VMM`,
            flex: 1,
            editable: false,
            renderHeader: () => {
                return <ShowChartIcon fontSize="small" />
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <Tendency
                            difference={params.row.differenceVMM}
                            unit={Units.kg}
                            tooltip={TooltipMessage.VMM}
                        />
                    </SecondaryText>
                )
            },
        },
        {
            field: 'VMMLastYear',
            editable: false,
            headerName: `VMM (${Units.kg})`,
            flex: 1,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <PrimaryText color={BLACK}>
                        {params.colDef.headerName}
                    </PrimaryText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
    ]
}

export const getCategoriesMetroColumns = (): GridColDef[] => {
    return [
        {
            field: 'type',
            headerName: 'Catégories',
            editable: false,
            flex: 1.8,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <PrimaryText color={BLACK}>
                        {params.colDef.headerName}
                    </PrimaryText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return <CellTooltip text={params.value} />
            },
        },
        {
            field: 'ca',
            headerName: `CA en A (${Units.euro})`,
            flex: 0.9,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <PrimaryText color={BLACK}>
                        {params.colDef.headerName}
                    </PrimaryText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
        {
            field: 'differenceCA',
            headerName: `Différence de CA`,
            flex: 0.9,
            editable: false,
            renderHeader: () => {
                return <ShowChartIcon fontSize="small" />
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <Tendency
                        difference={params.value}
                        unit={Units.none}
                        tooltip={TooltipMessage.CA}
                    />
                )
            },
        },
        {
            field: 'caLastYear',
            headerName: `CA en A-1 (${Units.euro})`,
            flex: 0.9,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <PrimaryText color={BLACK}>
                        {params.colDef.headerName}
                    </PrimaryText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
        {
            field: 'percentageCA',
            headerName: `%CA en A(${Units.euro})`,
            flex: 1,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <PrimaryText color={BLACK}>
                        {params.colDef.headerName}
                    </PrimaryText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
        {
            field: 'differencePercentageCA',
            headerName: `Différence de %CA`,
            flex: 1,
            editable: false,
            renderHeader: () => {
                return <ShowChartIcon fontSize="small" />
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <Tendency
                            difference={params.value}
                            unit={Units.percent}
                            tooltip={TooltipMessage.SHARE_OF_TURNOVER}
                        />
                    </SecondaryText>
                )
            },
        },
        {
            field: 'percentageCALastYear',
            headerName: `%CA en A-1 (${Units.euro})`,
            editable: false,
            flex: 1,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <PrimaryText color={BLACK}>
                        {params.colDef.headerName}
                    </PrimaryText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
    ]
}

export const getClientsColumns = (type: DrillDownType): GridColDef[] => {
    const vmmUnit =
        type === DrillDownType.WAREHOUSES
            ? `${Units.kg}`
            : `${Units.kgPerWarehouse}`
    switch (type) {
        case DrillDownType.PRODUCTSPF:
        case DrillDownType.PRODUCTSEPISAVEURS:
            return [
                {
                    field: 'type',
                    headerName: 'Sous-sous-types',
                    editable: false,
                    flex: 1.8,
                    renderHeader: (params: GridColumnHeaderParams) => {
                        return (
                            <PrimaryText color={BLACK}>
                                {params.colDef.headerName}
                            </PrimaryText>
                        )
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return <CellTooltip text={params.value} />
                    },
                },
                {
                    field: 'warehouses',
                    headerName: 'Dépôts',
                    flex: 0.9,
                    editable: false,
                    renderHeader: (params: GridColumnHeaderParams) => {
                        return (
                            <PrimaryText color={BLACK}>
                                {params.colDef.headerName}
                            </PrimaryText>
                        )
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return (
                            <SecondaryText color={BLACK}>
                                <NumberWithSpace
                                    number={roundIfNotZero(params.value)}
                                />
                            </SecondaryText>
                        )
                    },
                },
                {
                    field: 'differenceWarehouses',
                    headerName: `Différence de dépôts`,
                    flex: 0.9,
                    editable: false,
                    renderHeader: () => {
                        return <ShowChartIcon fontSize="small" />
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return (
                            <Tendency
                                difference={params.value}
                                unit={Units.none}
                                tooltip={TooltipMessage.WAREHOUSES}
                            />
                        )
                    },
                },
                {
                    field: 'volume',
                    headerName: `Volume (${Units.kg})`,
                    flex: 0.9,
                    editable: false,
                    renderHeader: (params: GridColumnHeaderParams) => {
                        return (
                            <PrimaryText color={BLACK}>
                                {params.colDef.headerName}
                            </PrimaryText>
                        )
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return (
                            <SecondaryText color={BLACK}>
                                <NumberWithSpace
                                    number={roundIfNotZero(params.value)}
                                />
                            </SecondaryText>
                        )
                    },
                },
                {
                    field: 'differenceWeight',
                    headerName: `Différence de volume`,
                    flex: 0.9,
                    editable: false,
                    renderHeader: () => {
                        return <ShowChartIcon fontSize="small" />
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return (
                            <Tendency
                                difference={params.row.differenceWeight}
                                unit={Units.kg}
                                tooltip={TooltipMessage.VOLUME}
                            />
                        )
                    },
                },
                {
                    field: 'volumeLastYear',
                    headerName: `Volume A-1 (${Units.kg})`,
                    flex: 0.9,
                    editable: false,
                    renderHeader: (params: GridColumnHeaderParams) => {
                        return (
                            <PrimaryText color={BLACK}>
                                {params.colDef.headerName}
                            </PrimaryText>
                        )
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return (
                            <SecondaryText color={BLACK}>
                                <NumberWithSpace
                                    number={roundIfNotZero(params.value)}
                                />
                            </SecondaryText>
                        )
                    },
                },
                {
                    field: 'VMM',
                    headerName: `VMM (${vmmUnit})`,
                    flex: 1,
                    editable: false,
                    renderHeader: (params: GridColumnHeaderParams) => {
                        return (
                            <PrimaryText color={BLACK}>
                                {params.colDef.headerName}
                            </PrimaryText>
                        )
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return (
                            <SecondaryText color={BLACK}>
                                <NumberWithSpace
                                    number={roundIfNotZero(params.value)}
                                />
                            </SecondaryText>
                        )
                    },
                },
                {
                    field: 'differenceVMM',
                    headerName: `Différence de VMM`,
                    flex: 1,
                    editable: false,
                    renderHeader: () => {
                        return <ShowChartIcon fontSize="small" />
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return (
                            <SecondaryText color={BLACK}>
                                <Tendency
                                    difference={params.row.differenceVMM}
                                    unit={Units.kgPerWarehouse}
                                    tooltip={TooltipMessage.VMM}
                                />
                            </SecondaryText>
                        )
                    },
                },
                {
                    field: 'VMMLastYear',
                    editable: false,
                    headerName: `VMM (${vmmUnit})`,
                    flex: 1,
                    renderHeader: (params: GridColumnHeaderParams) => {
                        return (
                            <PrimaryText color={BLACK}>
                                {params.colDef.headerName}
                            </PrimaryText>
                        )
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return (
                            <SecondaryText color={BLACK}>
                                <NumberWithSpace
                                    number={roundIfNotZero(params.value)}
                                />
                            </SecondaryText>
                        )
                    },
                },
            ]
        case DrillDownType.PRODUCTSCERCLEVERT:
        case DrillDownType.PRODUCTSPF:
            return [
                {
                    field: 'type',
                    headerName: 'Sous-sous-types',
                    editable: false,
                    flex: 1.8,
                    renderHeader: (params: GridColumnHeaderParams) => {
                        return (
                            <PrimaryText color={BLACK}>
                                {params.colDef.headerName}
                            </PrimaryText>
                        )
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return <CellTooltip text={params.value} />
                    },
                },
                {
                    field: 'volume',
                    headerName: `Volume (${Units.kg})`,
                    flex: 0.9,
                    editable: false,
                    renderHeader: (params: GridColumnHeaderParams) => {
                        return (
                            <PrimaryText color={BLACK}>
                                {params.colDef.headerName}
                            </PrimaryText>
                        )
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return (
                            <SecondaryText color={BLACK}>
                                <NumberWithSpace
                                    number={roundIfNotZero(params.value)}
                                />
                            </SecondaryText>
                        )
                    },
                },
                {
                    field: 'differenceWeight',
                    headerName: `Différence de volume`,
                    flex: 0.9,
                    editable: false,
                    renderHeader: () => {
                        return <ShowChartIcon fontSize="small" />
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return (
                            <Tendency
                                difference={params.row.differenceWeight}
                                unit={Units.kg}
                                tooltip={TooltipMessage.VOLUME}
                            />
                        )
                    },
                },
                {
                    field: 'volumeLastYear',
                    headerName: `Volume A-1 (${Units.kg})`,
                    flex: 0.9,
                    editable: false,
                    renderHeader: (params: GridColumnHeaderParams) => {
                        return (
                            <PrimaryText color={BLACK}>
                                {params.colDef.headerName}
                            </PrimaryText>
                        )
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return (
                            <SecondaryText color={BLACK}>
                                <NumberWithSpace
                                    number={roundIfNotZero(params.value)}
                                />
                            </SecondaryText>
                        )
                    },
                },
            ]
        case DrillDownType.WAREHOUSESPF:
            return [
                {
                    field: 'type',
                    headerName: 'Sous-sous-types',
                    editable: false,
                    flex: 1.8,
                    renderHeader: (params: GridColumnHeaderParams) => {
                        return (
                            <PrimaryText color={BLACK}>
                                {params.colDef.headerName}
                            </PrimaryText>
                        )
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return <CellTooltip text={params.value} />
                    },
                },
                {
                    field: 'products',
                    headerName: 'Références',
                    flex: 0.9,
                    editable: false,
                    renderHeader: (params: GridColumnHeaderParams) => {
                        return (
                            <PrimaryText color={BLACK}>
                                {params.colDef.headerName}
                            </PrimaryText>
                        )
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return (
                            <SecondaryText color={BLACK}>
                                <NumberWithSpace
                                    number={roundIfNotZero(params.value)}
                                />
                            </SecondaryText>
                        )
                    },
                },
                {
                    field: 'differenceProducts',
                    headerName: `Différence de références`,
                    flex: 0.9,
                    editable: false,
                    renderHeader: () => {
                        return <ShowChartIcon fontSize="small" />
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return (
                            <Tendency
                                difference={params.row.differenceProducts}
                                unit={Units.none}
                                tooltip={TooltipMessage.PRODUCTS}
                            />
                        )
                    },
                },
                {
                    field: 'volume',
                    headerName: `Volume (${Units.kg})`,
                    flex: 0.9,
                    editable: false,
                    renderHeader: (params: GridColumnHeaderParams) => {
                        return (
                            <PrimaryText color={BLACK}>
                                {params.colDef.headerName}
                            </PrimaryText>
                        )
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return (
                            <SecondaryText color={BLACK}>
                                <NumberWithSpace
                                    number={roundIfNotZero(params.value)}
                                />
                            </SecondaryText>
                        )
                    },
                },
                {
                    field: 'differenceWeight',
                    headerName: `Différence de volume`,
                    flex: 0.9,
                    editable: false,
                    renderHeader: () => {
                        return <ShowChartIcon fontSize="small" />
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return (
                            <Tendency
                                difference={params.row.differenceWeight}
                                unit={Units.kg}
                                tooltip={TooltipMessage.VOLUME}
                            />
                        )
                    },
                },
                {
                    field: 'volumeLastYear',
                    headerName: `Volume A-1 (${Units.kg})`,
                    flex: 0.9,
                    editable: false,
                    renderHeader: (params: GridColumnHeaderParams) => {
                        return (
                            <PrimaryText color={BLACK}>
                                {params.colDef.headerName}
                            </PrimaryText>
                        )
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return (
                            <SecondaryText color={BLACK}>
                                <NumberWithSpace
                                    number={roundIfNotZero(params.value)}
                                />
                            </SecondaryText>
                        )
                    },
                },
            ]
        case DrillDownType.PRODUCTSSODEXO:
            return [
                {
                    field: 'type',
                    headerName: `Types d'utilisateurs`,
                    editable: false,
                    flex: 1.8,
                    renderHeader: (params: GridColumnHeaderParams) => {
                        return (
                            <PrimaryText color={BLACK}>
                                {params.colDef.headerName}
                            </PrimaryText>
                        )
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return <CellTooltip text={params.value} />
                    },
                },
                {
                    field: 'volume',
                    headerName: `Volume (${Units.uvc})`,
                    flex: 0.9,
                    editable: false,
                    renderHeader: (params: GridColumnHeaderParams) => {
                        return (
                            <PrimaryText color={BLACK}>
                                {params.colDef.headerName}
                            </PrimaryText>
                        )
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return (
                            <SecondaryText color={BLACK}>
                                <NumberWithSpace
                                    number={roundIfNotZero(params.value)}
                                />
                            </SecondaryText>
                        )
                    },
                },
                {
                    field: 'differenceWeight',
                    headerName: `Différence de volume`,
                    flex: 0.9,
                    editable: false,
                    renderHeader: () => {
                        return <ShowChartIcon fontSize="small" />
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return (
                            <Tendency
                                difference={params.row.differenceWeight}
                                unit={Units.uvc}
                                tooltip={TooltipMessage.VOLUME}
                            />
                        )
                    },
                },
                {
                    field: 'volumeLastYear',
                    headerName: `Volume A-1 (${Units.uvc})`,
                    flex: 0.9,
                    editable: false,
                    renderHeader: (params: GridColumnHeaderParams) => {
                        return (
                            <PrimaryText color={BLACK}>
                                {params.colDef.headerName}
                            </PrimaryText>
                        )
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return (
                            <SecondaryText color={BLACK}>
                                <NumberWithSpace
                                    number={roundIfNotZero(params.value)}
                                />
                            </SecondaryText>
                        )
                    },
                },
                {
                    field: 'VMM',
                    headerName: `VMM (${Units.uvc})`,
                    flex: 1,
                    editable: false,
                    renderHeader: (params: GridColumnHeaderParams) => {
                        return (
                            <PrimaryText color={BLACK}>
                                {params.colDef.headerName}
                            </PrimaryText>
                        )
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return (
                            <SecondaryText color={BLACK}>
                                <NumberWithSpace
                                    number={roundIfNotZero(params.value)}
                                />
                            </SecondaryText>
                        )
                    },
                },
                {
                    field: 'differenceVMM',
                    headerName: `Différence de VMM`,
                    flex: 1,
                    editable: false,
                    renderHeader: () => {
                        return <ShowChartIcon fontSize="small" />
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return (
                            <SecondaryText color={BLACK}>
                                <Tendency
                                    difference={params.row.differenceVMM}
                                    unit={Units.uvc}
                                    tooltip={TooltipMessage.VMM}
                                />
                            </SecondaryText>
                        )
                    },
                },
                {
                    field: 'VMMLastYear',
                    editable: false,
                    headerName: `VMM (${Units.uvc})`,
                    flex: 1,
                    renderHeader: (params: GridColumnHeaderParams) => {
                        return (
                            <PrimaryText color={BLACK}>
                                {params.colDef.headerName}
                            </PrimaryText>
                        )
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return (
                            <SecondaryText color={BLACK}>
                                <NumberWithSpace
                                    number={roundIfNotZero(params.value)}
                                />
                            </SecondaryText>
                        )
                    },
                },
            ]
        case DrillDownType.WAREHOUSESEPISAVEURS:
            return [
                {
                    field: 'type',
                    headerName: 'Sous-sous-types',
                    editable: false,
                    flex: 1.8,
                    renderHeader: (params: GridColumnHeaderParams) => {
                        return (
                            <PrimaryText color={BLACK}>
                                {params.colDef.headerName}
                            </PrimaryText>
                        )
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return <CellTooltip text={params.value} />
                    },
                },
                {
                    field: 'volume',
                    headerName: `Volume (${Units.kg})`,
                    flex: 0.9,
                    editable: false,
                    renderHeader: (params: GridColumnHeaderParams) => {
                        return (
                            <PrimaryText color={BLACK}>
                                {params.colDef.headerName}
                            </PrimaryText>
                        )
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return (
                            <SecondaryText color={BLACK}>
                                <NumberWithSpace
                                    number={roundIfNotZero(params.value)}
                                />
                            </SecondaryText>
                        )
                    },
                },
                {
                    field: 'differenceWeight',
                    headerName: `Différence de volume`,
                    flex: 0.9,
                    editable: false,
                    renderHeader: () => {
                        return <ShowChartIcon fontSize="small" />
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return (
                            <Tendency
                                difference={params.row.differenceWeight}
                                unit={Units.kg}
                                tooltip={TooltipMessage.VOLUME}
                            />
                        )
                    },
                },
                {
                    field: 'volumeLastYear',
                    headerName: `Volume A-1 (${Units.kg})`,
                    flex: 0.9,
                    editable: false,
                    renderHeader: (params: GridColumnHeaderParams) => {
                        return (
                            <PrimaryText color={BLACK}>
                                {params.colDef.headerName}
                            </PrimaryText>
                        )
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return (
                            <SecondaryText color={BLACK}>
                                <NumberWithSpace
                                    number={roundIfNotZero(params.value)}
                                />
                            </SecondaryText>
                        )
                    },
                },
                {
                    field: 'VMM',
                    headerName: `VMM (${Units.kg})`,
                    flex: 1,
                    editable: false,
                    renderHeader: (params: GridColumnHeaderParams) => {
                        return (
                            <PrimaryText color={BLACK}>
                                {params.colDef.headerName}
                            </PrimaryText>
                        )
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return (
                            <SecondaryText color={BLACK}>
                                <NumberWithSpace
                                    number={roundIfNotZero(params.value)}
                                />
                            </SecondaryText>
                        )
                    },
                },
                {
                    field: 'differenceVMM',
                    headerName: `Différence de VMM`,
                    flex: 1,
                    editable: false,
                    renderHeader: () => {
                        return <ShowChartIcon fontSize="small" />
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return (
                            <SecondaryText color={BLACK}>
                                <Tendency
                                    difference={params.row.differenceVMM}
                                    unit={Units.kgPerWarehouse}
                                    tooltip={TooltipMessage.VMM}
                                />
                            </SecondaryText>
                        )
                    },
                },
                {
                    field: 'VMMLastYear',
                    editable: false,
                    headerName: `VMM (${Units.kg})`,
                    flex: 1,
                    renderHeader: (params: GridColumnHeaderParams) => {
                        return (
                            <PrimaryText color={BLACK}>
                                {params.colDef.headerName}
                            </PrimaryText>
                        )
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return (
                            <SecondaryText color={BLACK}>
                                <NumberWithSpace
                                    number={roundIfNotZero(params.value)}
                                />
                            </SecondaryText>
                        )
                    },
                },
            ]
        default:
            return [
                {
                    field: 'type',
                    headerName: 'Sous-sous-types',
                    editable: false,
                    flex: 1.8,
                    renderHeader: (params: GridColumnHeaderParams) => {
                        return (
                            <PrimaryText color={BLACK}>
                                {params.colDef.headerName}
                            </PrimaryText>
                        )
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return <CellTooltip text={params.value} />
                    },
                },
                {
                    field: 'clients',
                    headerName: 'Utilisateurs finaux',
                    flex: 0.9,
                    editable: false,
                    renderHeader: (params: GridColumnHeaderParams) => {
                        return (
                            <PrimaryText color={BLACK}>
                                {params.colDef.headerName}
                            </PrimaryText>
                        )
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return (
                            <SecondaryText color={BLACK}>
                                <NumberWithSpace
                                    number={roundIfNotZero(params.value)}
                                />
                            </SecondaryText>
                        )
                    },
                },
                {
                    field: 'differenceClients',
                    headerName: `Différence d'utilisateurs finaux`,
                    flex: 0.9,
                    editable: false,
                    renderHeader: () => {
                        return <ShowChartIcon fontSize="small" />
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return (
                            <Tendency
                                difference={params.row.differenceClients}
                                unit={Units.none}
                                tooltip={TooltipMessage.CUSTOMERS}
                            />
                        )
                    },
                },
                {
                    field: 'warehouses',
                    headerName: 'Dépôts',
                    flex: 0.9,
                    editable: false,
                    renderHeader: (params: GridColumnHeaderParams) => {
                        return (
                            <PrimaryText color={BLACK}>
                                {params.colDef.headerName}
                            </PrimaryText>
                        )
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return (
                            <SecondaryText color={BLACK}>
                                <NumberWithSpace
                                    number={roundIfNotZero(params.value)}
                                />
                            </SecondaryText>
                        )
                    },
                },
                {
                    field: 'differenceWarehouses',
                    headerName: `Différence de dépôts`,
                    flex: 0.9,
                    editable: false,
                    renderHeader: () => {
                        return <ShowChartIcon fontSize="small" />
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return (
                            <Tendency
                                difference={params.value}
                                unit={Units.none}
                                tooltip={TooltipMessage.WAREHOUSES}
                            />
                        )
                    },
                },
                {
                    field: 'products',
                    headerName: 'Références',
                    flex: 0.9,
                    editable: false,
                    hide: type === DrillDownType.PRODUCTS,
                    renderHeader: (params: GridColumnHeaderParams) => {
                        return (
                            <PrimaryText color={BLACK}>
                                {params.colDef.headerName}
                            </PrimaryText>
                        )
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return (
                            <SecondaryText color={BLACK}>
                                <NumberWithSpace
                                    number={roundIfNotZero(params.value)}
                                />
                            </SecondaryText>
                        )
                    },
                },
                {
                    field: 'differenceProducts',
                    headerName: `Différence de références`,
                    flex: 0.9,
                    editable: false,
                    hide: type === DrillDownType.PRODUCTS,

                    renderHeader: () => {
                        return <ShowChartIcon fontSize="small" />
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return (
                            <Tendency
                                difference={params.row.differenceProducts}
                                unit={Units.none}
                                tooltip={TooltipMessage.PRODUCTS}
                            />
                        )
                    },
                },
                {
                    field: 'volume',
                    headerName: `Volume (${Units.kg})`,
                    flex: 0.9,
                    editable: false,
                    renderHeader: (params: GridColumnHeaderParams) => {
                        return (
                            <PrimaryText color={BLACK}>
                                {params.colDef.headerName}
                            </PrimaryText>
                        )
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return (
                            <SecondaryText color={BLACK}>
                                <NumberWithSpace
                                    number={roundIfNotZero(params.value)}
                                />
                            </SecondaryText>
                        )
                    },
                },
                {
                    field: 'differenceWeight',
                    headerName: `Différence de volume`,
                    flex: 0.9,
                    editable: false,
                    renderHeader: () => {
                        return <ShowChartIcon fontSize="small" />
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return (
                            <Tendency
                                difference={params.row.differenceWeight}
                                unit={Units.kg}
                                tooltip={TooltipMessage.VOLUME}
                            />
                        )
                    },
                },
                {
                    field: 'volumeLastYear',
                    headerName: `Volume A-1 (${Units.kg})`,
                    flex: 0.9,
                    editable: false,
                    renderHeader: (params: GridColumnHeaderParams) => {
                        return (
                            <PrimaryText color={BLACK}>
                                {params.colDef.headerName}
                            </PrimaryText>
                        )
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return (
                            <SecondaryText color={BLACK}>
                                <NumberWithSpace
                                    number={roundIfNotZero(params.value)}
                                />
                            </SecondaryText>
                        )
                    },
                },
                {
                    field: 'VMM',
                    headerName: `VMM (${vmmUnit})`,
                    flex: 1,
                    editable: false,
                    renderHeader: (params: GridColumnHeaderParams) => {
                        return (
                            <PrimaryText color={BLACK}>
                                {params.colDef.headerName}
                            </PrimaryText>
                        )
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return (
                            <SecondaryText color={BLACK}>
                                <NumberWithSpace
                                    number={roundIfNotZero(params.value)}
                                />
                            </SecondaryText>
                        )
                    },
                },
                {
                    field: 'differenceVMM',
                    headerName: `Différence de VMM`,
                    flex: 1,
                    editable: false,
                    renderHeader: () => {
                        return <ShowChartIcon fontSize="small" />
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return (
                            <SecondaryText color={BLACK}>
                                <Tendency
                                    difference={params.row.differenceVMM}
                                    unit={Units.kgPerWarehouse}
                                    tooltip={TooltipMessage.VMM}
                                />
                            </SecondaryText>
                        )
                    },
                },
                {
                    field: 'VMMLastYear',
                    editable: false,
                    headerName: `VMM (${vmmUnit})`,
                    flex: 1,
                    renderHeader: (params: GridColumnHeaderParams) => {
                        return (
                            <PrimaryText color={BLACK}>
                                {params.colDef.headerName}
                            </PrimaryText>
                        )
                    },
                    renderCell: (params: GridRenderCellParams) => {
                        return (
                            <SecondaryText color={BLACK}>
                                <NumberWithSpace
                                    number={roundIfNotZero(params.value)}
                                />
                            </SecondaryText>
                        )
                    },
                },
            ]
    }
}

export const getClientsPromoColumns = (): GridColDef[] => [
    {
        field: 'type',
        headerName: 'Sous-sous-types',
        editable: false,
        flex: 1.8,
        renderHeader: (params: GridColumnHeaderParams) => {
            return (
                <PrimaryText color={BLACK}>
                    {params.colDef.headerName}
                </PrimaryText>
            )
        },
        renderCell: (params: GridRenderCellParams) => {
            return <CellTooltip text={params.value} />
        },
    },
    {
        field: 'clients',
        headerName: 'Utilisateurs finaux',
        flex: 0.9,
        editable: false,
        renderHeader: (params: GridColumnHeaderParams) => {
            return (
                <PrimaryText color={BLACK}>
                    {params.colDef.headerName}
                </PrimaryText>
            )
        },
        renderCell: (params: GridRenderCellParams) => {
            return (
                <SecondaryText color={BLACK}>
                    <NumberWithSpace number={roundIfNotZero(params.value)} />
                </SecondaryText>
            )
        },
    },
    {
        field: 'differenceClients',
        headerName: `Différence d'utilisateurs finaux`,
        flex: 0.9,
        editable: false,
        renderHeader: () => {
            return <ShowChartIcon fontSize="small" />
        },
        renderCell: (params: GridRenderCellParams) => {
            return (
                <Tendency
                    difference={params.value}
                    unit={Units.none}
                    tooltip={TooltipMessage.CUSTOMERS}
                />
            )
        },
    },
    {
        field: 'warehouses',
        headerName: 'Dépôts',
        flex: 0.9,
        editable: false,
        renderHeader: (params: GridColumnHeaderParams) => {
            return (
                <PrimaryText color={BLACK}>
                    {params.colDef.headerName}
                </PrimaryText>
            )
        },
        renderCell: (params: GridRenderCellParams) => {
            return (
                <SecondaryText color={BLACK}>
                    <NumberWithSpace number={roundIfNotZero(params.value)} />
                </SecondaryText>
            )
        },
    },
    {
        field: 'differenceWarehouses',
        headerName: `Différence de dépôts`,
        flex: 0.9,
        editable: false,
        renderHeader: () => {
            return <ShowChartIcon fontSize="small" />
        },
        renderCell: (params: GridRenderCellParams) => {
            return (
                <Tendency
                    difference={params.value}
                    unit={Units.none}
                    tooltip={TooltipMessage.WAREHOUSES}
                />
            )
        },
    },
    {
        field: 'volume',
        headerName: `Volume`,
        flex: 0.9,
        editable: false,
        renderHeader: (params: GridColumnHeaderParams) => {
            return (
                <PrimaryText color={BLACK}>
                    {params.colDef.headerName}
                </PrimaryText>
            )
        },
        renderCell: (params: GridRenderCellParams) => {
            return (
                <SecondaryText color={BLACK}>
                    <NumberWithSpace number={roundIfNotZero(params.value)} />
                </SecondaryText>
            )
        },
    },
    {
        field: 'differenceWeight',
        headerName: `Différence de volume`,
        flex: 0.9,
        editable: false,
        renderHeader: () => {
            return <ShowChartIcon fontSize="small" />
        },
        renderCell: (params: GridRenderCellParams) => {
            return (
                <Tendency
                    difference={params.value}
                    unit={Units.kg}
                    tooltip={TooltipMessage.VOLUME}
                />
            )
        },
    },
    {
        field: 'volumeLastYear',
        headerName: `Volume A-1`,
        flex: 0.9,
        editable: false,
        renderHeader: (params: GridColumnHeaderParams) => {
            return (
                <PrimaryText color={BLACK}>
                    {params.colDef.headerName}
                </PrimaryText>
            )
        },
        renderCell: (params: GridRenderCellParams) => {
            return (
                <SecondaryText color={BLACK}>
                    <NumberWithSpace number={roundIfNotZero(params.value)} />
                </SecondaryText>
            )
        },
    },
]

export const getStoresNotDistributorsColumns = (): GridColDef[] => [
    {
        field: 'storeName',
        headerName: 'Points de vente',
        editable: false,
        flex: 1,
        renderHeader: (params: GridColumnHeaderParams) => {
            return (
                <PrimaryText color={BLACK}>
                    {params.colDef.headerName}
                </PrimaryText>
            )
        },
        renderCell: (params: GridRenderCellParams) => {
            return <CellTooltip text={params.value} />
        },
    },
    /*    {
        field: 'VMM',
        headerName: `VMM (${Units.uvc})`,
        editable: false,
        flex: 1,
        renderHeader: (params: GridColumnHeaderParams) => {
            return (
                <PrimaryText color={BLACK}>
                    {params.colDef.headerName}
                </PrimaryText>
            )
        },
        renderCell: (params: GridRenderCellParams) => {
            return (
                <SecondaryText color={BLACK}>
                    <NumberWithSpace number={roundIfNotZero(params.value)} />
                </SecondaryText>
            )
        },
    },
    {
        field: 'distributionChannel',
        headerName: 'Circuits de distribution',
        editable: false,
        flex: 1,
        renderHeader: (params: GridColumnHeaderParams) => {
            return (
                <PrimaryText color={BLACK}>
                    {params.colDef.headerName}
                </PrimaryText>
            )
        },
        renderCell: (params: GridRenderCellParams) => {
            return <CellTooltip text={params.value} />
        },
    }, */
]

export const getStoresColumns = (type: DrillDownType): GridColDef[] => [
    {
        field: 'store',
        headerName: 'Point de vente',
        editable: false,
        flex: 4,
        renderHeader: (params: GridColumnHeaderParams) => {
            return (
                <PrimaryText color={BLACK}>
                    {params.colDef.headerName}
                </PrimaryText>
            )
        },
        renderCell: (params: GridRenderCellParams) => {
            return <CellTooltip text={params.row.storeName} />
        },
    },
    {
        field: 'values',
        headerName: `Volume A (${Units.uvc})`,
        flex: 1,
        editable: false,
        renderHeader: (params: GridColumnHeaderParams) => {
            return (
                <ColumnHeaderTextTooltip tooltipIsNeeded text={params.colDef.headerName} />
            )
        },
        renderCell: (params: GridRenderCellParams) => {
            return (
                <SecondaryText color={BLACK}>
                    <NumberWithSpace number={roundIfNotZero(params.value)} />
                </SecondaryText>
            )
        },
    },
    {
        headerName: `Différence de volume`,
        field: 'difference',
        flex: 0.8,
        editable: false,
        renderHeader: () => {
            return <ShowChartIcon fontSize="small" />
        },
        renderCell: (params: GridRenderCellParams) => {
            return (
                <Tendency
                    difference={params.row.difference}
                    unit={Units.uvc}
                    tooltip={TooltipMessage.VOLUME}
                />
            )
        },
    },
    {
        field: 'oldValues',
        editable: false,
        headerName: `Volume A-1 (${Units.uvc})`,
        flex: 1,
        renderHeader: (params: GridColumnHeaderParams) => {
            return (
                <ColumnHeaderTextTooltip tooltipIsNeeded text={params.colDef.headerName} />
            )
        },
        renderCell: (params: GridRenderCellParams) => {
            return (
                <SecondaryText color={BLACK}>
                    <NumberWithSpace
                        number={roundIfNotZero(params.row.oldValues)}
                    />
                </SecondaryText>
            )
        },
    },
    {
        field: 'VMM',
        headerName: `VMM A (${Units.uvc})`,
        flex: 1,
        editable: false,
        renderHeader: (params: GridColumnHeaderParams) => {
            return (
                <ColumnHeaderTextTooltip tooltipIsNeeded text={params.colDef.headerName} />
            )
        },
        renderCell: (params: GridRenderCellParams) => {
            return (
                <SecondaryText color={BLACK}>
                    <NumberWithSpace number={roundIfNotZero(params.value)} />
                </SecondaryText>
            )
        },
    },
    {
        field: 'differenceVMM',
        headerName: `Différence de VMM`,
        flex: 1,
        editable: false,
        renderHeader: () => {
            return <ShowChartIcon fontSize="small" />
        },
        renderCell: (params: GridRenderCellParams) => {
            return (
                <SecondaryText color={BLACK}>
                    <Tendency
                        difference={params.row.differenceVMM}
                        unit={Units.uvc}
                        tooltip={TooltipMessage.VMM}
                    />
                </SecondaryText>
            )
        },
    },
    {
        field: 'oldVMM',
        editable: false,
        headerName: `VMM A-1 (${Units.uvc})`,
        flex: 1,
        renderHeader: (params: GridColumnHeaderParams) => {
            return (
                <ColumnHeaderTextTooltip tooltipIsNeeded text={params.colDef.headerName} />
            )
        },
        renderCell: (params: GridRenderCellParams) => {
            return (
                <SecondaryText color={BLACK}>
                    <NumberWithSpace number={roundIfNotZero(params.value)} />
                </SecondaryText>
            )
        },
    },
]

export const getColumnsInformationTab = (
    type: DrillDownType,
    row: any
): GridRowsProp => {
    const { unit } = useAppSelector((state) => state.global)

    switch (type) {
        case DrillDownType.STORES:
            return [
                {
                    id: 1,
                    columnName: `Volume A (${Units.uvc})`,
                    information: row.row.values,
                    difference: row.row.difference,
                    unit: row.row.unit,
                },
                {
                    id: 2,
                    columnName: `Volume A-1 (${Units.uvc})`,
                    information: row.row.oldValues,
                },
                {
                    id: 4,
                    columnName: `Rotation A (${Units.uvcPerStorePerMonth})`,
                    information: row.row.VMM,
                    difference: row.row.differenceVMM,
                },
                {
                    id: 5,
                    columnName: `Rotation A-1 (${Units.uvcPerStorePerMonth})`,
                    information: row.row.oldVMM,
                },
                {
                    id: 5,
                    columnName: 'Nombre de références',
                    information: row.row.references,
                    difference: row.row.differenceReferences,
                },
            ]

        case DrillDownType.PROMO:
            return [
                {
                    id: 1,
                    columnName: `Sell-in (kg)`,
                    information: row.row.volSellIn,
                },
                {
                    id: 2,
                    columnName: `Sell-in (€ net)`,
                    information: row.row.netSellIn,
                },
                {
                    id: 3,
                    columnName: `Sell-out (kg)`,
                    information: row.row.volSellOut,
                },
                {
                    id: 4,
                    columnName: `Promotions (€)`,
                    information: row.row.promo,
                },
            ]
        case DrillDownType.RETAILPRODUCTS:
            return [
                {
                    id: 1,
                    columnName: `Volume A (${Units.uvc})`,
                    information: row.row.values,
                    difference: row.row.difference,
                    unit: row.row.unit,
                },
                {
                    id: 2,
                    columnName: `Volume A-1 (${Units.uvc})`,
                    information: row.row.oldValues,
                },
                {
                    id: 3,
                    columnName: `Rotation A (${Units.uvcPerStorePerMonth})`,
                    information: row.row.VMM,
                    difference: row.row.differenceVMM,
                },
                {
                    id: 4,
                    columnName: `Rotation A-1 (${Units.uvcPerStorePerMonth})`,
                    information: row.row.oldVMM,
                },
                {
                    id: 5,
                    columnName: 'Nombre de points de vente',
                    information: row.row.stores,
                    difference: row.row.differenceStores,
                },
            ]

        case DrillDownType.CLIENTS:
            return [
                {
                    id: 1,
                    columnName: `Volume A (${Units.kg})`,
                    information: row.row.volume,
                    difference: row.row.differenceWeight,
                    unit: row.row.unit,
                },
                {
                    id: 2,
                    columnName: `Volume A-1 (${Units.kg})`,
                    information: row.row.volumeLastYear,
                },
                {
                    id: 3,
                    columnName: `VMM A (${Units.kgPerWarehouse})`,
                    information: row.row.VMM,
                    difference: row.row.differenceVMM,
                },
                {
                    id: 4,
                    columnName: `VMM A-1 (${Units.kgPerWarehouse})`,
                    information: row.row.VMMLastYear,
                },
                {
                    id: 3,
                    columnName: "Nombre d'utilisateurs finaux",
                    information: row.row.clients,
                    difference: row.row.differenceClients,
                },
                {
                    id: 4,
                    columnName: 'Nombre de références',
                    information: row.row.products,
                    difference: row.row.differenceProducts,
                },
                {
                    id: 4,
                    columnName: 'Nombre de dépôts',
                    information: row.row.warehouses,
                    difference: row.row.differenceWarehouses,
                },
            ]
        case DrillDownType.CLIENTSPF:
        case DrillDownType.CLIENTSEPISAVEURS:
            return [
                {
                    id: 1,
                    columnName: `Volume A (${Units.kg})`,
                    information: row.row.volume,
                    difference: row.row.differenceWeight,
                    unit: row.row.unit,
                },
                {
                    id: 2,
                    columnName: `Volume A-1 (${Units.kg})`,
                    information: row.row.volumeLastYear,
                },

                {
                    id: 3,
                    columnName: 'Nombre de dépôts',
                    information: row.row.warehouses,
                    difference: row.row.differenceWarehouses,
                },
                {
                    id: 4,
                    columnName: 'Nombre de références',
                    information: row.row.products,
                    difference: row.row.differenceProducts,
                },
                {
                    id: 5,
                    columnName: `VMM A (${Units.kgPerWarehouse})`,
                    information: row.row.VMM,
                    difference: row.row.differenceVMM,
                },
                {
                    id: 6,
                    columnName: `VMM A-1 (${Units.kgPerWarehouse})`,
                    information: row.row.VMMLastYear,
                },
            ]

        case DrillDownType.CLIENTSCERCLEVERT:
        case DrillDownType.CLIENTSTGM:
            return [
                {
                    id: 1,
                    columnName: `Volume A (${Units.kg})`,
                    information: row.row.volume,
                    difference: row.row.differenceWeight,
                    unit: row.row.unit,
                },
                {
                    id: 2,
                    columnName: `Volume A-1 (${Units.kg})`,
                    information: row.row.volumeLastYear,
                },
                {
                    id: 4,
                    columnName: 'Nombre de références',
                    information: row.row.products,
                    difference: row.row.differenceProducts,
                },
            ]

        case DrillDownType.CLIENTSSODEXO:

            return [
                {
                    id: 1,
                    columnName: `Volume A (${Units.kg})`,
                    information: row.row.volume,
                    difference: row.row.differenceWeight,
                    unit: row.row.unit,
                },
                {
                    id: 2,
                    columnName: `Volume A-1 (${Units.kg})`,
                    information: row.row.volumeLastYear,
                },
                {
                    id: 3,
                    columnName: `VMM A (${Units.kg})`,
                    information: row.row.VMM,
                    difference: row.row.differenceVMM,
                },
                {
                    id: 4,
                    columnName: `VMM A-1 (${Units.kg})`,
                    information: row.row.VMMLastYear,
                },

                {
                    id: 4,
                    columnName: 'Nombre de références',
                    information: row.row.products,
                    difference: row.row.differenceProducts,
                },
            ]
        case DrillDownType.CLIENTSMETRO:
            return [
                {
                    id: 1,
                    columnName: `CA en A (${Units.euro})`,
                    information: row.row.ca,
                    difference: row.row.differenceCA,
                    unit: row.row.unit,
                },
                {
                    id: 2,
                    columnName: `CA en A-1 (${Units.euro})`,
                    information: row.row.caLastYear,
                },
                {
                    id: 3,
                    columnName: `%CA en A (${Units.euro})`,
                    information: row.row.percentageCA,
                    difference: row.row.differencePercentageCA,
                },
                {
                    id: 4,
                    columnName: `%CA en A-1 (${Units.euro})`,
                    information: row.row.percentageCALastYear,
                },
                {
                    id: 4,
                    columnName: 'Nombre de familles produit',
                    information: row.row.productsFamily,
                    difference: row.row.differenceProductsFamily,
                },
            ]
        case DrillDownType.WAREHOUSES:
            return [
                {
                    id: 1,
                    columnName: `Volume A (${Units.kg})`,
                    information: row.row.volume,
                    difference: row.row.differenceWeight,
                    unit: row.row.unit,
                },
                ...(row.row.volumeLastYear ? [{
                    id: 2,
                    columnName: `Volume A-1 (${Units.kg})`,
                    information: row.row.volumeLastYear,
                }] : []),
                ...(row.row.differenceVMM ? [{
                    id: 3,
                    columnName: `VMM A (${Units.kg})`,
                    information: row.row.VMM,
                    difference: row.row.differenceVMM,
                }] : []),
                ...(row.row.VMMLastYear ? [{
                    id: 4,
                    columnName: `VMM A-1 (${Units.kg})`,
                    information: row.row.VMMLastYear,
                }] : []),
                {
                    id: 3,
                    columnName: "Nombre d'utilisateurs finaux",
                    information: row.row.clients,
                    difference: row.row.differenceClients,
                },
                {
                    id: 4,
                    columnName: 'Nombre de références',
                    information: row.row.products,
                    difference: row.row.differenceProducts,
                },
            ]
        case DrillDownType.WAREHOUSESPF:
            return [
                {
                    id: 1,
                    columnName: `Volume A (${Units.kg})`,
                    information: row.row.volume,
                    difference: row.row.differenceWeight,
                    unit: row.row.unit,
                },
                {
                    id: 2,
                    columnName: `Volume A-1 (${Units.kg})`,
                    information: row.row.volumeLastYear,
                },

                {
                    id: 4,
                    columnName: 'Nombre de références',
                    information: row.row.products,
                    difference: row.row.differenceProducts,
                },
            ]
        case DrillDownType.WAREHOUSESEPISAVEURS:
            return [
                {
                    id: 1,
                    columnName: `Volume A (${Units.kg})`,
                    information: row.row.volume,
                    difference: row.row.differenceWeight,
                    unit: row.row.unit,
                },
                {
                    id: 2,
                    columnName: `Volume A-1 (${Units.kg})`,
                    information: row.row.volumeLastYear,
                },

                {
                    id: 3,
                    columnName: 'Nombre de références',
                    information: row.row.products,
                    difference: row.row.differenceProducts,
                },
                {
                    id: 4,
                    columnName: `VMM A (${Units.kg})`,
                    information: row.row.VMM,
                    difference: row.row.differenceVMM,
                },
                {
                    id: 5,
                    columnName: `VMM A-1 (${Units.kg})`,
                    information: row.row.VMMLastYear,
                }]
        case DrillDownType.WAREHOUSESTGM:
            return [
                {
                    id: 1,
                    columnName: `Nombre de transactions`,
                    information: row.row.clients,
                    difference: row.row.differenceClients,
                    unit: row.row.unit,
                },
                {
                    id: 2,
                    columnName: `Part de CA (%)`,
                    information: row.row.ca,
                },
                {
                    id: 3,
                    columnName: `Evolution de la part de CA A-1 (%)`,
                    information: row.row.evolCa,
                },
            ]
        case DrillDownType.WAREHOUSESMETRO:
            return [
                {
                    id: 1,
                    columnName: `Volume A (${unit})`,
                    information: row.row.volume,
                    difference: row.row.differenceWeight,
                    unit: row.row.unit,
                },
                {
                    id: 2,
                    columnName: `Volume A-1 (${unit})`,
                    information: row.row.volumeLastYear,
                },
                {
                    id: 3,
                    columnName: `VMM A (${unit})`,
                    information: row.row.VMM,
                    difference: row.row.differenceVMM,
                },
                {
                    id: 4,
                    columnName: `VMM A-1 (${unit})`,
                    information: row.row.VMMLastYear,
                },
                {
                    id: 5,
                    columnName: 'Nombre de références',
                    information: row.row.products,
                    difference: row.row.differenceProducts,
                },
            ]
        case DrillDownType.PRODUCTS:
            return [
                {
                    id: 1,
                    columnName: `Volume A (${Units.kg})`,
                    information: row.row.volume,
                    difference: row.row.differenceWeight,
                    unit: row.row.unit,
                },
                {
                    id: 2,
                    columnName: `Volume A-1 (${Units.kg})`,
                    information: row.row.volumeLastYear,
                },
                {
                    id: 3,
                    columnName: `VMM A (${Units.kgPerWarehouse})`,
                    information: row.row.VMM,
                    difference: row.row.differenceVMM,
                },
                {
                    id: 4,
                    columnName: `VMM A-1 (${Units.kgPerWarehouse})`,
                    information: row.row.VMMLastYear,
                },
                {
                    id: 5,
                    columnName: "Nombre d'utilisateurs finaux",
                    information: row.row.clients,
                    difference: row.row.differenceClients,
                },
                {
                    id: 6,
                    columnName: 'Nombre de dépôts',
                    information: row.row.warehouses,
                    difference: row.row.differenceWarehouses,
                },
            ]
        case DrillDownType.PRODUCTSCERCLEVERT:
            return [
                {
                    id: 1,
                    columnName: `Volume A (${Units.kg})`,
                    information: row.row.volume,
                    difference: row.row.differenceWeight,
                    unit: row.row.unit,
                },
                {
                    id: 2,
                    columnName: `Volume A-1 (${Units.kg})`,
                    information: row.row.volumeLastYear,
                },
            ]
        case DrillDownType.PRODUCTSMETRO:
            return [
                {
                    id: 1,
                    columnName: `Volume A (${Units.kg})`,
                    information: row.row.volume,
                    difference: row.row.differenceWeight,
                    unit: row.row.unit,
                },
                {
                    id: 2,
                    columnName: `Volume A-1 (${Units.kg})`,
                    information: row.row.volumeLastYear,
                },
                {
                    id: 3,
                    columnName: `VMM A (${Units.kgPerWarehouse})`,
                    information: row.row.VMM,
                    difference: row.row.differenceVMM,
                },
                {
                    id: 4,
                    columnName: `VMM A-1 (${Units.kgPerWarehouse})`,
                    information: row.row.VMMLastYear,
                },
                {
                    id: 6,
                    columnName: 'Nombre de dépôts',
                    information: row.row.warehouses,
                    difference: row.row.differenceWarehouses,
                },
            ]
        case DrillDownType.PRODUCTSPF:
            return [
                {
                    id: 1,
                    columnName: `Volume A (${Units.uvc})`,
                    information: row.row.volume,
                    difference: row.row.differenceWeight,
                    unit: row.row.unit,
                },
                {
                    id: 2,
                    columnName: `Volume A-1 (${Units.uvc})`,
                    information: row.row.volumeLastYear,
                },
                {
                    id: 3,
                    columnName: `VMM A (${Units.uvcPerWarehouse})`,
                    information: row.row.VMM,
                    difference: row.row.differenceVMM,
                },
                {
                    id: 4,
                    columnName: `VMM A-1 (${Units.uvcPerWarehouse})`,
                    information: row.row.VMMLastYear,
                },
                {
                    id: 6,
                    columnName: 'Nombre de dépôts',
                    information: row.row.warehouses,
                    difference: row.row.differenceWarehouses,
                },
            ]
        case DrillDownType.PRODUCTSEPISAVEURS:
            return [{
                id: 1,
                columnName: `Volume A (${Units.kg})`,
                information: row.row.volume,
                difference: row.row.differenceWeight,
                unit: row.row.unit,
            },
            {
                id: 2,
                columnName: `Volume A-1 (${Units.kg})`,
                information: row.row.volumeLastYear,
            },
            {
                id: 3,
                columnName: `VMM A (${Units.kgPerWarehouse})`,
                information: row.row.VMM,
                difference: row.row.differenceVMM,
            },
            {
                id: 4,
                columnName: `VMM A-1 (${Units.kgPerWarehouse})`,
                information: row.row.VMMLastYear,
            },
            {
                id: 5,
                columnName: 'Nombre de dépôts',
                information: row.row.warehouses,
                difference: row.row.differenceWarehouses,
            },]
        case DrillDownType.PRODUCTSTGM:
            return [
                {
                    id: 1,
                    columnName: `Volume A (${Units.kg})`,
                    information: row.row.volume,
                    difference: row.row.differenceWeight,
                    unit: row.row.unit,
                },
                {
                    id: 2,
                    columnName: `Volume A-1 (${Units.kg})`,
                    information: row.row.volumeLastYear,
                },
                {
                    id: 3,
                    columnName: `VMM A (${Units.kg})`,
                    information: row.row.VMM,
                    difference: row.row.differenceVMM,
                },
                {
                    id: 4,
                    columnName: `VMM A-1 (${Units.kg})`,
                    information: row.row.VMMLastYear,
                },
                {
                    id: 5,
                    columnName: 'Nombre de transactions',
                    information:
                        row.row.clients !== '-' ? row.row.clients : '-',
                    difference: row.row.differenceClients,
                },
            ]
        case DrillDownType.PRODUCTSSODEXO:
            return [
                {
                    id: 1,
                    columnName: `Volume A (${Units.uvc})`,
                    information: row.row.volume,
                    difference: row.row.differenceWeight,
                    unit: row.row.unit,
                },
                {
                    id: 2,
                    columnName: `Volume A-1 (${Units.uvc})`,
                    information: row.row.volumeLastYear,
                },
                {
                    id: 3,
                    columnName: `VMM A (${Units.uvc})`,
                    information: row.row.VMM,
                    difference: row.row.differenceVMM,
                },
                {
                    id: 4,
                    columnName: `VMM A-1 (${Units.uvc})`,
                    information: row.row.VMMLastYear,
                },
            ]
    }
}
