import React from 'react'
import { routerType } from '../../PublicNavigation'
import { KaryonModules } from '../../../../../../redux/user/userTypes'
import RetailCrossDashboard from '../../../../../../pages/private/Foodservice/Cross/RetailCrossDashboard'
import { PagesName } from '../../../types/pages'

export const RetailCrossRouter = (modules: KaryonModules[]): routerType[] => {
    if (!modules.includes(KaryonModules.CROSS)) {
        return []
    }

    return [
        {
            path: '/dashboard',
            element: <RetailCrossDashboard />,
            title: PagesName.CROSS,
        },
    ]
}
