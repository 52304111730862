import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import client from '../../api/config'
import { AppThunk } from '../store'
import { EnvType } from '../user/userTypes'
import {
    Campaign,
    CampaignData,
    ResponseViewsCreation,
    View,
    ViewConfig,
    ViewCreationMode,
    ViewData,
    viewsState,
    ViewsSteps,
    ViewTypes,
} from './viewsTypes'
import { resetFSFilters, resetRetailFilters, setActiveFsViewFilters, setActiveRetailFilters } from '../filters/filtersSlice'
import { FsViewFilters, RetailViewFilters } from '../filters/filtersTypes'
import { UnityType } from '../promotions/promotionsTypes'

const initialState: viewsState = {
    views: [],
    campaigns: {
        data: [],
        unityType: UnityType.UF
    },
    openAdd: false,
    openUpdate: false,
    openAddCampaign: false,
    config: {
        steps: [
            ViewsSteps.FsSettings,
            ViewsSteps.FsFilters,
            ViewsSteps.RetailSettings,
            ViewsSteps.RetailFilters,
            ViewsSteps.FsCrossFilters,
            ViewsSteps.FsCrossSettings,
        ],
    },
    configCampaign: {
        steps: [
            ViewsSteps.CampaignFS,
        ],
    },
    data: {
        name: '',
        type: ViewTypes.Dashboard,
        distributor: '',
        environment: EnvType.SETTING,
        creationMode: ViewCreationMode.GLOBAL,
        filters: {
            products: [],
            startDate: '2022-01',
            endDate: '2022-12',
            regions: [],
            stores: [],
            clients: [],
            categories: [],
            min: "",
            max: ""
        },
    },
    dataCampaign: {
        name: '',
        type: ViewTypes.Dashboard,
        budget: 0,
        distributor: '',
        environment: EnvType.SETTING,
        creationMode: ViewCreationMode.CAMPAIGN,
        filters: {
            startDate: '2022-01',
            endDate: '2022-12',
            products: [],
            clients: [],
            min: "",
            max: ""
        },
    },
    dataUpdate: {
        name: '',
        filters: {
            products: [],
            startDate: '2022-01',
            endDate: '2022-12',
            regions: [],
            clients: [],
            stores: [],
            categories: [],
            min: "",
            max: ""
        },
    },
    usersToShare: [],
    description: '',
}

const viewsSlice = createSlice({
    name: 'views',
    initialState,
    reducers: {
        closeViewCreator: (state) => {
            state.openAdd = false
        },
        closeViewUpdator: (state) => {
            state.openUpdate = false
        },
        setUsersToShare: (state, action: PayloadAction<string[]>) => {
            state.usersToShare = action.payload
        },
        setDescription: (state, action: PayloadAction<string>) => {
            state.description = action.payload
        },
        setupViews: (
            state,
            action: PayloadAction<{ config: ViewConfig; data?: ViewData }>
        ) => {
            state.openAdd = true
            state.config = action.payload.config
            if (action.payload.data) {
                state.data = action.payload.data
            }
        },
        setupViewsUpdate: (
            state,
            action: PayloadAction<{ config: ViewConfig; data?: ViewData }>
        ) => {
            state.openUpdate = true
            state.config = action.payload.config
            if (action.payload.data) {
                state.data = action.payload.data
            }
        },
        setupCampaign: (
            state,
            action: PayloadAction<{ config: ViewConfig }>
        ) => {
            state.openAddCampaign = true
            state.configCampaign = action.payload.config

        },
        closeCampaignCreator: (state) => {
            state.openAddCampaign = false
        },
        resetData: (state) => {
            state.openAdd = false
            state.data = initialState.data
        },
        resetDataUpdate: (state) => {
            state.openUpdate = false
            state.dataUpdate = initialState.dataUpdate
        },
        updateData: (
            state,
            action: PayloadAction<{ data: Partial<ViewData> }>
        ) => {
            state.data = { ...state.data, ...action.payload.data }
        },
        updateDataCampaign: (
            state,
            action: PayloadAction<{ data: Partial<CampaignData> }>
        ) => {
            state.dataCampaign = { ...state.dataCampaign, ...action.payload.data }
        },
        updateDataUpdate: (
            state,
            action: PayloadAction<{ data: Partial<View> }>
        ) => {
            state.dataUpdate = { ...state.dataUpdate, ...action.payload.data }
        },
        initViews: (state, action: PayloadAction<{ views: View[] }>) => {
            state.views = action.payload.views
        },
        resetViews: (state) => {
            state.views = initialState.views
        },
        addView: (state, action: PayloadAction<{ view: View }>) => {
            state.views = [...state.views, action.payload.view]
        },
        updatedView: (
            state,
            action: PayloadAction<{ viewId: string; update: Partial<View> }>
        ) => {
            state.openUpdate = false
            const index = state.views.findIndex(
                (view) => view._id === action.payload.viewId
            )

            state.views = [
                ...state.views.slice(0, index),
                {
                    ...state.views[index],
                    ...action.payload.update,
                },
                ...state.views.slice(index + 1),
            ]
        },
        deleteView: (state, action: PayloadAction<{ viewId: string }>) => {
            const index = state.views.findIndex(
                (view) => view._id === action.payload.viewId
            )

            state.views = [
                ...state.views.slice(0, index),
                ...state.views.slice(index + 1),
            ]
        },
        addCampaign: (state, action: PayloadAction<{ campaign: Campaign }>) => {
            state.campaigns.data = [...state.campaigns.data, action.payload.campaign]
        },
        initCampaigns: (state, action: PayloadAction<{ campaigns: { data: Campaign[], unityType: UnityType } }>) => {
            state.campaigns = action.payload.campaigns
        },
        resetCampaigns: (state) => {
            state.campaigns = initialState.campaigns
        },
        resetCampaignData: (state) => {
            state.openAddCampaign = false
            state.dataCampaign = initialState.dataCampaign
        },
        deleteCampaign: (state, action: PayloadAction<{ campaignId: string }>) => {
            const index = state.campaigns.data.findIndex(
                (campaign) => campaign._id === action.payload.campaignId
            )

            state.campaigns.data = [
                ...state.campaigns.data.slice(0, index),
                ...state.campaigns.data.slice(index + 1),
            ]
        },
    },
})

export const {
    closeViewCreator,
    setupViews,
    resetData,
    updateData,
    initViews,
    resetViews,
    addView,
    updatedView,
    deleteView,
    updateDataUpdate,
    setupViewsUpdate,
    closeViewUpdator,
    resetDataUpdate,
    setUsersToShare,
    setDescription,
    updateDataCampaign,
    setupCampaign,
    closeCampaignCreator,
    addCampaign,
    initCampaigns,
    resetCampaigns,
    resetCampaignData,
    deleteCampaign
} = viewsSlice.actions

export default viewsSlice.reducer

export const fetchViews =
    (distributor: string): AppThunk<Promise<View[]>> =>
        async (dispatch, getState) => {
            try {
                const views = await client
                    .get(`/views?distributor=${distributor}`)
                    .then((response) => {
                        dispatch(initViews({ views: response.data }))
                        return response.data
                    })
                    .catch((err) => {
                        console.log('ERROR : ', err)
                        return []
                    })

                return views
            } catch (e) {
                console.log('ERROR : ', e)
                return []
            }
        }

export const fetchCampaigns =
    (distributor: string): AppThunk<Promise<View[]>> =>
        async (dispatch, getState) => {
            try {
                const campaigns = await client
                    .get(`/views/campaigns?distributor=${distributor}`)
                    .then((response) => {
                        dispatch(initCampaigns({ campaigns: response.data }))
                        return response.data
                    })
                    .catch((err) => {
                        console.log('ERROR : ', err)
                        return []
                    })

                return campaigns
            } catch (e) {
                console.log('ERROR : ', e)
                return []
            }
        }

export const handleViewCreation =
    (viewCreationMode: ViewCreationMode): AppThunk<Promise<ResponseViewsCreation | null>> =>
        async (dispatch, getState) => {
            const viewForm = getState().views.data

            const userId = getState().user.user._id

            try {
                const insertedId = await client
                    .post('/views', viewForm)
                    .then((response) => {
                        if (viewForm.creationMode === ViewCreationMode.GLOBAL) {
                            dispatch(
                                addView({
                                    view: {
                                        _id: response.data.insertedId,
                                        name: viewForm.name,
                                        type: ViewTypes.Dashboard,
                                        environment: viewForm.environment,
                                        distributor: viewForm.distributor,
                                        createConfig: {
                                            creator: userId,
                                            created_at: new Date(),
                                        },
                                        creationMode: viewForm.creationMode,
                                        filters: viewForm.filters,
                                    },
                                })
                            )
                        } else {
                            dispatch(
                                addView({
                                    view: {
                                        _id: response.data.insertedId,
                                        name: viewForm.name,
                                        type: ViewTypes.Dashboard,
                                        environment: viewForm.environment,
                                        distributor: viewForm.distributor,
                                        createConfig: {
                                            creator: userId,
                                            created_at: new Date(),
                                        },
                                        creationMode: viewForm.creationMode,
                                        filters: viewForm.filters,
                                        notificationId: viewForm.notificationId,
                                    },
                                })
                            )
                        }
                        dispatch(resetData())
                        return response.data.insertedId
                    })
                    .catch((err) => {
                        console.log('ERROR : ', err)
                        return null
                    })
                return {
                    insertedId: insertedId,
                    distributor: viewForm.distributor,
                    environment: viewForm.environment,
                }
            } catch (e) {
                console.log('ERROR : ', e)
                return null
            }
        }

export const handleCampaignCreation =
    (): AppThunk<Promise<ResponseViewsCreation | null>> =>
        async (dispatch, getState) => {
            const viewForm = getState().views.dataCampaign
            const userId = getState().user.user._id
            try {
                const insertedId = await client
                    .post('/campaign', viewForm)
                    .then((response) => {
                        dispatch(
                            addCampaign({
                                campaign: {
                                    _id: response.data.insertedId,
                                    name: viewForm.name,
                                    budget: viewForm.budget,
                                    environment: viewForm.environment,
                                    distributor: viewForm.distributor,
                                    createConfig: {
                                        creator: userId,
                                        created_at: new Date(),
                                    },
                                    creationMode: viewForm.creationMode,
                                    filters: viewForm.filters as FsViewFilters,
                                    data: response.data.data
                                },
                            })
                        )
                        dispatch(resetCampaignData())
                        return {
                            insertedId: response.data.insertedId,
                        }
                    })
                    .catch((err) => {
                        console.log('ERROR : ', err)
                        return null
                    })

                return {
                    insertedId: insertedId.insertedId,
                    distributor: viewForm.distributor,
                    environment: viewForm.environment,
                }
            } catch (e) {
                console.log('ERROR : ', e)
                return null
            }
        }

export const fetchDeleteCampaign = (
    campaignId: string,
): AppThunk<Promise<void>> =>
    async (dispatch, getState) => {
        try {
            const deleteCampaign = await client
                .delete(`/campaign/${campaignId}`)
                .then((response) => {
                    dispatch(deleteCampaign({ campaignId }))
                    return response.data
                })
                .catch((err) => {
                    console.log('ERROR : ', err)
                    return []
                })


            return deleteCampaign
        } catch (e) {
            console.log('ERROR : ', e)
            return {}
        }
    }

export const fetchDeleteView =
    (viewId: string): AppThunk<Promise<View[]>> =>
        async (dispatch, getState) => {
            try {
                const views = await client
                    .delete(`/views/${viewId}`)
                    .then((response) => {
                        dispatch(deleteView({ viewId }))
                        return response.data
                    })
                    .catch((err) => {
                        console.log('ERROR : ', err)
                        return []
                    })

                return views
            } catch (e) {
                console.log('ERROR : ', e)
                return []
            }
        }

export const shareView =
    (
        viewId: string,
        usersToShare: string[],
        description: string,
        distrib: string,
        envType: string
    ): AppThunk<Promise<View[]>> =>
        async (dispatch, getState) => {
            try {
                const viewsUpdated = await client
                    .put(`/views/${viewId}`, {
                        shared: true,
                        usersToShare: usersToShare,
                        description: description,
                    })
                    .then((response) => {
                        return response.data
                    })
                    .catch((err) => {
                        console.log('ERROR : ', err)
                        return []
                    })

                const views = await client
                    .post(`/views/${viewId}`, {
                        usersToShare: usersToShare,
                        distrib: distrib,
                        envType: envType,
                        msg: description
                    })
                    .then((response) => {
                        return response.data
                    })
                    .catch((err) => {
                        console.log('ERROR : ', err)
                        return []
                    })

                return views
            } catch (e) {
                console.log('ERROR : ', e)
                return []
            }
        }

export const updateView =
    (viewId: string): AppThunk<Promise<View[]>> =>
        async (dispatch, getState) => {
            const viewForm = getState().views.dataUpdate
            const { type } = getState().global.env

            try {
                const views = await client
                    .put(`/views/${viewId}`, {
                        name: viewForm.name,
                        filters: viewForm.filters,
                    })
                    .then((response) => {
                        dispatch(
                            updatedView({
                                viewId,
                                update: {
                                    name: viewForm.name,
                                    filters: viewForm.filters,
                                },
                            })
                        )
                        dispatch(resetDataUpdate())
                        return response.data
                    })
                    .catch((err) => {
                        console.log('ERROR : ', err)
                        return []
                    })
                if (type === EnvType.RETAIl) {
                    dispatch(resetRetailFilters())
                    dispatch(setActiveRetailFilters(viewForm.filters as RetailViewFilters))
                } else {
                    dispatch(setActiveFsViewFilters(viewForm.filters as FsViewFilters))
                    dispatch(resetFSFilters())
                }

                return views
            } catch (e) {
                console.log('ERROR : ', e)
                return []
            }
        }
