import React, { useSyncExternalStore } from 'react'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    LegendItem,
} from 'chart.js'
import { Line } from 'react-chartjs-2'
import { Box } from '@mui/material'
import { BRIC, SECONDARY } from '../../../ui/Colors'
import { useAppSelector } from '../../../../redux/hooks'
import {
    getResizeSnapshot,
    subscribeToResize,
} from '../../../../helpers/ExternalStore'
import { formateLegend } from './utils/formateProductsData'
import { backgroundColor } from '../../../../pages/private/Foodservice/Pages/ProductsPage/LineChartProduct'
import { LinkButton } from '../../../ui/Button'

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
)

interface DataSet {
    label: string,
    data: number[],
    dataLastYear: number[],
    borderColor: string,
    backgroundColor: string

}

interface Data {
    datasets: DataSet[]
}

const LineChart = () => {
    const { height } = useSyncExternalStore(
        subscribeToResize,
        getResizeSnapshot
    )
    const { unit, env } = useAppSelector((state) => state.global)
    const { productsChart, products, legendChart } = useAppSelector(
        (state) => state.global.fsDashboardData
    )

    const formatedLegend = formateLegend(legendChart)
    const datasets = productsChart.map((product, index) => {
        let color = null
        if (product.name === products[0]._id) {
            color = SECONDARY
        } else if (product.name === products[products.length - 1]._id) {
            color = BRIC
        } else {
            color = backgroundColor[index % 33]
        }

        return {
            label: product.name,
            data: [null, ...product.data, null],
            dataLastYear: [null, ...product.evolution, null],
            borderColor: color,
            backgroundColor: color,
        }
    })

    const data = {
        labels: [null, ...formatedLegend, null],
        datasets: datasets,
    }

    const options = {
        scales: {
            x: {
                ticks: {
                    font: {
                        size: height * 0.014,
                    },
                },
            },
            y: {
                ticks: {
                    font: {
                        size: height * 0.014,
                    },
                },
            },
        },
        maintainAspectRatio: false,
        responsive: true,
        pointBorderWidth: height * 0.004,
        borderWidth: height * 0.003,
        plugins: {
            legend: {
                display: true,
                labels: {
                    filter: (legendItem: LegendItem, data: Data) => {
                        const index = data.datasets.findIndex(
                            (dataset: DataSet) => dataset.label === legendItem.text
                        )
                        return index < 4
                    },
                    color: '#000',
                    font: {
                        size: height * 0.014,
                        family: ['Cabin', 'sans-serif'],
                    },
                    boxWidth: height * 0.01,
                    boxHeight: height * 0.01,
                },
                onClick: () => null,
            },
            title: {
                display: false,
            },
            tooltip: {
                callbacks: {
                    label: (model: any) => {
                        const value = Math.round(model.raw)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')

                        return `${model.dataset.label} : ${value} ${unit}`
                    },
                    footer: (model: any) => {
                        const evolution =
                            data.datasets[model[0].datasetIndex].dataLastYear[
                                model[0].dataIndex
                            ] === undefined
                                ? null
                                : Math.round(
                                    data.datasets[model[0].datasetIndex]
                                        .dataLastYear[model[0].dataIndex]
                                )

                        if (evolution === null) {
                            return 'Évolution : -'
                        }

                        if (evolution === 0) {
                            return ''
                        }
                        return evolution > 0
                            ? `Évolution : ↑ ${evolution} %`
                            : `Évolution : ↓  ${evolution} %`
                    },
                },
            },
        },
    }

    return (
        <Box
            sx={{
                height: '24vh',
                width: '60vw',
                display: 'inline-block',
                position: 'relative',
            }}
        >
            <Line options={options as any} data={data} />
            {datasets.length > 3 &&
                <Box
                    sx={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                    }}
                >
                    <LinkButton
                        to={`/food-service/${env.name}/produits`}>
                        Voir plus
                    </LinkButton>

                </Box>}
        </Box>
    )
}

export default LineChart
