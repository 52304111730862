import { Grid, Tooltip } from '@mui/material'
import React from 'react'
import { LIGHT_GREY, MEDIUM_GREY } from '../ui/Colors'
import { PrimaryText } from '../ui/Text'

interface OwnProps {
    name: string
    onClick?: (string) => void
    isSelected?: boolean
}

const OtherDistributorIcon = ({
    name,
    onClick,
    isSelected = false,
}: OwnProps) => {
    return (
        <Tooltip title={name}>
            <Grid
                container
                justifyContent="center"
                alignItems="center"
                sx={{
                    background: LIGHT_GREY,
                    borderRadius: 1,
                    width: '7vh',
                    height: '7vh',
                }}
                onClick={() => {
                    onClick(name)
                }}
                style={{
                    objectFit: 'contain',
                    cursor: 'pointer',
                    border: isSelected && '1px solid #3A4D87',
                    borderRadius: isSelected && '5px',
                }}
            >
                <PrimaryText bold color={MEDIUM_GREY} >
                    {name && name[0].toUpperCase()}
                </PrimaryText >
            </Grid>
        </Tooltip>
    )
}

export default OtherDistributorIcon
