import { Grid } from '@mui/material'
import React from 'react'
import ChartBlock from '../../../../../components/features/ChartBlock'
import LineChart from '../../../../../components/features/foodService/dashboard/LineChart'
import LineChartCard from '../../../../../components/features/foodService/dashboard/LineChartCard'
import { LIGHT_MOCK } from '../../../../../components/ui/Colors'
import { useAppSelector } from '../../../../../redux/hooks'
import { EnvName, RetailProduct, type Products } from '../../../../../redux/user/userTypes'
import { Separator } from '../../../../../components/ui/Separator'
import { Units } from '../../../../../redux/global/globalTypes'

export interface InformationsListConfig {
    title: string
    data: number
    unit: Units
    tooltip: string
}

export const getFSInformationsListConfig = (data: Products): InformationsListConfig[] => {
    const { unit } = useAppSelector((state) => state.global)
    const envName = useAppSelector((state) => state.global.env.name)
    return [
        {
            title: "Nombre de références",
            data: data.products,
            unit: Units.references,
            tooltip: `Nombre total de références dans votre famille ${data._id}.`,
        },
        {
            title: "VMM",
            data: Math.round(data.vmm),
            unit: unit,
            tooltip: `Volume moyen vendu mensuellement de votre famille ${data._id}.`,
        },
        ...(envName === EnvName.FRANCE_FRAIS) ? [{
            title: "Utilisateurs finaux",
            data: Math.round(data.clients),
            unit: Units.clients,
            tooltip: `Nombre total d'utilisateurs finaux ayant acheté au moins une référence dans votre famille ${data._id}.`
        }] : []
    ]
}

export const UniqueCard = ({ data, unit, informationsListConfig, tooltipTop }: { data: RetailProduct | Products, unit: Units, informationsListConfig: InformationsListConfig[], tooltipTop: string }) => {
    return (
        <Grid
            container
            direction="column"
            wrap="nowrap"
            flex={1.5}
            sx={{ borderRight: `1px solid ${LIGHT_MOCK}` }}
        >
            <LineChartCard isTop border={false} difference={data.differenceWeight} informationsListConfig={informationsListConfig} tooltip={tooltipTop} lineChartTitle={top.name} unit={unit} />
            <Separator />
        </Grid>
    )
}

export const MultipleCards = ({
    top,
    flop,
    informationsListConfigTop,
    informationsListConfigFlop,
    unit,
    tooltipFlop,
    tooltipTop
}: {
    top: RetailProduct | Products
    flop: RetailProduct | Products
    informationsListConfigTop: InformationsListConfig[],
    informationsListConfigFlop: InformationsListConfig[],
    unit: Units
    tooltipTop: string
    tooltipFlop: string,
}) => {

    return (
        <Grid container direction="column" wrap="nowrap" flex={1.5}>
            <LineChartCard isTop difference={top.differenceWeight} informationsListConfig={informationsListConfigTop} tooltip={tooltipTop} lineChartTitle={top.name} unit={unit} />
            <Separator />
            <LineChartCard isTop={false} difference={flop.differenceWeight} informationsListConfig={informationsListConfigFlop} tooltip={tooltipFlop} lineChartTitle={flop.name} unit={unit} />
        </Grid>
    )
}

const FsLineChart = () => {
    const { products } = useAppSelector((state) => state.global.fsDashboardData)
    const { unit } = useAppSelector((state) => state.global)
    const { name } = useAppSelector((state) => state.global.env)
    const tooltipTop = "Croissance maximale enregistrée par rapport à l’année précédente sur la même période."
    const tooltipFlop = "Recul maximal (ou croissance minimale) enregistré par rapport à l'année précédente sur la même période."

    return (
        <ChartBlock
            title={`Ventes par famille par mois (${unit})`}
            link={`/food-service/${name}/produits`}
        >
            <Grid container wrap="nowrap" height="100%">
                {products.length > 1 ? (
                    <MultipleCards
                        top={products[0]}
                        flop={products[products.length - 1]}
                        informationsListConfigTop={getFSInformationsListConfig(products[0])}
                        informationsListConfigFlop={getFSInformationsListConfig(products[products.length - 1])}
                        unit={unit}
                        tooltipTop={tooltipTop}
                        tooltipFlop={tooltipFlop}
                    />
                ) : (
                    <UniqueCard data={products[0]}
                        unit={unit}
                        informationsListConfig={getFSInformationsListConfig(products[0])} tooltipTop={tooltipTop} />
                )}
                <Grid item flex={3} p="0px 1vw 1vw 1vw">
                    <LineChart />
                </Grid>
            </Grid>
        </ChartBlock>
    )
}

export default FsLineChart
