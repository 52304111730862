export interface PromotionsState {
    impact: Impact
    promoKpis: PromotionsKpis
    performance: Performance
    cost: Cost
    assortment: Assortment
    warehouses: PromoWarehouse[]
}

//to be modified
export interface Impact {
    promoWeight: number,
    differencePromoWeight: number,
    stockagePercentage: number,
    globalTendency: number,
    chart: {
        sellOut: number,
        sellInGlobal: number,
        sellInPromo: number
    },
    incremental: number,
    differenceSellOut: number,
    evolSellOut: number
}

export interface Assortment {
    productsList: Product[]
}

export interface Product {
    id: string;
    name: string;
    promo: number;
    volSellIn: number;
    volSellOut: number;
}

export enum UnityType {
    TRANSACTIONS = 'transactions',
    UF = "utilisateurs-finaux",
    KG = "kg",
    CA = "% ca",
}
export interface Cost {
    CACUF: number,
    CACVA: number,
    differenceCACUF: number,
    evolCACUF: number,
    differenceInvestPerKg: number,
    evolInvestPerKg: number,
    unityType: UnityType
}

export interface Performance {
    basket: number,
    differenceBasket: number,
    evolBasket: number,
    UF: number,
    differenceUF: number,
    evolUF: number,
    chart: PerformanceChart
    unityType: UnityType
}

interface PerformanceChart {
    labels: string[]
    data: number[],
    volume: number[],
    evolVolume: number[],
    clients: number[],
    evolClients: number[]
}

export interface PromotionsKpis {
    volumeSellOut: number,
    differenceSellOut: number,
    evolutionSellOut: number,

    volumeSellIn: number,
    differenceSellIn: number,
    evolutionSellIn: number,

    volumePromo: number,
    differencePromo: number,
    evolutionPromo: number,

    budgetTotal: number,
    budgetInvest: number,
    percentageBudget: number,
}

export interface MultiAxesGraph {
    labels: string[]
    data: number[]
    evolution: number[]
    name: string
}

export interface PromoWarehouse {
    id: string;
    products: number;
    clients: number;
    warehouse: string;
    volume: number;
    incremental: number;
    unity: UnityType
}