import React from 'react'
import { routerType } from '../../PublicNavigation'
import { KaryonModules } from '../../../../../../redux/user/userTypes'
import { PagesName } from '../../../types/pages'
import { CustomersPageEpiSaveurs } from '../../../../../../pages/private/Foodservice/EpiSaveurs/CustomerPage'
import { ProductsPageEpiSaveurs } from '../../../../../../pages/private/Foodservice/EpiSaveurs/ProductsPage'
import { WarehousesPageEpiSaveurs } from '../../../../../../pages/private/Foodservice/EpiSaveurs/WarehousesPage'
import { ViewPage } from '../../../../views/ViewPage'
import DashboardPage from '../../../../../../pages/private/Foodservice/Pages/Dashboard/DashboardPage'

export const EpiSaveursRouter = (modules: KaryonModules[]): routerType[] => [
    {
        path: 'dashboard/',
        element: <DashboardPage />,
        title: PagesName.DASHBOARD,
    },
    {
        path: 'clients/',
        element: <CustomersPageEpiSaveurs />,
        title: PagesName.CLIENTS,
    },
    {
        path: 'depots/',
        element: <WarehousesPageEpiSaveurs />,
        title: PagesName.WAREHOUSES,
    },
    {
        path: 'produits/',
        element: <ProductsPageEpiSaveurs />,
        title: PagesName.PRODUCTS,
    },
    {
        path: 'vues/:viewId/',
        element: <ViewPage />,
        title: PagesName.VIEWS,
    },
]
