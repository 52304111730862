import React from 'react'
import PrivatePage from '../../../../components/features/GlobalPages/Private/PrivatePage'
import Filters, {
    FiltersType,
} from '../../../../components/features/filters/Filters'
import { useAppSelector } from '../../../../redux/hooks'
import { PromoLayout } from '../layout/PromoLayout'
import { PromoPageV2 } from '../Pages/PromotionPage/page/PromoPageV2'
import { PagesName } from '../../../../components/features/navigation/types/pages'
import { PrimaryText } from '../../../../components/ui/Text'
import { MenuType } from '../../../../components/features/filters/utils'
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Box } from '@mui/system'
import { BLACK } from '../../../../components/ui/Colors'
import { Grid } from '@mui/material'

export interface WarningElement {
    warningElement: JSX.Element,
    filters: MenuType[]
}

const warning = {
    warningElement: <Box sx={{ backgroundColor: "#FFF4BC", }} p="1vw"> <Grid container alignItems="center"><WarningAmberIcon sx={{
        color: BLACK, borderColor: BLACK, outlineColor: BLACK, mr: "0.5vw", height: '2vh',
    }} /><PrimaryText color={BLACK}>Ce filtre ne s’applique que sur les données sell-out.</PrimaryText></Grid><PrimaryText color={BLACK}>Tous les indicateurs basés sur le sell-in resteront globaux (volumes, investissements).</PrimaryText></Box>,
    filters: [MenuType.CLIENTS]
}

export const PromoPage = () => {
    const { activeFsFilters } = useAppSelector((state) => state.filters)

    return (

        <PrivatePage period title="Promo" page={PagesName.PROMO}>
            <Filters filtersType={FiltersType.FS} filters={activeFsFilters} page={PagesName.PROMO} warningElement={warning} />
            <PromoLayout filters={activeFsFilters}>
                <PromoPageV2 />
            </PromoLayout>
        </PrivatePage >
    )
}
