import React from 'react'
import { Grid, Box } from '@mui/material'
import { BLACK, MEDIUM_GREY } from '../../ui/Colors'
import { useAppSelector } from '../../../redux/hooks'
import { PrimaryText } from '../../ui/Text'
import MainFilter, { FiltersData } from './selects/MainFilter'
import { EnvType } from '../../../redux/user/userTypes'
import { MenuType } from './utils'
import { PagesName } from '../navigation/types/pages'
import { WarningElement } from '../../../pages/private/Foodservice/FFR/PromoPage'

export enum DepthOfData {
    ONE = 1,
    TWO = 2,
    THREE = 3,
}

interface OwnProps {
    name: string
    icon: JSX.Element
    placeholder: string
    type: MenuType
    values: string[]
    setFieldValue: (field: string, value: any) => void
    env: EnvType
    export?: boolean
    page?: PagesName
    submitFilters?: () => void
    isDialogFilter?: boolean
    warningElement?: WarningElement
}

interface RenderedFilterProps {
    selected: string[]
    icon: JSX.Element
    placeholder: string
    menu: any[]
    data: FiltersData[]
    lengthOfChildren: number
    depth: DepthOfData
}

const getProductName = (
    depth: DepthOfData,
    data: FiltersData[],
    selected: string[]
) => {
    switch (depth) {
        case DepthOfData.THREE:
            const elementSelected = data
                .map((el) =>
                    el.children
                        .map((el) =>
                            el.children.filter((el) => el.id === selected[0])
                        )
                        .flat()
                )
                .flat()[0]
            return elementSelected && elementSelected.name

        case DepthOfData.ONE:
            const productSelected = data.filter(
                (el) => el.id === selected[0]
            )[0]
            return productSelected && productSelected.name
        case DepthOfData.TWO:
            const elementSelected2 = data
                .map((el) => el.children.filter((el) => el.id === selected[0]))
                .flat()[0]
            return elementSelected2 && elementSelected2.name
        default:
            return selected[0]
    }
}

export const RenderedFilter = ({
    selected,
    icon,
    placeholder,
    lengthOfChildren,
    data,
    depth,
}: RenderedFilterProps) => {
    const numberOfSelected =
        selected.length === 0 ? lengthOfChildren : selected.length

    return (
        <Grid
            container
            alignItems="center"
            wrap="nowrap"
            data-testid="placeholderGrid"
        >
            <Box
                sx={{
                    color: MEDIUM_GREY,
                    mr: 2,
                    '& svg': {
                        width: '2vw',
                        height: '2vh',
                        mt: '0.8vh',
                    },
                }}
            >
                {icon}
            </Box>
            <PrimaryText
                color={BLACK}
                overflow="hidden"
                textOverflow="ellipsis"
            >
                {selected.length === 1
                    ? getProductName(depth, data, selected)
                    : `${placeholder} (${numberOfSelected}/${lengthOfChildren})`}
            </PrimaryText>
        </Grid>
    )
}

const FilterSelect = ({ type, page, submitFilters, isDialogFilter = false, warningElement, ...props }: OwnProps) => {
    const envName = props.env
        ? props.env
        : useAppSelector((state) => state.global.env.type)

    const allFilters = useAppSelector((state) => state.filters)

    const filters = () => {
        switch (props.env) {
            case EnvType.FOOD_SERVICE:
                return allFilters.fsFilters
            case EnvType.RETAIl:
                return allFilters.retailFilters
            case EnvType.FOOD_SERVICE_CROSS:
                return allFilters.fsCrossFilters
            default:
                return allFilters.fsFilters
        }
    }

    if (
        (envName === EnvType.FOOD_SERVICE && !allFilters.fsFilters) ||
        (envName === EnvType.RETAIl && !allFilters.retailFilters) ||
        (envName === EnvType.FOOD_SERVICE_CROSS && !allFilters.fsCrossFilters)
    )
        return null

    return (
        <MainFilter
            data={filters()[type].data as any}
            type={type}
            filtersValues={props.values}
            depth={filters()[type].depth}
            page={page}
            submitFilters={submitFilters}
            isDialogFilter={isDialogFilter}
            warningElement={warningElement}
            {...props}
        />
    )
}

export default FilterSelect
