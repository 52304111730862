import React from "react"
import { Chip, Grid } from "@mui/material"
import { BLACK, BRIC, GREEN, RED, SECONDARY } from "../ui/Colors"
import { PrimaryText, SecondaryText } from "../ui/Text"
import Tendency from "../ui/Tendency"
import { Units } from "../../redux/global/globalTypes"
import { roundIfNotZero } from "../../helpers/roundIfNotZero"
import NumberWithSpace from "../ui/NumberWithSpace"
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'

export const ChipCard = ({ productName, number, isTop, unit }: { productName: string, number: number, isTop: boolean, unit: Units }) => {

    const colorChip = isTop ? SECONDARY : BRIC
    const colorText = isTop ? GREEN : "#803F31"
    const colorTendency = number > 0 ? GREEN : RED
    const chipTitle = isTop ? "La + génératrice de volume" : "La - génératrice de volume"



    return (
        <Grid container flex={1} direction="column" >
            <Chip
                label={chipTitle}
                sx={{
                    color: colorText,
                    background: colorChip,
                    width: "75%",
                    mb: "1vh"
                }}
            />
            <PrimaryText color={BLACK} >{productName}</PrimaryText>
            <Grid container mt="1vh" width="100%" alignItems="center">
                <Grid >
                    <PrimaryText color={colorTendency} >Incrémental = </PrimaryText>
                </Grid>
                <Grid >
                    {number > 0 ?
                        <Tendency difference={number} unit={unit} ></Tendency>
                        :
                        <Grid container>
                            <ArrowDownwardIcon sx={{ color: RED, height: '2vh', ml: "0.5vw", mr: "0.5vw" }} />
                            <SecondaryText color={RED}>
                                <NumberWithSpace
                                    number={0}
                                />
                                {`${unit}`}
                            </SecondaryText>
                        </Grid>
                    }
                </Grid>
            </Grid>
        </Grid >
    )
}