import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import client from '../../api/config'
import { ActiveFsFilters } from '../filters/filtersTypes'
import { setGlobalKPIs, setLoading, setUnit } from '../global/globalSlice'
import { AppThunk } from '../store'
import {
    ClientItem,
    Clients,
    DrillDownCategories,
    DrillDownPromoClients,
    DrillDownPromoInformations,
    FsProductState,
    ProductItemFS,
    ProductNotDistribuedItemFS,
    Products,
    WarehouseItem,
    Warehouses,
} from './fsProductsTypes'
import { Units } from '../global/globalTypes'

const initialState: FsProductState = {
    clients: null,
    warehouses: null,
    products: null,
    drillDownProducts: null,
    drillDownProductsNotDistributed: null,
    drillDownWarehouses: null,
    drillDownClients: null,
    drillDownPromoClients: null,
    drillDownPromoInformations: null,
    drillDownCategories: null,
    drillDownWarehousesNotDistributors: null,
}

const fsProductSlice = createSlice({
    name: 'fs-products',
    initialState,
    reducers: {
        setClients: (state, action: PayloadAction<Clients>) => {
            state.clients = action.payload
        },
        setWarehouses: (state, action: PayloadAction<Warehouses>) => {
            state.warehouses = action.payload
        },
        setProducts: (state, action: PayloadAction<Products>) => {
            state.products = action.payload
        },
        setDrillDownProducts: (
            state,
            action: PayloadAction<ProductItemFS[]>
        ) => {
            state.drillDownProducts = action.payload
        },
        setDrillDownProductsNotDistributed: (
            state,
            action: PayloadAction<ProductNotDistribuedItemFS[]>
        ) => {
            state.drillDownProductsNotDistributed = action.payload
        },
        setDrillDownWarehouses: (
            state,
            action: PayloadAction<WarehouseItem[]>
        ) => {
            state.drillDownWarehouses = action.payload
        },
        setDrillDownWarehousesNotDistributors: (
            state,
            action: PayloadAction<any[]>
        ) => {
            state.drillDownWarehousesNotDistributors = action.payload
        },
        setDrillDownClients: (state, action: PayloadAction<ClientItem[]>) => {
            state.drillDownClients = action.payload
        },
        setDrillDownPromo: (
            state,
            action: PayloadAction<DrillDownPromoClients>
        ) => {
            state.drillDownPromoClients = action.payload
        },
        setDrillDownPromoInformations: (
            state,
            action: PayloadAction<DrillDownPromoInformations>
        ) => {
            state.drillDownPromoInformations = action.payload
        },
        setDrillDownCategories: (
            state,
            action: PayloadAction<DrillDownCategories[]>
        ) => {
            state.drillDownCategories = action.payload
        },

    },
})

export const {
    setClients,
    setWarehouses,
    setProducts,
    setDrillDownProducts,
    setDrillDownWarehouses,
    setDrillDownClients,
    setDrillDownPromo,
    setDrillDownPromoInformations,
    setDrillDownCategories,
    setDrillDownProductsNotDistributed,
    setDrillDownWarehousesNotDistributors,
} = fsProductSlice.actions
export default fsProductSlice.reducer

export const fetchFsClients =
    (filters: ActiveFsFilters): AppThunk<Promise<void>> =>
        async (dispatch, getState) => {
            const distributor = getState().global.env.name
            const unit = getState().global.unit
            const company = getState().user.user.brand

            dispatch(setLoading(true))
            try {
                const results = await client
                    .post(
                        `/fs/clients?distrib=${distributor}&unit=${unit}`,
                        filters
                    )
                    .then((response) => {
                        return {
                            clients: {
                                segmentation: response.data.segmentation[0],
                                list: response.data.list,
                            } as Clients,
                        }
                    })
                    .catch((err) => {
                        console.log('ERROR : ', err)
                        return null
                    })

                dispatch(setUnit(Units.kg))

                dispatch(setClients(results.clients))
                dispatch(setLoading(false))
            } catch (e) {
                console.log('ERROR : ', e)
            }
        }

export const fetchWarehouses =
    (filters: ActiveFsFilters): AppThunk<Promise<void>> =>
        async (dispatch, getState) => {
            const distributor = getState().global.env.name
            const unit = getState().global.unit

            dispatch(setLoading(true))
            try {
                const results = await client
                    .post(
                        `/fs/warehouses?distrib=${distributor}&unit=${unit}`,
                        filters
                    )
                    .then((response) => {
                        return {
                            warehouses: { list: response.data.list } as Warehouses,
                        }
                    })
                    .catch((err) => {
                        console.log('ERROR : ', err)
                        return null
                    })

                dispatch(setWarehouses(results.warehouses))
                dispatch(setLoading(false))
            } catch (e) {
                console.log('ERROR : ', e)
            }
        }

export const fetchFsProducts =
    (filters: ActiveFsFilters): AppThunk<Promise<void>> =>
        async (dispatch, getState) => {
            const distributor = getState().global.env.name
            const unit = getState().global.unit

            dispatch(setLoading(true))
            try {
                const results = await client
                    .post(
                        `/fs/productsNew?distrib=${distributor}&unit=${unit}`,
                        filters
                    )
                    .then((response) => {
                        return {
                            product: {
                                chart: response.data.chart,
                                list: response.data.list,
                                period: response.data.chart.timeline,
                            } as Products,
                        }
                    })
                    .catch((err) => {
                        console.log('ERROR : ', err)
                        return null
                    })

                dispatch(setUnit(Units.kg))

                dispatch(setProducts(results.product))
                dispatch(setLoading(false))
            } catch (e) {
                console.log('ERROR : ', e)
            }
        }

export const fetchDrillDownProducts =
    (filters: ActiveFsFilters): AppThunk<Promise<void>> =>
        async (dispatch, getState) => {
            const distributor = getState().global.env.name
            const unit = getState().global.unit

            try {
                const result = await client
                    .post(
                        `/fs/products/detail?distrib=${distributor}&unit=${unit}`,
                        filters
                    )
                    .then((response) => {
                        return response.data
                    })
                    .catch((err) => {
                        console.log('ERROR : ', err)
                        return null
                    })
                dispatch(setDrillDownProducts(result))
            } catch (e) {
                console.log('ERROR : ', e)
            }
        }

export const fetchDrillDownWarehouses =
    (filters: ActiveFsFilters): AppThunk<Promise<void>> =>
        async (dispatch, getState) => {
            const distributor = getState().global.env.name
            const unit = getState().global.unit

            try {
                const result = await client
                    .post(
                        `/fs/products/warehouses/detail?distrib=${distributor}&unit=${unit}`,
                        filters
                    )
                    .then((response) => {
                        return response.data
                    })
                    .catch((err) => {
                        console.log('ERROR : ', err)
                        return null
                    })

                dispatch(setDrillDownWarehouses(result))
            } catch (e) {
                console.log('ERROR : ', e)
            }
        }

export const fetchDrillDownWarehousesNotDistributors =
    (filters: ActiveFsFilters): AppThunk<Promise<void>> =>
        async (dispatch, getState) => {
            const distributor = getState().global.env.name
            const unit = getState().global.unit

            try {
                const result = await client
                    .post(
                        `/fs/products/warehousesNotDistributors/detail?distrib=${distributor}&unit=${unit}`,
                        filters
                    )
                    .then((response) => {
                        return response.data
                    })
                    .catch((err) => {
                        console.log('ERROR : ', err)
                        return null
                    })

                dispatch(setDrillDownWarehousesNotDistributors(result))
            } catch (e) {
                console.log('ERROR : ', e)
            }
        }

export const fetchDrillDownClients =
    (filters: ActiveFsFilters): AppThunk<Promise<void>> =>
        async (dispatch, getState) => {
            const distributor = getState().global.env.name
            const unit = getState().global.unit

            try {
                const result = await client
                    .post(
                        `/fs/products/clients/detail?distrib=${distributor}&unit=${unit}`,
                        filters
                    )
                    .then((response) => {
                        return response.data
                    })
                    .catch((err) => {
                        console.log('ERROR : ', err)
                        return null
                    })

                dispatch(setDrillDownClients(result))
            } catch (e) {
                console.log('ERROR : ', e)
            }
        }

export const fetchDrillDownProductsNotDistributed =
    (filters: ActiveFsFilters): AppThunk<Promise<void>> =>
        async (dispatch, getState) => {
            const distributor = getState().global.env.name
            const unit = getState().global.unit

            try {
                const result = await client
                    .post(
                        `/fs/productsNotDistributed/detail?distrib=${distributor}&unit=${unit}`,
                        filters
                    )
                    .then((response) => {
                        return response.data
                    })
                    .catch((err) => {
                        console.log('ERROR : ', err)
                        return null
                    })

                dispatch(setDrillDownProductsNotDistributed(result))
            } catch (e) {
                console.log('ERROR : ', e)
            }
        }



export const fetchDrillDownCategories =
    (filters: ActiveFsFilters): AppThunk<Promise<void>> =>
        async (dispatch, getState) => {
            const distributor = getState().global.env.name
            const unit = getState().global.unit

            try {
                const result = await client
                    .post(
                        `/fs/products/categories/detail?distrib=${distributor}&unit=${unit}`,
                        filters
                    )
                    .then((response) => {
                        return response.data
                    })
                    .catch((err) => {
                        console.log('ERROR : ', err)
                        return null
                    })

                dispatch(setDrillDownCategories(result))
            } catch (e) {
                console.log('ERROR : ', e)
            }
        }
