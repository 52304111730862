import React, { useCallback, useEffect, useState } from 'react'
import { Grid } from '@mui/material'
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks'
import Loading from '../../../../../components/features/screens/LoadingScreen'
import { fetchFilteredDashboard } from '../../../../../redux/global/globalSlice'
import { type ActiveRetailFilters } from '../../../../../redux/filters/filtersTypes'
import Indicator from './indicator/Indicator'
import RetailSegmentation from './RetailSegmentation'
import RegionTable from './RegionTable'
import { DashboardMap } from './DashboardMap'
import RetailKpis from './RetailKpis'
import WaitingScreen from '../../../../../components/features/screens/WaitingScreen'
import { NoDataScreen } from '../../../../../components/features/screens/NoDataScreen'
import { HistogramRetail } from './histogram/HistogramRetail'
import { EnvStatus } from '../../../../../redux/user/userTypes'
import { dashboardLabels } from '../../../../../components/features/foodService/dashboard/histogram/HistogramLabels'
import { Units } from '../../../../../redux/global/globalTypes'

const CarrefourDashboard = () => {
    const [isFirstLoad, setIsFirstLoad] = useState(true)
    const dashboard = useAppSelector(
        (state) => state.global.retailDashboardData
    )
    const { activeRetailFilters } = useAppSelector((state) => state.filters)
    const { isLoading, env } = useAppSelector((state) => state.global)
    const { brand } = useAppSelector((state) => state.user.user)
    const dispatch = useAppDispatch()
    const _fetchRetailDashboard = useCallback(
        async (filters: ActiveRetailFilters) => {
            await dispatch(fetchFilteredDashboard(filters, false))
        },
        [dispatch]
    )

    useEffect(() => {
        if (brand && activeRetailFilters.startDate) {
            _fetchRetailDashboard(activeRetailFilters)
            setIsFirstLoad(false)
        }
    }, [brand, activeRetailFilters, _fetchRetailDashboard])

    if (
        isLoading ||
        isFirstLoad ||
        (!dashboard && env.status !== EnvStatus.ON_LOADING_INIT)
    ) {
        return <Loading />
    }

    if (
        !dashboard ||
        !activeRetailFilters.startDate ||
        env.status === EnvStatus.ON_LOADING_INIT
    ) {
        return <WaitingScreen />
    }

    if (dashboard.products.length === 0) {
        return <NoDataScreen />
    }

    return (
        <Grid container direction="column" wrap="nowrap" height="90vh" p="1vw">
            <RetailKpis />
            <Grid
                container
                direction="column"
                wrap="nowrap"
                justifyContent="flex-start"
                pt="1vw"
                sx={{ height: '78vh' }}
            >
                <Grid
                    container
                    direction="column"
                    wrap="nowrap"
                    justifyContent="flex-start"
                    sx={{ height: '42%' }}
                >
                    <Grid container sx={{ height: '100%' }} flexWrap="nowrap">
                        <Grid item flex={1.5} sx={{ height: '100%' }}>
                            <Indicator />
                        </Grid>
                        <Grid item flex={4} pl="1vw">
                            <HistogramRetail unit={Units.uvc} tooltipTitle='Volume' labels={dashboardLabels} title={"Total des ventes par mois (UVC)"} chartData={dashboard.productsChart} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container wrap="nowrap" pt="1vw" sx={{ height: '58%' }}>
                    <Grid
                        item
                        flex={1.5}
                        sx={{ height: '100%', width: '15vw' }}
                    >
                        <RetailSegmentation />
                    </Grid>
                    <Grid item flex={1.5} pl="1vw" sx={{ height: '100%' }}>
                        <RegionTable />
                    </Grid>
                    <Grid item flex={1.5} pl="1vw" sx={{ height: '100%' }}>
                        <DashboardMap />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default CarrefourDashboard
