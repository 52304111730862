import React, { useCallback, useEffect } from 'react'
import {
    Form,
    type FormikBag,
    type FormikProps,
    withFormik,
} from 'formik'
import { Box, Dialog, DialogContent, Grid } from '@mui/material'
import { array, object, string } from 'yup'
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks'
import { Env, EnvName, EnvStatus, EnvType, KaryonModules } from '../../../../redux/user/userTypes'
import { ErrorMessageForm, PrimaryText } from '../../../../components/ui/Text'
import { BLACK } from '../../../../components/ui/Colors'
import { DialogFooter } from '../../../../components/features/Dialog/BasicFooterDialog'
import { CheckboxList } from '../../../../components/ui/CheckboxList'
import { listOfFSDistributors } from '../../Foodservice/types'
import { getEnvNames } from './utilsAdminPage'
import { getModulesNames, ModulesFrenchNames } from './InvitationEnvStep'
import { DialogHeader } from '../../../../components/features/Dialog/BasicDialog'
import { UpdateUserEnvAndModules } from '../../../../redux/user/userSlice'
import { Member } from '../../../../redux/companies/companiesTypes'

interface FormValues {
    env: string[]
    modules: string[]
}

interface FormProps {
    isDialogOpen: boolean
    setIsDialogOpen: any
    initialValues: FormValues
    onSubmit: (
        values: FormValues,
        bag: FormikBag<FormProps, FormValues>
    ) => void
    userValues: any,
}


const PureSettingsForm = ({
    isDialogOpen,
    setIsDialogOpen,
    userValues,
    ...props
}: FormikProps<FormValues> & FormProps): JSX.Element => {
    const { env } = useAppSelector((state) => state.companies.company)
    const { companyModules } = useAppSelector((state) => state.companies.company)
    const envNames = getEnvNames(env)
    const userEnvNames = getEnvNames(userValues.env)
    const userModulesNames = getModulesNames(userValues.modules)
    const modulesNames = getModulesNames(companyModules)

    useEffect(() => {
        props.setFieldValue(
            'env', userEnvNames)
        props.setFieldValue(
            'modules', userModulesNames)
    }, [])

    const handleChangeEnvironnement = (envSelected) => {
        const isInclude = props.values.env.includes(envSelected)

        if (isInclude) {
            props.setFieldValue(
                'env',
                props.values.env.filter((el) => el !== envSelected)
            )
        } else {
            props.setFieldValue('env', [...props.values.env, envSelected])
        }


    }
    const handleChangeModules = (modulesSelected) => {
        const isInclude = props.values.modules.includes(modulesSelected)

        if (isInclude) {
            props.setFieldValue(
                'modules',
                props.values.modules.filter((el) => el !== modulesSelected)
            )
        } else {
            props.setFieldValue('modules', [...props.values.modules, modulesSelected])
        }
    }

    return (
        <Dialog
            open={isDialogOpen}
            fullWidth={true}
            onClose={() => setIsDialogOpen(false)}
            PaperProps={{
                sx: {
                    borderRadius: '1em',
                    minWidth: '55vw',
                    padding: '2vh 2vw 2vh 2vw',
                },
            }}
        >
            <DialogHeader
                title={`${userValues.firstname} ${userValues.lastname}`}
                setOpen={() => setIsDialogOpen(false)}
            />
            <Form style={{ width: '100%' }}>
                <DialogContent sx={{ height: '42vh' }}>
                    <PrimaryText color={BLACK} mb="3vh" mt="3vh" bold>Sélectionnez les environnements et modules à mettre à disposition</PrimaryText>
                    <Grid container direction="row">
                        <CheckboxList titleList="Environnements disponibles" list={envNames} handleChange={handleChangeEnvironnement} checkedList={props.values.env} />
                        <CheckboxList titleList="Modules disponibles" list={modulesNames} handleChange={handleChangeModules} checkedList={props.values.modules} />
                    </Grid>
                    <Box mt="2vh">
                        <ErrorMessageForm message={props.errors.env} />
                    </Box>
                </DialogContent>
                <DialogFooter
                    leftButtonFunc={() => {
                        setIsDialogOpen(false)
                    }
                    }
                    leftButtonText={'FERMER'}
                    rightButtonFunc={async () => {
                        await props.submitForm()
                    }}
                    rightButtonText={
                        `MODIFIER`
                    }
                />
            </Form>
        </Dialog>
    )
}

const ControlledSettingsForm = withFormik<FormProps, FormValues>({
    mapPropsToValues: (props) => ({
        env: props.initialValues.env,
        modules: props.initialValues.modules,
    }),
    validationSchema: () => {
        return object().shape({
            env: array()
                .of(string().required())
                .required().min(1, 'Sélectionnez au moins un environnement'),
            modules: array()
                .of(string())
                .optional()
        })
    },
    handleSubmit: (
        values: FormValues,
        bag: FormikBag<FormProps, FormValues>
    ) => {
        bag.props.onSubmit(values, bag)
    },
})(PureSettingsForm)

const UpdateEnvDialog = ({ isDialogOpen, setIsDialogOpen, values, closeMenu }: { isDialogOpen: boolean, setIsDialogOpen: React.Dispatch<React.SetStateAction<boolean>>, values: Member, closeMenu: React.Dispatch<React.SetStateAction<boolean>> }) => {

    const dispatch = useAppDispatch()
    const _disableMember = useCallback((email: string, env: Env[], modules: KaryonModules[]) => dispatch(UpdateUserEnvAndModules(email, env, modules)), [dispatch])

    const handleSubmit = (formValues: FormValues) => {

        const environnements = formValues.env.map((env) => {
            if ((Object.values(listOfFSDistributors) as string[]).includes(env)) {
                return {
                    name: env as EnvName,
                    type: EnvType.FOOD_SERVICE,
                    status: EnvStatus.ACTIVATED,

                }
            } else {
                return {
                    name: env as EnvName,
                    type: EnvType.RETAIl,
                    status: EnvStatus.ACTIVATED,
                }
            }
        })

        const modules = formValues.modules.map((module) => {
            switch (module) {
                case ModulesFrenchNames.CROSS:
                    return KaryonModules.CROSS
                case ModulesFrenchNames.PROMO:
                    return KaryonModules.PROMO
            }
        })
        _disableMember(values.email, environnements, modules)
        setIsDialogOpen(false)
        closeMenu(false)

    }
    return (
        <ControlledSettingsForm
            setIsDialogOpen={setIsDialogOpen}
            onSubmit={handleSubmit}
            initialValues={{
                env: [],
                modules: [],
            }}
            isDialogOpen={isDialogOpen}
            userValues={values}
        />
    )
}

export default UpdateEnvDialog
