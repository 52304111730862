import { Typography, type TypographyProps } from '@mui/material'
import React from 'react'
import { BLACK, DARK_BLUE, DARK_GREY } from './Colors'

interface TextProps extends Omit<TypographyProps, 'sx'> {
    bold?: boolean
    color?: string
    textDecoration?: string
    wrap?: string
    fontSize?: {}
}

export const PagesTitle = ({
    color = BLACK,
    ml = "1vw",
    ...props
}: TextProps) => {
    return <Typography
        sx={{ fontSize: '1.7vw', fontWeight: 400, cursor: "default", fontFamily: 'Ubuntu', ml: ml }}
        ml="1vw"
        {...props}
    >
        {props.children}
    </Typography>
}


export const Title = ({
    bold,
    color = DARK_BLUE,
    textDecoration = 'none',
    ...props
}: TextProps) => (
    <Typography
        sx={{
            color: color,
            fontWeight: bold ? 700 : 400,
            fontSize: { xs: '30px', md: '3vw' },
            textDecoration: textDecoration,
            fontFamily: 'Ubuntu',
            cursor: "default"
        }}
        {...props}
    >
        {props.children}
    </Typography>
)

export const TitleBis = ({
    bold,
    color = DARK_GREY,
    textDecoration = 'none',
    ...props
}: TextProps) => (
    <Typography
        sx={{
            color: color,
            fontWeight: bold ? 700 : 400,
            fontSize: { xs: '16px', md: '3.5vmin' },
            fontFamily: 'Cabin',
            textDecoration: textDecoration,
            cursor: "default",
        }}
        {...props}
    >
        {props.children}
    </Typography>
)

export const SubTitle = ({
    bold,
    color = DARK_BLUE,
    textDecoration = 'none',
    ...props
}: TextProps) => (
    <Typography
        sx={{
            color: color,
            fontWeight: bold ? 700 : 400,
            fontSize: { xs: '3vmin', md: '2vmin' },
            fontFamily: 'Ubuntu',
            cursor: "default",
            textDecoration: textDecoration,
        }}
        {...props}
    >
        {props.children}
    </Typography>
)

export const PrimaryText = ({
    bold,
    color = DARK_GREY,
    textDecoration = 'none',
    ...props
}: TextProps) => (
    <Typography
        sx={{
            color: color,
            fontWeight: bold ? 700 : 400,
            fontSize: { xs: '16px', md: '1.7vmin' },
            fontFamily: 'Cabin',
            textDecoration: textDecoration,
            cursor: "default",
        }}
        {...props}
    >
        {props.children}
    </Typography>
)

export const SecondaryText = ({
    bold,
    color = DARK_GREY,
    textDecoration = 'none',
    wrap = "noWrap",
    ...props
}: TextProps) => (
    <Typography
        sx={{
            color: color,
            fontWeight: bold ? 700 : 400,
            fontSize: { xs: '16px', md: '1.4vmin' },
            fontFamily: 'Cabin',
            textDecoration: textDecoration,
            wrap: wrap,
            cursor: "default"
        }}
        {...props}
    >
        {props.children}
    </Typography>
)

export const Annotation = ({
    bold,
    color = DARK_GREY,
    textDecoration = 'none',
    ...props
}: TextProps) => (
    <Typography
        sx={{
            color: color,
            fontWeight: bold ? 700 : 400,
            fontSize: { xs: '16px', md: '1.2vmin' },
            textDecoration: textDecoration,
            cursor: "default"
        }}
        {...props}
    >
        {props.children}
    </Typography>
)

export const TertaryText = ({
    bold,
    color = DARK_GREY,
    textDecoration = 'none',
    fontSize = { xs: '16px', md: '2vmin' },
    ...props
}: TextProps) => (
    <Typography
        sx={{
            color,
            fontWeight: bold ? 700 : 400,
            fontSize,
            fontFamily: 'Cabin',
            textDecoration: textDecoration,
            cursor: "default",
        }}
        {...props}
    >
        {props.children}
    </Typography>
)


export const KpiText = ({
    color = "#6993FF",
    textDecoration = 'none',
    ...props
}: TextProps) => (
    <Typography
        sx={{
            color: color,
            fontSize: { xs: '32px', md: '3vmin' },
            textDecoration: textDecoration,
            cursor: "default",
            fontFamily: 'Cabin',
            pt: "1vh"
        }}
        {...props}
    >
        {props.children}
    </Typography>
)

export const ErrorMessageForm = ({
    message,
}: {
    message: string | string[]
}) => {
    return (
        <Typography
            sx={{
                fontFamily: 'Cabin',
                fontWeight: 400,
                fontSize: '0.75rem',
                color: '#d32f2f',
            }}
        >
            {message}
        </Typography>
    )
}
