import { Units } from "../../../../../redux/global/globalTypes"
import { EnvName, FsCercleVertKPIs, FsEpiSaveursKPIs, FsFFRKPIs, FsMetroKPIs, FsSodexoKPIs, FsTGMKPIs } from "../../../../../redux/user/userTypes"
import { ConfigKPIs } from "./FsKpis"

export const KPIsCercleVertConfig = ({
    KPIs,
    envName,
}: {
    KPIs: FsCercleVertKPIs
    envName: EnvName
}): ConfigKPIs[] => [
        {
            id: 1,
            name: 'Volume sell-out total',
            units: Units.kg,
            description: `Volume total sur la période sélectionnée et variation par rapport à l'année précédente.`,
            number: Math.round(KPIs.volume),
            difference: KPIs.volume - KPIs.volumeLastYear,
            route: `/food-service/${envName}/produits`,
            evolution: KPIs.evolutionVolume
        },
        {
            id: 2,
            name: 'Références',
            units: Units.references,
            description: `Nombre de références vendues sur la période sélectionnée et variation par rapport à l'année précédente`,
            number: Math.round(KPIs.products),
            difference: KPIs.products - KPIs.productsLastYear,
            route: `/food-service/${envName}/produits`,
            evolution: KPIs.evolutionProducts
        },
    ]


export const KPIsEpiSaveursConfig = ({
    KPIs,
    envName,
}: {
    KPIs: FsEpiSaveursKPIs
    envName: EnvName
}): ConfigKPIs[] => [
        {
            id: 1,
            name: 'Volume sell-out total',
            units: Units.kg,
            description: `Volume total livré sur la période sélectionnée et variation par rapport à l'année précédente.`,
            number: Math.round(KPIs.volume),
            difference: KPIs.volume - KPIs.volumeLastYear,
            route: `/food-service/${envName}/produits`,
            evolution: KPIs.evolutionVolume
        },
        {
            id: 2,
            name: 'Références',
            units: Units.references,
            description: `Nombre de références vendue sur la période sélectionnée et variation par rapport à l'année précédente`,
            number: Math.round(KPIs.products),
            difference: KPIs.products - KPIs.productsLastYear,
            route: `/food-service/${envName}/produits`,
            evolution: KPIs.evolutionProducts
        },
        {
            id: 3,
            name: 'VMM',
            units: Units.kgPerWarehouse,
            description: `Ventes moyennes mensuelles : indication en volume de la rotation de vos produits et variation par rapport à l'année précédente.`,
            number: Math.round(KPIs.vmm),
            difference: KPIs.vmm - KPIs.vmmLastYear,
            route: `/food-service/${envName}/depots`,
            evolution: KPIs.evolutionVmm
        },
        {
            id: 4,
            name: 'Dépôts',
            units: Units.warehouses,
            description: `Nombre total de dépôts ayant distribué au moins une de vos références. et variation par rapport à l'année précédente.`,
            number: KPIs.warehouses,
            difference: KPIs.warehouses - KPIs.warehousesLastYear,
            route: `/food-service/${envName}/depots`,
            evolution: KPIs.evolutionWarehouses
        },
    ]


export const KPIsPassionFroidConfig = ({
    KPIs,
    envName,
}: {
    KPIs: FsTGMKPIs
    envName: EnvName
}): ConfigKPIs[] => [
        {
            id: 1,
            name: 'Volume sell-out total',
            units: Units.kg,
            description: `Volume total livré sur la période sélectionnée et variation par rapport à l'année précédente.`,
            number: Math.round(KPIs.volume),
            difference: KPIs.volume - KPIs.volumeLastYear,
            route: `/food-service/${envName}/produits`,
            evolution: KPIs.evolutionVolume
        },
        {
            id: 2,
            name: 'Références',
            units: Units.references,
            description: `Nombre de références vendues sur la période sélectionnée et variation par rapport à l'année précédente`,
            number: Math.round(KPIs.products),
            difference: KPIs.products - KPIs.productsLastYear,
            route: `/food-service/${envName}/produits`,
            evolution: KPIs.evolutionProducts
        },
        {
            id: 3,
            name: 'Dépôts',
            units: Units.warehouses,
            description: `Nombre total de dépôts ayant distribué au moins une de vos références. et variation par rapport à l'année précédente.`,
            number: KPIs.warehouses,
            difference: KPIs.warehouses - KPIs.warehousesLastYear,
            route: `/food-service/${envName}/depots`,
            evolution: KPIs.evolutionWarehouses
        },
    ]

export const KPIsSodexoConfig = ({
    KPIs,
    envName,
}: {
    KPIs: FsSodexoKPIs
    envName: EnvName
}): ConfigKPIs[] => [
        {
            id: 1,
            name: 'Volume sell-out total',
            units: Units.kg,
            description: `Volume total sur la période sélectionnée et variation par rapport à l'année précédente.`,
            number: Math.round(KPIs.volume),
            difference: KPIs.volume - KPIs.volumeLastYear,
            route: `/food-service/${envName}/produits`,
            evolution: KPIs.evolutionVolume
        },
        {
            id: 2,
            name: 'Références',
            units: Units.references,
            description: `Nombre de références vendues sur la période sélectionnée et variation par rapport à l'année précédente`,
            number: Math.round(KPIs.products),
            difference: KPIs.products - KPIs.productsLastYear,
            route: `/food-service/${envName}/produits`,
            evolution: KPIs.evolutionProducts
        },
    ]

export const KPIsTGMConfig = ({
    KPIs,
    envName,
}: {
    KPIs: FsTGMKPIs
    envName: EnvName
}): ConfigKPIs[] => [
        {
            id: 1,
            name: 'Volume sell-out total',
            units: Units.kg,
            description: `Total des volumes facturés sur la période sélectionnée et variation par rapport à l’année précédente.`,
            number: Math.round(KPIs.volume),
            difference: KPIs.volume - KPIs.volumeLastYear,
            route: `/food-service/${envName}/produits`,
            evolution: KPIs.evolutionVolume
        },
        {
            id: 2,
            name: 'Références',
            units: Units.references,
            description: `Nombre de références vendues sur la période sélectionnée et variation par rapport à l'année précédente`,
            number: Math.round(KPIs.products),
            difference: KPIs.products - KPIs.productsLastYear,
            route: `/food-service/${envName}/produits`,
            evolution: KPIs.evolutionProducts
        },
        {
            id: 3,
            name: 'Dépôts',
            units: Units.warehouses,
            description: `Nombre total de points de vente finaux ayant distribué vos références sur la période sélectionnée et variation par rapport à l'année précédente.`,
            number: KPIs.warehouses,
            difference: KPIs.warehouses - KPIs.warehousesLastYear,
            route: `/food-service/${envName}/depots`,
            evolution: KPIs.evolutionWarehouses
        },
    ]


export const KPIsFFRConfig = ({
    KPIs,
    envName,
}: {
    KPIs: FsFFRKPIs
    envName: EnvName
}): ConfigKPIs[] => [
        {
            id: 1,
            name: 'Volume sell-out total',
            units: Units.kg,
            description: `Volume total livré sur la période sélectionnée et variation par rapport à l'année précédente.`,
            number: Math.round(KPIs.volume),
            difference: KPIs.volume - KPIs.volumeLastYear,
            route: `/food-service/${envName}/produits`,
            evolution: KPIs.evolutionVolume
        },
        {
            id: 2,
            name: `Nombre d'utilisateurs finaux`,
            units: Units.clients,
            description: `Nombre total des utilisateurs finaux ayant acheté au moins une de vos références et variation par rapport à l'année précédente.`,
            number: KPIs.clients,
            difference: KPIs.clients - KPIs.clientsLastYear,
            route: `/food-service/${envName}/clients`,
            evolution: KPIs.evolutionClients
        },
        {
            id: 3,
            name: 'VMM',
            units: Units.kgPerWarehouse,
            description: `Ventes moyennes mensuelles : indication en volume de la rotation de vos produits et variation par rapport à l'année précédente.`,
            number: Math.round(KPIs.vmm),
            difference: KPIs.vmm - KPIs.vmmLastYear,
            route: `/food-service/${envName}/produits`,
            evolution: KPIs.evolutionVmm
        },
        {
            id: 4,
            name: 'Dépôts',
            units: Units.warehouses,
            description: `Nombre total de dépôts ayant distribué au moins une de vos références. et variation par rapport à l'année précédente.`,
            number: KPIs.warehouses,
            difference: KPIs.warehouses - KPIs.warehousesLastYear,
            route: `/food-service/${envName}/depots`,
            evolution: KPIs.evolutionWarehouses
        },
    ]

export const KPIsMetroConfig = ({
    KPIs,
    envName,
    unit
}: {
    KPIs: FsMetroKPIs
    envName: EnvName
    unit: Units
}): ConfigKPIs[] => [
        {
            id: 1,
            name: 'Volume sell-out total',
            units: unit === Units.uvc ? Units.uvc : Units.kg,
            description: `Volume total livré sur la période sélectionnée et variation par rapport à l'année précédente.`,
            number: Math.round(KPIs.volume),
            difference: KPIs.volume - KPIs.volumeLastYear,
            route: `/food-service/${envName}/produits`,
            evolution: KPIs.evolutionVolume
        },
        {
            id: 2,
            name: 'Dépôts',
            units: Units.warehouses,
            description: `Nombre total de dépôts ayant distribué au moins une de vos références. et variation par rapport à l'année précédente.`,
            number: KPIs.warehouses,
            difference: KPIs.warehouses - KPIs.warehousesLastYear,
            route: `/food-service/${envName}/depots`,
            evolution: KPIs.evolutionWarehouses
        },
        {
            id: 3,
            name: 'VMM par dépôt',
            units: unit === Units.uvc ? Units.uvcPerWarehouse : Units.kgPerWarehouse,
            description: `Ventes moyennes mensuelles : indication en volume de la rotation de vos produits et variation par rapport à l'année précédente.`,
            number: Math.round(KPIs.vmm),
            difference: KPIs.vmm - KPIs.vmmLastYear,
            route: `/food-service/${envName}/depots`,
            evolution: KPIs.evolutionVmm
        },
        {
            id: 4,
            name: 'Références distribuées',
            units: Units.references,
            description: `Nombre de références vendues sur la période sélectionnée et variation par rapport à l'année précédente`,
            number: Math.round(KPIs.products),
            difference: KPIs.products - KPIs.productsLastYear,
            route: `/food-service/${envName}/produits`,
            evolution: KPIs.evolutionProducts
        },
    ]