import { DepthOfData } from '../FilterSelect'
import { MenuType } from '../utils'
import { FiltersData } from './MainFilter'

export const toggleChangeFilters = (
    level: number,
    el: FiltersData,
    filters: string[]
) => {
    switch (level) {
        case 1: {
            if (el.children) {
                //check if the filters have 2 or 3 levels
                if (
                    el.children
                        .map((children) =>
                            children.children
                                ? children.children.map(
                                    (children) => children.id
                                )
                                : []
                        )
                        .flat().length > 0
                ) {
                    // if 3 levels check if every children of children are in the current filters
                    if (
                        !el.children
                            .map((children) =>
                                children.children.map((children) => children.id)
                            )
                            .flat()
                            .every((el) => filters.flat().includes(el))
                    )
                        // if no, we add them
                        return [
                            ...filters,
                            el.children
                                .map((children) =>
                                    children.children.map((children) =>
                                        [...filters]
                                            .flat()
                                            .includes(children.id) // check if the current filters contains the id of children of children, if yes we add null else we add it (the id)
                                            ? null
                                            : children.id
                                    )
                                )
                                .flat(),
                        ]
                            .flat()
                            .filter((el) => el != null)
                    // if no, we remove them
                    else
                        return filters.flat().filter(
                            (item) =>
                                !el.children
                                    .map((children) =>
                                        children.children.map(
                                            (children) => children.id // remove all ids of children.children from current filters
                                        )
                                    )
                                    .flat()
                                    .includes(item)
                        )
                } // if we have 2 levels check if every of children are in the current filters
                else {
                    if (
                        !el.children
                            .map((children) => children.id)
                            .flat()
                            .every((el) => filters.flat().includes(el))
                    )
                        return [
                            ...filters,
                            el.children
                                .map((children) =>
                                    [...filters].flat().includes(children.id) // check if the current filters contains the id of children, if yes we add null else we add it
                                        ? null
                                        : children.id
                                )
                                .flat(),
                        ]
                            .flat()
                            .filter((el) => el != null)
                    // else we remove them (the ids of the differents children)
                    else
                        return filters.flat().filter(
                            (item) =>
                                !el.children
                                    .map((children) => children.id) // remove all ids of children from filters filters
                                    .flat()
                                    .includes(item)
                        )
                }
            } // if we have one level
            else {
                if (!filters.flat().includes(el.id)) {
                    return [...filters, el.id]
                } else return filters.flat().filter((menu) => menu !== el.id)
            }
        }

        case 2: {
            // if we click on the level 2
            // check if we have children (3 levels)
            if (el.children) {
                // if yes, we check if every children of children are in the current filters
                if (
                    !el.children
                        .map((el) => el.id)
                        .every((el) => filters.flat().includes(el))
                )
                    return [
                        ...filters,
                        el.children.map((children) =>
                            [...filters].flat().includes(children.id)
                                ? null // check if the current filters contains the id of children of the children, if yes we add null else we add it
                                : children.id
                        ),
                    ]
                        .flat()
                        .filter((el) => el != null)
                // else we remove them
                else {
                    return filters.filter(
                        (item) =>
                            !el.children
                                .map((children) => children.id)
                                .includes(item)
                    )
                }
            } // else if we have not children (2 levels)
            else {
                // check if the id exists in the current filter and add it or remove it
                if (!filters.flat().includes(el.id)) {
                    return [...filters, el.id]
                } else return filters.flat().filter((menu) => menu !== el.id)
            }
        }

        case 3: {
            // if we click on the level 3, we check if the id exists in the current filter and add it or remove it
            if (!filters.flat().includes(el.id)) {
                return [...filters, el.id]
            } else return filters.flat().filter((menu) => menu !== el.id)
        }
    }
}

export const isSelected = (
    el: FiltersData,
    filters: string[],
    level: number,
    depth: number
) => {
    if (depth === 1) {
        return filters.includes(el.id)
    }
    //check the level
    if (level === 1) {
        return (
            el.children // check the checkbox if every children are in the current filters
                .map((el) => el.id)
                .every((el) => filters.flat().includes(el)) ||
            el.children // check the checkbox if every  children of children are in  the current filters
                .map(
                    (children) =>
                        children.children &&
                        children.children.map((children) => children.id)
                )
                .flat()
                .every((el) => filters.flat().includes(el))
        )
    } // if it's level 2 or 3 we check if we have children if yes map on children and check if current filters contains the id of children else we check if our id is included on filters
    else
        return el.children
            ? el.children
                .map((el) => el.id)
                .every((el) => filters.flat().includes(el))
            : filters.flat().includes(el.id)
}

export const calculateLengthOfChildren = (
    data: FiltersData[],
    depth: number
) => {
    let lengthOfChildren = 0
    if (!data) return 0
    switch (depth) {
        case 1:
            return data.length
        case 2:
            data.map((el) => (lengthOfChildren += el.children.length))
            return lengthOfChildren
        default:
            data.map(
                (el) =>
                    el.children &&
                    el.children.map((children) => {
                        children.children &&
                            (lengthOfChildren += children.children.length)
                    })
            )
            return lengthOfChildren
    }
}

const allDataSelected = (data: FiltersData[], depth: number) => {
    switch (depth) {
        case 1:
            return data.map((el) => el.id)
        case 2:
            return data
                .map((el) => el.children.map((el) => el.id).flat())
                .flat()
        default:
            return data
                .map((el) =>
                    el.children
                        .map((children) =>
                            children.children.map((children) => children.id)
                        )
                        .flat()
                )
                .flat()
    }
}

export const toggleSelectAll = (
    data: FiltersData[],
    type: MenuType,
    filters: string[],
    setFieldValue: (field: string, value: string[]) => void,
    depth: number
) => {
    const lengthOfChildren = calculateLengthOfChildren(data, depth)
    const allDataIsSelected = allDataSelected(data, depth)

    if (filters.length !== lengthOfChildren) {
        setFieldValue(type, allDataIsSelected)
    } else {
        setFieldValue(type, [])
    }
}

const hasSearchResults = (name: string, searchQuery: string) => {
    return (
        name
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .toLowerCase()
            .indexOf(
                searchQuery
                    .normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, '')
                    .toLowerCase()
            ) > -1
    )
}

export const filterData = (
    data: FiltersData[],
    searchQuery: string,
    depth: DepthOfData
) => {
    if (searchQuery === '') return data
    else {
        const filteredResults = []
        if (depth === DepthOfData.ONE) {
            data.forEach((item) => {
                const hasResults = hasSearchResults(item.name, searchQuery)
                if (hasResults) {
                    filteredResults.push(item)
                }
            })

            return filteredResults
        } else if (depth === DepthOfData.TWO) {
            data.forEach((item) => {
                const hasResult = hasSearchResults(item.name, searchQuery)

                // handle case for non common formatted filter
                if (!item.children && hasResult) {
                    filteredResults.push(item)
                } else if (!item.children) {
                    return
                }

                const filteredGrandchildren = item.children.filter((el) =>
                    hasSearchResults(el.name, searchQuery)
                )

                if (hasResult) {
                    filteredResults.push(item)
                } else if (filteredGrandchildren.length > 0) {
                    filteredResults.push({
                        ...item,
                        children: filteredGrandchildren,
                    })
                }
            })

            return filteredResults
        } else {
            data.forEach((item) => {
                const filteredChildren = []

                const hasResult = hasSearchResults(item.name, searchQuery)

                item.children.forEach((child) => {
                    const hasChildResult = hasSearchResults(
                        child.name,
                        searchQuery
                    )

                    const filteredGrandchildren = child.children.filter(
                        (littleChild) =>
                            hasSearchResults(littleChild.name, searchQuery)
                    )

                    if (hasChildResult) {
                        filteredChildren.push(child)
                    } else if (filteredGrandchildren.length > 0) {
                        filteredChildren.push({
                            ...child,
                            children: filteredGrandchildren,
                        })
                    }
                })

                if (hasResult) {
                    filteredResults.push(item)
                } else if (filteredChildren.length > 0) {
                    filteredResults.push({
                        ...item,
                        children: filteredChildren,
                    })
                }
            })

            return filteredResults
        }
    }
}
