import React, { useEffect } from 'react'
import { Grid } from '@mui/material'
import {
    setActiveRetailViewFilters,
    setRetailViewFilters,
} from '../../../redux/filters/filtersSlice'
import { useAppDispatch, useAppSelector } from '../../../redux/hooks'
import Filters, {
    FiltersType,
} from '../../../components/features/filters/Filters'
import { Navigate, useParams } from 'react-router-dom'
import { RetailViewFilters } from '../../../redux/filters/filtersTypes'
import PrivatePage from '../../../components/features/GlobalPages/Private/PrivatePage'
import RetailDashboardView from '../Global/views/types/RetailDashboardView'

const ViewsPage = () => {
    const { viewId } = useParams()
    const views = useAppSelector((state) => state.views.views)
    const view = views.find((view) => view._id === viewId)
    const filters = view ? view.filters as RetailViewFilters : null

    const dispatch = useAppDispatch()

    useEffect(() => {
        dispatch(
            setRetailViewFilters({
                startDate: filters.startDate,
                endDate: filters.endDate,
                products: filters.products,
                stores: filters.stores,
                distributionChannels: filters.distributionChannels,
                min: filters.min,
                max: filters.max
            })
        )
        dispatch(
            setActiveRetailViewFilters({
                startDate: filters.startDate,
                endDate: filters.endDate,
                products: filters.products,
                stores: filters.stores,
                distributionChannels: filters.distributionChannels,
                min: filters.min,
                max: filters.max
            })
        )
    }, [filters])


    if (!view) {
        return <Navigate to="/" />
    } else {

        return (
            <PrivatePage title={view.name} period onViewPage>
                <Grid>
                    <Filters
                        filtersType={FiltersType.VIEWSRETAIL}
                        filters={filters}
                    />
                    <RetailDashboardView view={view} />
                </Grid>
            </PrivatePage>
        )
    }
}

export default ViewsPage
