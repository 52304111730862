import React, { useCallback } from 'react'
import {
    Form,
    type FormikBag,
    type FormikProps,
    withFormik,
} from 'formik'
import { Dialog, DialogContent, } from '@mui/material'
import { object } from 'yup'
import { DialogHeader } from '../../../../../../../components/features/Dialog/BasicDialog'
import { PrimaryText } from '../../../../../../../components/ui/Text'
import { BLACK } from '../../../../../../../components/ui/Colors'
import { DialogFooter } from '../../../../../../../components/features/Dialog/BasicFooterDialog'
import { useAppDispatch, useAppSelector } from '../../../../../../../redux/hooks'
import { fetchDeleteCampaign } from '../../../../../../../redux/views/viewsSlice'
import { useNavigate, useParams } from 'react-router-dom'
import { trashFsFilters, trashFsPeriodFilter } from '../../../../../../../redux/filters/filtersSlice'
import { setIsFiltered } from '../../../../../../../redux/global/globalSlice'


interface FormValues {
}

interface OwnProps {
    isDialogOpen: boolean,
    setIsDialogOpen: React.Dispatch<React.SetStateAction<boolean>>,
    values: any,
    closeMenu: React.Dispatch<React.SetStateAction<boolean>>
}

interface FormProps {
    isDialogOpen: boolean
    setIsDialogOpen: any
    onSubmit: (
        values: FormValues,
        bag: FormikBag<FormProps, FormValues>
    ) => void

}


const PureSettingsForm = ({
    isDialogOpen,
    setIsDialogOpen,
    ...props
}: FormikProps<FormValues> & FormProps): JSX.Element => {

    return (
        <Dialog
            open={isDialogOpen}
            fullWidth={true}
            onClose={() => setIsDialogOpen(false)}
            PaperProps={{
                sx: {
                    borderRadius: '1em',
                    minWidth: '55vw',
                    padding: '2vh 2vw 2vh 2vw',
                },
            }}
        >
            <DialogHeader
                title={"Supprimer cette campagne ?"}
                setOpen={() => setIsDialogOpen(false)}
            />
            <Form style={{ width: '100%' }}>
                <DialogContent sx={{ height: '42vh' }}>
                    <PrimaryText color={BLACK} mb="3vh" mt="3vh" bold>Souhaitez-vous vraiment supprimer cette campagne ?</PrimaryText>

                </DialogContent>
                <DialogFooter
                    leftButtonFunc={() => {
                        setIsDialogOpen(false)
                    }
                    }
                    leftButtonText={'ANNULER'}
                    rightButtonFunc={async () => {
                        await props.submitForm()
                    }}
                    rightButtonText={
                        `Supprimer cette campagne`
                    }
                />
            </Form>
        </Dialog>
    )
}

const ControlledSettingsForm = withFormik<FormProps, FormValues>({
    mapPropsToValues: () => ({}),
    validationSchema: () => {
        return object().shape({
        })
    },
    handleSubmit: (
        values: FormValues,
        bag: FormikBag<FormProps, FormValues>
    ) => {
        bag.props.onSubmit(values, bag)
    },
})(PureSettingsForm)

const DeleteCampaignDialog = ({ isDialogOpen, setIsDialogOpen, values, closeMenu }: OwnProps) => {
    const { campaignId } = useParams()
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const env = useAppSelector((state) => state.global.env)
    const _disableMember = useCallback((campaignId: string) => dispatch(fetchDeleteCampaign(campaignId)), [dispatch])
    const _trashFsFilters = useCallback(
        () => dispatch(trashFsFilters()),
        [dispatch]
    )
    const _trashFsPeriodFilter = useCallback(
        () => dispatch(trashFsPeriodFilter()),
        [dispatch]
    )

    const _setIsFilteredFS = useCallback(
        (isFiltered: boolean) => dispatch(setIsFiltered(isFiltered)),
        [dispatch]
    )


    const handleSubmit = () => {
        _disableMember(values._id)
        setIsDialogOpen(false)
        closeMenu(false)
        if (values._id === campaignId) {
            _trashFsFilters()
            _trashFsPeriodFilter()
            _setIsFilteredFS(false)
            navigate(`/${env.type}/${env.name}/promo`)

        }
    }
    return (
        <ControlledSettingsForm
            setIsDialogOpen={setIsDialogOpen}
            isDialogOpen={isDialogOpen}
            onSubmit={handleSubmit}
        />
    )
}

export default DeleteCampaignDialog
