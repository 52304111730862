import { ThemeProvider } from "@emotion/react"
import { DataGrid } from "@mui/x-data-grid"
import React from "react"
import { theme } from "../../DataTableComponent"
import { Box } from "@mui/material"
import { MembersDataGridColumns } from "./utilsAdminPage"
import { Member } from "../../../../redux/companies/companiesTypes"

export const MembersDataGrid = ({ members }: { members: Member[] }) => {

    return (<Box
        sx={{
            height: '100%',
            width: '100%',
            '& .MuiDataGrid-columnHeaders': {
                height: '4vh',
                maxHeight: 'unset !important',
                minHeight: 'unset !important',
            },
            '& .MuiDataGrid-columnHeader': {
                padding: '0 1vw !important',
                height: '100% !important',
            },
            '& .MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnSeparator:last-child':
            {
                display: 'none',
            },
            '& .MuiDataGrid-columnHeaderTitleContainer': {
                justifyContent: 'center !important',
            },
            '& .MuiDataGrid-columnHeaderTitleContainer:first-child': {
                justifyContent: 'center !important',
            },
            '& div.MuiDataGrid-columnHeader:nth-child(1) > div:nth-child(1) > div:nth-child(1)':
            {
                justifyContent: 'flex-start !important',
            },
            '& .MuiDataGrid-cell--textLeft:first-child': {
                justifyContent: 'flex-start !important',
            },
            '& .MuiDataGrid-cell--textLeft': {
                justifyContent: 'center !important',
            },
            '& .MuiTablePagination-root:last-child': {
                fontSize: { xs: '16px', md: '1.7vmin' },
            },
            '& .MuiTablePagination-root .MuiTablePagination-selectLabel': {
                fontSize: { xs: '16px', md: '1.7vmin' },
            },
            '& .MuiTablePagination-displayedRows': {
                fontSize: { xs: '16px', md: '1.7vmin' },
            },
            '& .MuiDataGrid-virtualScroller': {
                marginTop: '4vh !important',
            },
        }}
    >
        <ThemeProvider theme={theme}>
            <DataGrid
                hideFooterSelectedRowCount={true}
                rows={members}
                columns={MembersDataGridColumns()}
                hideFooter={true}
                sx={{
                    height: '100%',
                }}
                getRowId={(row) => row.email}
                getRowHeight={() => 'auto'}
            />
        </ThemeProvider>
    </Box>)
}