import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Grid } from '@mui/material'
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks'
import { View } from '../../../../../redux/views/viewsTypes'
import { fetchFilteredDashboard } from '../../../../../redux/global/globalSlice'
import { ActiveRetailFilters } from '../../../../../redux/filters/filtersTypes'
import { setActiveRetailFilters } from '../../../../../redux/filters/filtersSlice'
import { NoDataScreen } from '../../../../../components/features/screens/NoDataScreen'
import Loading from '../../../../../components/features/screens/LoadingScreen'
import RetailKpis from '../../../Retail/Carrefour/dashboard/RetailKpis'
import Indicator from '../../../Retail/Carrefour/dashboard/indicator/Indicator'
import { HistogramRetail } from '../../../Retail/Carrefour/dashboard/histogram/HistogramRetail'
import RetailSegmentation from '../../../Retail/Carrefour/dashboard/RetailSegmentation'
import RegionTable from '../../../Retail/Carrefour/dashboard/RegionTable'
import { DashboardMap } from '../../../Retail/Carrefour/dashboard/DashboardMap'
import { dashboardLabels } from '../../../../../components/features/foodService/dashboard/histogram/HistogramLabels'
import { Units } from '../../../../../redux/global/globalTypes'

const RetailDashboardView = ({ view }: { view: View }) => {
    const [isFirstLoad, setIsFirstLoad] = useState(true)
    const dispatch = useAppDispatch()
    const { productsChart } = useAppSelector(
        (state) => state.global.retailDashboardData
    )
    const dashboard = useAppSelector(
        (state) => state.global.retailDashboardData
    )
    const { unit } = useAppSelector((state) => state.global)
    const { activeRetailViewFilters } = useAppSelector((state) => state.filters)
    const isFirstUpdate = useRef(true)

    const _fetchRetailDashboard = useCallback(
        async (filters: ActiveRetailFilters) => {
            await dispatch(fetchFilteredDashboard(filters, true))
        },
        [dispatch]
    )

    useEffect(() => {
        if (!isFirstUpdate.current) {
            _fetchRetailDashboard(view.filters as ActiveRetailFilters)
            setIsFirstLoad(false)
            isFirstUpdate.current = false
            setActiveRetailFilters(activeRetailViewFilters)
        }
    }, [view.filters])

    useEffect(() => {
        if (activeRetailViewFilters.startDate && !isFirstUpdate.current) {
            setIsFirstLoad(false)
            _fetchRetailDashboard(activeRetailViewFilters)
        }
        isFirstUpdate.current = false
    }, [activeRetailViewFilters, _fetchRetailDashboard, unit])

    if (isFirstLoad) return <Loading />

    if (dashboard === null || dashboard.products.length === 0)
        return <NoDataScreen />

    return (
        <Grid container direction="column" wrap="nowrap" height="90vh" p="1vw">
            <RetailKpis />
            <Grid
                container
                direction="column"
                wrap="nowrap"
                justifyContent="flex-start"
                pt="1vw"
                sx={{ height: '78vh' }}
            >
                <Grid
                    container
                    direction="column"
                    wrap="nowrap"
                    justifyContent="flex-start"
                    sx={{ height: '42%' }}
                >
                    <Grid container sx={{ height: '100%' }} flexWrap="nowrap">
                        <Grid item flex={1.5} sx={{ height: '100%' }}>
                            <Indicator />
                        </Grid>
                        <Grid item flex={4} pl="1vw">
                            <HistogramRetail unit={Units.uvc} tooltipTitle='Volume' labels={dashboardLabels} title={"Total des ventes par mois (UVC)"} chartData={productsChart} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container wrap="nowrap" pt="1vw" sx={{ height: '58%' }}>
                    <Grid
                        item
                        flex={1.5}
                        sx={{ height: '100%', width: '15vw' }}
                    >
                        <RetailSegmentation />
                    </Grid>
                    <Grid item flex={1.5} pl="1vw" sx={{ height: '100%' }}>
                        <RegionTable />
                    </Grid>
                    <Grid item flex={1.5} pl="1vw" sx={{ height: '100%' }}>
                        <DashboardMap />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default RetailDashboardView
