import React from 'react'
import { routerType } from '../../PublicNavigation'
import { KaryonModules } from '../../../../../../redux/user/userTypes'
import { PagesName } from '../../../types/pages'
import { CustomersPageFFR } from '../../../../../../pages/private/Foodservice/FFR/CustomersPage'
import { WarehousesPageFFR } from '../../../../../../pages/private/Foodservice/FFR/WarehousesPage'
import { ProductsPageFFR } from '../../../../../../pages/private/Foodservice/FFR/ProductsPage'
import { PromoPage } from '../../../../../../pages/private/Foodservice/FFR/PromoPage'
import { CampaignPage } from '../../../../../../pages/private/Foodservice/FFR/CampaignPage'
import { PlaybookPromo } from '../../../../../../pages/private/Foodservice/PlaybookPromo'
import { ViewPage } from '../../../../views/ViewPage'
import DashboardPage from '../../../../../../pages/private/Foodservice/Pages/Dashboard/DashboardPage'

export const FranceFraisRouter = (modules: KaryonModules[], companyModules: KaryonModules[]): routerType[] => [
    {
        path: 'dashboard/',
        element: <DashboardPage />,
        title: PagesName.DASHBOARD,
    },
    {
        path: 'clients/',
        element: <CustomersPageFFR />,
        title: PagesName.CLIENTS,
    },
    {
        path: 'depots/',
        element: <WarehousesPageFFR />,
        title: PagesName.WAREHOUSES,
    },
    {
        path: 'produits/',
        element: <ProductsPageFFR />,
        title: PagesName.PRODUCTS,
    },
    {
        path: 'vues/:viewId/',
        element: <ViewPage />,
        title: PagesName.VIEWS,
    },
    ...(modules.includes(KaryonModules.PROMO)
        ? [
            {
                path: 'promo/',
                element: <PromoPage />,
                title: PagesName.PROMO,
            },
            {
                path: 'promo/:campaignId',
                element: <CampaignPage />,
                title: PagesName.CAMPAIGN,
            },

        ]
        : companyModules.includes(KaryonModules.PROMO) ?
            [] :
            [{
                path: 'promoDemo/',
                element: <PlaybookPromo />,
                title: PagesName.PROMODEMO,
            },]),
]

