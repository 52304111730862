import { useState } from "react"
import React from "react"
import { List, ListItem, ListItemButton, ListItemIcon } from "@mui/material"
import { BLACK } from "../../../../../../../components/ui/Colors"
import { PrimaryText } from "../../../../../../../components/ui/Text"
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import DeleteCampaignDialog from "./DeleteCampaignDialog"

interface ItemsModifyMenu {
    id: number
    label: string
    icon: JSX.Element
    customAction?: any
}


export const ModifyCampaignMenu = ({ values, closeMenu }: { values: any, closeMenu: React.Dispatch<React.SetStateAction<boolean>> }) => {
    const [openDelete, setOpenDelete] = useState(false)

    const customActionDelete = () => {
        if (!openDelete) {
            setOpenDelete(() => true)
        }
    }

    const itemsMenu: ItemsModifyMenu[] = [
        {
            id: 0,
            label: "Supprimer",
            icon: <DeleteForeverIcon />,
            customAction: () => customActionDelete(),
        },
    ]


    return (
        <List sx={{
            padding: 0,
            width: 'fill-available',
        }}>
            {itemsMenu.map((item) => {
                return (
                    <ListItem >
                        <ListItemButton sx={{ padding: '0.8vh 0.6vw' }}
                            onClick={(e) => {
                                e.preventDefault()
                                e.stopPropagation()
                                if (item.customAction) {
                                    item.customAction()
                                }
                            }}
                        >
                            <ListItemIcon
                                sx={{
                                    color: BLACK,
                                    minWidth: { xs: '40px', md: '3vw' },
                                    '& svg': {
                                        width: { xs: '22px', md: '2.5vw' },
                                        height: { xs: '22px', md: '2.5vh' },
                                    },
                                }}

                            >
                                {item.icon}
                            </ListItemIcon>
                            <PrimaryText color={BLACK} noWrap style={{ cursor: "pointer" }}>
                                {item.label}
                            </PrimaryText>
                        </ListItemButton>
                    </ListItem>)
            })}

            {openDelete && <DeleteCampaignDialog isDialogOpen={openDelete} setIsDialogOpen={setOpenDelete} values={values} closeMenu={closeMenu} />}
        </List>
    )
}