import React from 'react'
import ChartBlock from '../../../../../../components/features/ChartBlock'
import { useAppSelector } from '../../../../../../redux/hooks'
import { type RetailProduct } from '../../../../../../redux/user/userTypes'
import { Units } from '../../../../../../redux/global/globalTypes'
import { InformationsListConfig, MultipleCards, UniqueCard } from '../../../../Foodservice/Pages/Dashboard/FsLineChart'

const getInformationsListConfig = (data: RetailProduct, isTop: boolean): InformationsListConfig[] => {
    return [
        {
            title: "Croissance en %",
            data: Math.round(data.evolution),
            unit: Units.percent,
            tooltip: isTop
                ? 'Taux de croissance correspondant à votre TOP référence.'
                : 'Taux de croissance correspondant à votre FLOP référence.'
        },
        {
            title: "VMM",
            data: Math.round(data.vmm),
            unit: Units.uvc,
            tooltip: "Volume Mensuel Moyen vendu de cette référence."
        },
        {
            title: "Points de vente",
            data: Math.round(data.stores),
            unit: Units.stores,
            tooltip: "Nombre de points de vente vendeurs de cette référence sur la période A."
        }
    ]
}

const Indicator = () => {
    const { products } = useAppSelector(
        (state) => state.global.retailDashboardData
    )
    const { env } = useAppSelector((state) => state.global)
    const link = `/retail/${env.name}/produits`

    const tooltipTop = 'Croissance maximale en UVC correspondant à votre TOP référence par rapport à A-1.'
    const tooltipFlop = 'Recul maximal en UVC correspondant à votre FLOP référence par rapport à A-1.'

    return (
        <ChartBlock title="TOP/FLOP Références (UVC)" link={link}>
            {products.length > 1 ? (
                <MultipleCards
                    top={products[0]}
                    flop={products[products.length - 1]}
                    informationsListConfigTop={getInformationsListConfig(products[0], true)}
                    informationsListConfigFlop={getInformationsListConfig(products[products.length - 1], false)}
                    unit={Units.uvc}
                    tooltipTop={tooltipTop}
                    tooltipFlop={tooltipFlop}
                />
            ) : (
                <UniqueCard data={products[0]} informationsListConfig={getInformationsListConfig(products[0], true)}
                    unit={Units.uvc} tooltipTop={tooltipTop} />
            )}
        </ChartBlock>
    )
}

export default Indicator
