import React, { useEffect, useCallback, useState } from 'react'
import { Grid, Tooltip } from '@mui/material'
import {
    type GridColDef,
    type GridColumnHeaderParams,
    type GridRenderCellParams,
} from '@mui/x-data-grid'
import ChartBlock from '../../../../../components/features/ChartBlock'
import { DataTableComponent } from '../../../DataTableComponent'
import { PrimaryText, SecondaryText } from '../../../../../components/ui/Text'
import { BLACK } from '../../../../../components/ui/Colors'
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks'
import { type ActiveRetailFilters } from '../../../../../redux/filters/filtersTypes'
import Loading from '../../../../../components/features/screens/LoadingScreen'
import WaitingScreen from '../../../../../components/features/screens/WaitingScreen'
import {
    fetchFilteredretailStores,
    setStoresDrillDownStores,
} from '../../../../../redux/retailProducts/retailProductsSlice'
import { NoDataScreen } from '../../../../../components/features/screens/NoDataScreen'
import Tendency, { TooltipMessage } from '../../../../../components/ui/Tendency'
import { Units } from '../../../../../redux/global/globalTypes'
import ShowChartIcon from '@mui/icons-material/ShowChart'
import { DrillDownType } from '../../../../../components/features/drillDowns/ProductsDrillDownContent'
import DrillDown from '../../../../../components/features/drillDowns/DrillDown'
import { ColumnHeaderTextTooltip } from '../../../../../components/features/drillDowns/setupDrillDown'
import { HistogramRetail } from '../dashboard/histogram/HistogramRetail'
import { storesPageLabels } from '../../../../../components/features/foodService/dashboard/histogram/HistogramLabels'

const columns: GridColDef[] = [
    {
        field: 'storeName',
        headerName: 'Points de vente',
        flex: 4,
        editable: false,
        renderHeader: (params: GridColumnHeaderParams) => {
            return (
                <ColumnHeaderTextTooltip text={params.colDef.headerName} />
            )
        },
        renderCell: (params: GridRenderCellParams) => {
            return <SecondaryText color={BLACK}>{params.value}</SecondaryText>
        },
    },
    {
        field: 'distributionChannel',
        headerName: 'Circuits de distribution',
        flex: 1,
        editable: false,
        renderHeader: (params: GridColumnHeaderParams) => {
            return (
                <ColumnHeaderTextTooltip tooltipIsNeeded text={params.colDef.headerName} />
            )
        },
        renderCell: (params: GridRenderCellParams) => {
            return (
                <SecondaryText color={BLACK}>
                    {params.value === 'Non renseigné' ? '-' : params.value}
                </SecondaryText>
            )
        },
    },
    {
        field: 'region',
        headerName: 'Régions',
        flex: 1,
        editable: false,
        renderHeader: (params: GridColumnHeaderParams) => {
            return (
                <ColumnHeaderTextTooltip text={params.colDef.headerName} />
            )
        },
        renderCell: (params: GridRenderCellParams) => {
            return (
                <SecondaryText color={BLACK}>
                    {params.value === 'Non renseigné' ? '-' : params.value}
                </SecondaryText>
            )
        },
    },
    {
        field: 'references',
        headerName: 'Références',
        flex: 1,
        editable: false,
        renderHeader: (params: GridColumnHeaderParams) => {
            return (
                <ColumnHeaderTextTooltip tooltipIsNeeded text={params.colDef.headerName} />
            )
        },
        renderCell: (params: GridRenderCellParams) => {
            return <SecondaryText color={BLACK}>{params.value}</SecondaryText>
        },
    },
    {
        field: 'differenceReferences',
        flex: 1,
        editable: false,
        renderHeader: () => {
            return (
                <Grid
                    justifyContent="center"
                    alignItems="center"
                    direction="column"
                    container
                >
                    <ShowChartIcon fontSize="small" />
                </Grid>
            )
        },
        renderCell: (params: GridRenderCellParams) => {
            return (
                <Tendency
                    difference={params.row.differenceReferences}
                    unit={Units.none}
                    tooltip={TooltipMessage.VMM}
                />
            )
        },
    },
    {
        field: 'values',
        headerName: 'Volume (UVC)',
        flex: 1,
        editable: false,
        renderHeader: (params: GridColumnHeaderParams) => {
            return (
                <ColumnHeaderTextTooltip tooltipIsNeeded text={params.colDef.headerName} />
            )
        },
        renderCell: (params: GridRenderCellParams) => {
            return <SecondaryText color={BLACK}>{params.value}</SecondaryText>
        },
    },
    {
        field: 'difference',
        flex: 1,
        editable: false,
        renderHeader: () => {
            return <ShowChartIcon fontSize="small" />
        },
        renderCell: (params: GridRenderCellParams) => {
            return (
                <Tendency
                    difference={params.row.difference}
                    unit={Units.uvc}
                    tooltip={TooltipMessage.VOLUME}
                />
            )
        },
    },
    {
        field: 'oldValues',
        headerName: 'Volume A-1 (UVC)',
        flex: 1,
        editable: false,
        renderHeader: (params: GridColumnHeaderParams) => {
            return (
                <ColumnHeaderTextTooltip tooltipIsNeeded text={params.colDef.headerName} />
            )
        },
        renderCell: (params: GridRenderCellParams) => {
            return <SecondaryText color={BLACK}>{params.value}</SecondaryText>
        },
    },
    {
        field: 'VMM',
        headerName: 'VMM (UVC)',
        flex: 1,
        editable: false,
        renderHeader: (params: GridColumnHeaderParams) => {
            return (
                <ColumnHeaderTextTooltip tooltipIsNeeded text={params.colDef.headerName} />
            )
        },
        renderCell: (params: GridRenderCellParams) => {
            return (
                <SecondaryText color={BLACK}>
                    {Math.round(params.value)}
                </SecondaryText>
            )
        },
    },
    {
        field: 'differenceVMM',
        flex: 1,
        editable: false,
        renderHeader: () => {
            return <ShowChartIcon fontSize="small" />
        },
        renderCell: (params: GridRenderCellParams) => {
            return (
                <Tendency
                    difference={params.row.differenceVMM}
                    unit={Units.uvc}
                    tooltip={TooltipMessage.VMM}
                />
            )
        },
    },
    {
        field: 'oldVMM',
        headerName: 'VMM A-1 (UVC)',
        flex: 1,
        editable: false,
        renderHeader: (params: GridColumnHeaderParams) => {
            return (
                <ColumnHeaderTextTooltip tooltipIsNeeded text={params.colDef.headerName} />
            )
        },
        renderCell: (params: GridRenderCellParams) => {
            return (
                <SecondaryText color={BLACK}>
                    {Math.round(params.value)}
                </SecondaryText>
            )
        },
    },
]

const CarrefourStoresPage = () => {
    const dispatch = useAppDispatch()
    const { brand } = useAppSelector((state) => state.user.user)
    const { isLoading, env } = useAppSelector((state) => state.global)
    const { stores } = useAppSelector((state) => state.retailProducts)
    const { activeRetailFilters } = useAppSelector((state) => state.filters)
    const _fetchStores = useCallback(
        async (filters: ActiveRetailFilters) => {
            await dispatch(fetchFilteredretailStores(filters))
        },
        [dispatch]
    )
    const [fieldSelected, setFieldSelected] = useState<any>()
    const [open, setOpen] = useState(false)
    const [value, setValue] = useState(0)

    const _setStoresDrillDownStores = useCallback(() => {
        dispatch(setStoresDrillDownStores(null))
    }, [dispatch])

    const handleClose = () => {
        setOpen(false)
        _setStoresDrillDownStores()
    }

    useEffect(() => {
        if (brand) {
            _fetchStores(activeRetailFilters)
        }
    }, [brand, activeRetailFilters, _fetchStores])

    if (isLoading || !stores) {
        return <Loading />
    }

    if (env.status === 'onload-init') {
        return <WaitingScreen />
    }

    if (stores.list.length === 0) {
        return <NoDataScreen />
    }

    return (
        <Grid
            container
            direction="column"
            sx={{
                height: '90vh',
                '& .MuiDataGrid-row:hover #tendency': {
                    visibility: 'visible',
                },
            }}
            p="1vw"
            flexWrap="wrap"
        >
            <Grid flex={2} sx={{ height: '100%' }}>
                <Grid height={"40%"}>
                    <HistogramRetail hasLink unit={Units.pdv} tooltipTitle='PDV' title="Total des points de vente par mois (PDV)" labels={storesPageLabels} chartData={stores.storesChart} />
                </Grid>
                <ChartBlock
                    title={`Liste des points de vente (${stores.list.length})`}
                >
                    <Grid height="100%">
                        <DrillDown
                            type={DrillDownType.STORES}
                            open={open}
                            title={
                                fieldSelected &&
                                `${Object.values(fieldSelected)[0]}`
                            }
                            value={value}
                            setValue={setValue}
                            row={fieldSelected}
                            handleClose={handleClose}
                        />
                        <DataTableComponent
                            type={DrillDownType.STORES}
                            columns={columns}
                            setFieldSelected={setFieldSelected}
                            rows={stores.list}
                            getRowId={(row) => row.storeName}
                            setOpen={setOpen}
                            setValue={setValue}
                        />
                    </Grid>
                </ChartBlock>
            </Grid>
        </Grid>
    )
}

export default CarrefourStoresPage
