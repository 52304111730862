import React, { useState } from 'react'
import { DrillDownType } from '../../../../../../components/features/drillDowns/ProductsDrillDownContent'
import { DataTableComponent } from '../../../../DataTableComponent'
import {
    GridCellParams,
    GridColDef,
    GridColumnHeaderParams,
    GridRenderCellParams,
} from '@mui/x-data-grid'
import {
    PrimaryText,
    SecondaryText,
} from '../../../../../../components/ui/Text'
import { BLACK } from '../../../../../../components/ui/Colors'
import NumberWithSpace from '../../../../../../components/ui/NumberWithSpace'
import { Grid } from '@mui/material'
import DrillDown from '../../../../../../components/features/drillDowns/DrillDown'
import { roundIfNotZero } from '../../../../../../helpers/roundIfNotZero'
import { PromoWarehouse, UnityType } from '../../../../../../redux/promotions/promotionsTypes'
import { useAppSelector } from '../../../../../../redux/hooks'
import { EnvName } from '../../../../../../redux/user/userTypes'

export const columnsWarehousesListPromoPage = (unity: UnityType): GridColDef[] => {
    return [
        {
            field: 'name',
            headerName: 'Dépôts',
            flex: 2,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <PrimaryText color={BLACK}>
                        {params.colDef.headerName}
                    </PrimaryText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>{params.value}</SecondaryText>
                )
            },
        },
        {
            field: 'clients',
            headerName: unity === UnityType.CA ? "Transactions" : 'Clients',
            flex: 1,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <PrimaryText color={BLACK}>
                        {params.colDef.headerName}
                    </PrimaryText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
        ...(unity === UnityType.KG ? [{
            field: 'products',
            headerName: 'Références',
            flex: 1,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <PrimaryText color={BLACK}>
                        {params.colDef.headerName}
                    </PrimaryText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        }] : []),
        {
            field: 'volume',
            headerName: unity === UnityType.CA ? "Part de CA (%)" : `Sell-out (${UnityType.KG})`,
            flex: 1,
            editable: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <PrimaryText color={BLACK}>
                        {params.colDef.headerName}
                    </PrimaryText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        />
                    </SecondaryText>
                )
            },
        },
        ...(unity === UnityType.KG ? [{
            field: 'incremental',
            headerName: `Incrémental sell-out`,
            flex: 1,
            editable: false,
            hide: false,
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <PrimaryText color={BLACK}>
                        {params.colDef.headerName}
                    </PrimaryText>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <SecondaryText color={BLACK}>
                        {params.value > 0 ? <NumberWithSpace
                            number={roundIfNotZero(params.value)}
                        /> : 0}
                    </SecondaryText>
                )
            },
        }] : []),

    ]
}

export const WarehousesList = ({
    warehouses,
    drillDownType,
}: {
    warehouses: PromoWarehouse[]
    drillDownType: DrillDownType
}) => {
    const unity = warehouses[0].unity
    const [isOpen, setOpen] = useState(false)
    const [fieldSelected, setFieldSelected] = useState<GridCellParams<any>>()
    const [value, setValue] = useState(0)
    const { env } = useAppSelector((state) => state.global)
    const isTGM = env.name === EnvName.TRANSGOURMET


    const handleClose = () => {
        setOpen(false)
    }

    return (
        <Grid
            item
            mt="1vw"
            sx={{
                height: '40vh',
            }}
        >
            <DataTableComponent
                type={drillDownType}
                setOpen={setOpen}
                setFieldSelected={setFieldSelected}
                setValue={setValue}
                rows={warehouses}
                columns={columnsWarehousesListPromoPage(unity)}
                getRowId={(row) => row.id}
                hideFooter={true}
            />
            {!isTGM && <DrillDown
                type={drillDownType}
                open={isOpen}
                title={fieldSelected && `${fieldSelected.row.name}`}
                value={value}
                setValue={setValue}
                row={fieldSelected}
                handleClose={handleClose}
            />}
        </Grid>
    )
}
