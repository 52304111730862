import React from "react"
import { useAppSelector } from "../../../../../redux/hooks"
import PrivatePage from "../../../../../components/features/GlobalPages/Private/PrivatePage"
import Filters, { FiltersType } from "../../../../../components/features/filters/Filters"
import { DashboardLayout } from "../../layout/DashboardLayout"


const DashboardPage = () => {
    const { activeFsFilters } = useAppSelector((state) => state.filters)

    return (
        <PrivatePage
            title="Tableau de bord"
            period
        >
            <Filters
                filtersType={FiltersType.FS}
                filters={activeFsFilters}
            />
            <DashboardLayout />
        </PrivatePage>

    )
}

export default DashboardPage
