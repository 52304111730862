import { FsCrossViewFilters, FsViewFilters, RetailViewFilters } from '../filters/filtersTypes'
import { UnityType } from '../promotions/promotionsTypes'
import { EnvType } from '../user/userTypes'

export enum ViewsSteps {
    FsSettings = 'food-service-settings',
    FsCrossSettings = 'food-service-cross-settings',
    RetailSettings = 'retail-settings',
    FsFilters = 'food-service-filters',
    FsCrossFilters = 'food-service-cross-filters',
    RetailFilters = 'retail-filters',
    CampaignFS = "Campaign-food-service",
    CampaignFSCrossFilters = "Campaign-food-service-cross-filters",
}

export enum ViewTypes {
    Dashboard = 'dashboard',
    Map = 'map',
    ListProduct = 'list-product',
    ListShop = 'list-shop',
}

export enum ViewCreationMode {
    GLOBAL = 'global',
    NOTIFICATION = 'notification',
    CAMPAIGN = 'campaign'
}

export interface ViewData {
    name: string
    type: ViewTypes
    environment: EnvType
    distributor: string
    filters: FsViewFilters | RetailViewFilters | FsCrossViewFilters
    creationMode: ViewCreationMode
    notificationId?: string
}


export interface ViewDataUpdate {
    name: string
    filters: FsViewFilters | RetailViewFilters
}

export interface ViewConfig {
    steps: ViewsSteps[]
}

export interface View {
    _id: string
    name: string
    environment: EnvType
    distributor: string
    type: ViewTypes
    createConfig: ViewsCreation
    filters: FsViewFilters | RetailViewFilters | FsCrossViewFilters
    creationMode: ViewCreationMode
    notificationId?: string
    description?: string
}

interface ViewsCreation {
    creator: string;
    created_at: Date;
    shared?: boolean;
    usersToShare?: string[];
    creatorFirstname?: string;
    creatorLastname?: string;
    creatorEmail?: string;
}

export interface ResponseViewsCreation {
    insertedId: string
    distributor: string
    environment: EnvType
}

export interface viewsState {
    views: View[]
    campaigns: { data: Campaign[], unityType: UnityType }
    openAdd: boolean
    openUpdate: boolean
    openAddCampaign: boolean
    config: ViewConfig
    data: ViewData
    dataUpdate: ViewDataUpdate
    usersToShare: string[]
    description: string
    dataCampaign: CampaignData
    configCampaign: ViewConfig
}

export interface Campaign {
    _id: string
    name: string
    environment: EnvType
    distributor: string
    budget: number
    createConfig: ViewsCreation
    filters: FsViewFilters
    creationMode: ViewCreationMode
    data: {
        stockage: number
        incremental: number
        evolUF: number
        cost?: number
    }
}
export interface CampaignData {
    name: string
    type: ViewTypes
    budget: number
    environment: EnvType
    distributor: string
    filters: FsViewFilters
    creationMode: ViewCreationMode

}
export interface StepConfig {
    handleNext: () => void
    handlePrevious: () => void
    handleReset: () => void
    handleSubmit?: () => void
    handleClose?: () => void
    stepsNumber: number
}
