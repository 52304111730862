import React from 'react'
import { routerType } from '../../PublicNavigation'
import { PagesName } from '../../../types/pages'
import { DashboardRetailPage } from '../../../../../../pages/private/Retail/DashboardRetailPage'
import { ProductsPage } from '../../../../../../pages/private/Retail/ProductsPage'
import { StoresPage } from '../../../../../../pages/private/Retail/StoresPage'
import ViewsPage from '../../../../../../pages/private/views/ViewsPage'

export const RetailGeneralRouter = (): routerType[] => [
        {
                path: '/dashboard',
                element: <DashboardRetailPage />,
                title: PagesName.DASHBOARD,
        },
        {
                path: '/produits',
                element: <ProductsPage />,
                title: PagesName.PRODUCTS,
        },
        {
                path: '/magasins',
                element: <StoresPage />,
                title: PagesName.STORES,
        },
        {
                path: '/vues/:viewId',
                element: <ViewsPage />,
                title: PagesName.VIEWS,
        },
]

