import { Grid } from '@mui/material'
import React, { useCallback, useEffect, useRef } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks'
import { ActiveFsFilters } from '../../../../redux/filters/filtersTypes'
import { fetchAssortmentPromo, fetchCostPromo, fetchImpactPromo, fetchPerformancePromo, fetchPromoKPIs, fetchWarehousesPromo } from '../../../../redux/promotions/promotionsSlice'

export const CampaignLayout = ({ children, filters }: { children: JSX.Element, filters: ActiveFsFilters }) => {
    const dispatch = useAppDispatch()
    const { activeFsFilters } = useAppSelector((state) => state.filters)
    const isFirstUpdate = useRef(true)

    const _fetchPromo = useCallback(
        (filters: ActiveFsFilters) => {
            dispatch(fetchPromoKPIs(filters))
            dispatch(fetchCostPromo(filters))
            dispatch(fetchPerformancePromo(filters))
            dispatch(fetchImpactPromo(filters))
            dispatch(fetchAssortmentPromo(filters))
            dispatch(fetchWarehousesPromo(filters))
        },
        [dispatch]
    )

    useEffect(() => {
        if (filters.startDate && isFirstUpdate.current) {
            _fetchPromo(filters)
            isFirstUpdate.current = false
        } else {
            _fetchPromo(activeFsFilters)
        }
    }, [activeFsFilters])


    return <Grid>{children}</Grid>
}
