import React, { useCallback, useEffect, useState } from 'react'
import {
    CustomTabPanel,
    DataTableDrillDown,
} from '../../../../../../components/features/drillDowns/othersDrillDownComponents'
import Loading from '../../../../../../components/features/screens/LoadingScreen'
import { ActiveFsFilters } from '../../../../../../redux/filters/filtersTypes'
import { useAppDispatch, useAppSelector } from '../../../../../../redux/hooks'
import { Grid } from '@mui/material'
import { DataCardDrillDownPromoClients } from './DataCardDrillDownPromoClients'
import {
    getClientsPromoColumns,
    getFSRows,
} from '../../../../../../components/features/drillDowns/setupDrillDown'
import { PrimaryText } from '../../../../../../components/ui/Text'
import { BLACK } from '../../../../../../components/ui/Colors'
import { fetchDrillDownPromoClients } from '../../../../../../redux/promotions/promotionsSlice'

export const PromoClientsTab = ({
    value,
    isFirstVisit,
    row,
}: {
    value: number
    isFirstVisit: boolean
    row: any
}) => {
    const dispatch = useAppDispatch()
    const { activeFsFilters } = useAppSelector((state) => state.filters)
    const [isLoading, setLoading] = useState(true)
    const _fetchDrillDownPromo = useCallback(
        async (filters: ActiveFsFilters) => {
            await dispatch(fetchDrillDownPromoClients(filters))
        },
        [dispatch]
    )

    const filters: ActiveFsFilters = {
        startDate: activeFsFilters.startDate,
        endDate: activeFsFilters.endDate,
        clients: activeFsFilters.clients,
        products: [row.id],
        regions: activeFsFilters.regions,
        categories: activeFsFilters.categories,
        min: activeFsFilters.min,
        max: activeFsFilters.max,
    }

    useEffect(() => {
        const fetchData = async () => {
            await _fetchDrillDownPromo(filters)
        }

        fetchData().then(() => setLoading(false))
    }, [])
    const { drillDownPromoClients } = useAppSelector(
        (state) => state.fsProducts
    )
    return (
        <CustomTabPanel value={value} index={1}>
            {isLoading && <Loading text={false} height="54vh" />}
            {drillDownPromoClients && !isLoading && (
                <Grid container sx={{ flexDirection: 'column' }}>
                    <Grid item sx={{ height: '20vh', mb: '4vh' }}>
                        <DataTableDrillDown
                            isFirstVisit={isFirstVisit}
                            rows={getFSRows(drillDownPromoClients.list)}
                            columns={getClientsPromoColumns()}
                            message={''}
                            gridHeight="100%"
                            hideFooter={true}
                        />
                    </Grid>
                    <Grid item sx={{ p: '2vh' }} flexDirection="column">
                        <PrimaryText color={BLACK}>Détails</PrimaryText>
                        <DataCardDrillDownPromoClients
                            top3Warehouses={
                                drillDownPromoClients.top3Warehouses
                            }
                            topCategory={
                                drillDownPromoClients.topCategoryPerSubSubType
                            }
                        />
                    </Grid>
                </Grid>
            )}
        </CustomTabPanel>
    )
}
