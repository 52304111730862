import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import MenuIcon from '@mui/icons-material/Menu'
import LogoutMobile from '../LogoutMobile'
import Navbar from '../Navbar'
import Logo from '../../../../assets/Logo-KaryonFood-Normal.png'
import { BLACK, WHITE } from '../../../ui/Colors'
import { Link, useLocation } from 'react-router-dom'
import {
    Button,
    Drawer,
    Grid,
    ListItemIcon,
} from '@mui/material'
import ViewsNavigation from '../ViewsNavigation'
import EnvNavigation from '../EnvNavigation'
import UserNavigation from '../UserNavigation'
import { useAppSelector } from '../../../../redux/hooks'
import PreferencesNavigation from '../../../../pages/private/PreferencesNavigation'
import { EnvName, EnvType, UsersRole } from '../../../../redux/user/userTypes'
import { PrimaryText } from '../../../ui/Text'
import exportIcon from '../../../../assets/icon/exportIcon.svg'
import { ExportDialog } from '../../export/ExportDialog'

export const NAVBAR_WIDTH = 12
export const NAVBAR_PADDING = 1
const LOGO_LINK = '/'

export const MobileSidebar = () => {
    const [isOpen, setOpen] = useState<boolean>(false)

    return (
        <Grid
            container
            justifyContent="center"
            sx={{ background: WHITE, padding: '16px' }}
        >
            <Button
                onClick={() => {
                    setOpen(true)
                }}
                sx={{ position: 'absolute', left: '16px', height: '43px' }}
            >
                <MenuIcon sx={{ color: BLACK }} />
            </Button>
            <Box>
                <img style={{ width: '200px' }} src={Logo} alt="Logo Karyon" />
            </Box>
            <Drawer
                anchor="left"
                open={isOpen}
                onClose={() => {
                    setOpen(false)
                }}
            >
                <Box sx={{ padding: `${NAVBAR_PADDING}vw` }}>
                    <Navbar />
                    <LogoutMobile />
                </Box>
            </Drawer>
        </Grid>
    )
}

export const Sidebar = () => {
    const [isDialogOpen, setDialogOpen] = useState<boolean>(false)
    const { env } = useAppSelector((state) => state.global)
    const { role } = useAppSelector((state) => state.user.user)
    const { state } = useLocation()
    const isSuperAdmin = role === UsersRole.SUPER_ADMIN && env.type === EnvType.SUPER_ADMIN

    useEffect(() => {
        if (state && (state as any).exportModaleOpen) {
            setDialogOpen(true)
        }
    }, [state])

    const hasExport = env.name === EnvName.FRANCE_FRAIS

    return (
        <Box
            sx={{
                width: `${NAVBAR_WIDTH}vw`,
                height: '100vh',
                boxShadow: '2px 0 6px -0px rgba(0, 0, 0, 0.1)',
                background: WHITE,
                padding: `0 ${NAVBAR_PADDING}vw`,
                position: 'fixed',
            }}
        >
            <Box mb="4vh" pt="2vh" textAlign="center">
                <Link to={LOGO_LINK}>
                    <img
                        style={{ width: '12vw' }}
                        src={Logo}
                        alt="Logo Karyon"
                    />
                </Link>
            </Box>
            {!isSuperAdmin && <EnvNavigation />}
            <Grid
                container
                direction="column"
                justifyContent="space-between"
                sx={{ height: `calc(100% - ${isSuperAdmin ? "16.5vh" : "27.5vh"})` }}
            >
                <Navbar />
                {hasExport && (
                    <Button
                        sx={{
                            justifyContent: 'flex-start',
                            p: '0.8vh 1vw',
                        }}
                        onClick={() => setDialogOpen(true)}
                    >
                        <ListItemIcon
                            sx={{
                                minWidth: { xs: '40px', md: '3vw' },
                            }}
                        >
                            <img
                                style={{
                                    width: '2.5vw',
                                    height: '2.5vh',
                                }}
                                src={exportIcon}
                            />
                        </ListItemIcon>
                        <PrimaryText
                            color={BLACK}
                            noWrap
                            textTransform="capitalize"
                        >
                            Export
                        </PrimaryText>
                    </Button>
                )}
                {[EnvType.SETTING].includes(env.type) ? (
                    <PreferencesNavigation />
                ) : !isSuperAdmin && (
                    <ViewsNavigation />
                )}
                <UserNavigation />
            </Grid>
            <ExportDialog
                isOpen={isDialogOpen}
                setOpen={setDialogOpen}
                env={env}
            />
        </Box>
    )
}
