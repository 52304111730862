import React from 'react'
import { routerType } from '../../PublicNavigation'
import { KaryonModules } from '../../../../../../redux/user/userTypes'
import { PagesName } from '../../../types/pages'
import DashboardPage from '../../../../../../pages/private/Foodservice/Pages/Dashboard/DashboardPage'
import { ProductsPageGBH } from '../../../../../../pages/private/Foodservice/GBH/ProductsPage'
import { SuppliersPageGBH } from '../../../../../../pages/private/Foodservice/GBH/SuppliersPage'
import { WarehousesPageGBH } from '../../../../../../pages/private/Foodservice/GBH/WarehousesPage'

export const GBHRouter = (modules: KaryonModules[]): routerType[] => [
    {
        path: 'dashboard/',
        element: <DashboardPage />,
        title: PagesName.DASHBOARD,
    },
    {
        path: 'pointsdevente/',
        element: <WarehousesPageGBH />,
        title: PagesName.WAREHOUSES,
    },
    {
        path: 'fournisseurs/',
        element: <SuppliersPageGBH />,
        title: PagesName.SUPPLIERS,
    },
    {
        path: 'références/',
        element: <ProductsPageGBH />,
        title: PagesName.PRODUCTS,
    },
]
