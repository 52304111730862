import React, { useState } from "react"
import { List, ListItem, ListItemButton, ListItemIcon } from "@mui/material"
import { BLACK } from "../../../../components/ui/Colors";
import { PrimaryText } from "../../../../components/ui/Text";
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined'
import PermIdentityIcon from '@mui/icons-material/PermIdentity'
import BusinessIcon from '@mui/icons-material/Business';
import UpdateEnvContent from "./UpdateEnvDialog";
import UpdateRoleDialog from "./updateRoleDialog";
import UpdateStatusDialog from "./updateStatusDialog";
import { Member } from "../../../../redux/companies/companiesTypes";

interface ItemsModifyUserMenu {
    id: number
    label: string
    icon: JSX.Element
    customAction?: any
}



export const ModifyUserMenu = ({ values, userStatusDeactivated, closeMenu }: { values: Member, userStatusDeactivated: boolean, closeMenu: React.Dispatch<React.SetStateAction<boolean>> }) => {
    const [openEnv, setOpenEnv] = useState(false)
    const [openRole, setOpenRole] = useState(false)
    const [openStatus, setOpenStatus] = useState(false)

    const customActionEnv = () => {
        if (!openEnv) {
            setOpenEnv(() => true)
        }
    }

    const customActionRole = () => {
        if (!openRole) {
            setOpenRole(() => true)
        }
    }

    const customActionStatus = () => {
        if (!openStatus) {
            setOpenStatus(() => true)
        }
    }


    const itemsModifyUserMenu: ItemsModifyUserMenu[] = [
        {
            id: 0,
            label: 'Rôle',
            icon: <PermIdentityIcon />,
            customAction: () => customActionRole()
        },
        {
            id: 1,
            label: 'Environnements & modules',
            icon: <BusinessIcon />,
            customAction: () => customActionEnv(),

        },
        {
            id: 2,
            label: "Désactiver",
            icon: <LogoutOutlinedIcon />,
            customAction: () => customActionStatus(),
        },
    ]


    return (
        <List sx={{
            padding: 0,
            width: 'fill-available',
        }}>
            {itemsModifyUserMenu.map((item) => {
                return (
                    <ListItem >
                        <ListItemButton sx={{ padding: '0.8vh 0.6vw' }}
                            onClick={(e) => {
                                e.preventDefault()
                                e.stopPropagation()
                                if (item.customAction) {
                                    item.customAction()
                                }
                            }}
                            disabled={userStatusDeactivated}
                        >
                            <ListItemIcon
                                sx={{
                                    color: BLACK,
                                    minWidth: { xs: '40px', md: '3vw' },
                                    '& svg': {
                                        width: { xs: '22px', md: '2.5vw' },
                                        height: { xs: '22px', md: '2.5vh' },
                                    },
                                }}

                            >
                                {item.icon}
                            </ListItemIcon>
                            <PrimaryText color={BLACK} noWrap style={{ cursor: "pointer" }}>
                                {item.label}
                            </PrimaryText>
                        </ListItemButton>
                    </ListItem>)
            })}
            {openEnv && <UpdateEnvContent isDialogOpen={openEnv} setIsDialogOpen={setOpenEnv} values={values} closeMenu={closeMenu} />}
            {openRole && <UpdateRoleDialog isDialogOpen={openRole} setIsDialogOpen={setOpenRole} values={values} closeMenu={closeMenu} />}
            {openStatus && <UpdateStatusDialog isDialogOpen={openStatus} setIsDialogOpen={setOpenStatus} values={values} closeMenu={closeMenu} />}
        </List>
    )
}