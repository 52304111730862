import React from 'react'
import { routerType } from '../../PublicNavigation'
import { KaryonModules } from '../../../../../../redux/user/userTypes'
import { PagesName } from '../../../types/pages'
import { FranceBoissons } from '../../../../../../pages/private/Foodservice/France Boisson/FranceBoissonMaquettes'


export const FranceBoissonsRouter = (modules: KaryonModules[]): routerType[] => [
    {
        path: 'dashboard/',
        element: <FranceBoissons />,
        title: PagesName.DASHBOARD,
    },

]
