import React from 'react'
import { routerType } from '../../PublicNavigation'
import { KaryonModules } from '../../../../../../redux/user/userTypes'
import { PagesName } from '../../../types/pages'
import { SellInRetailMaquettes } from '../../../../../../pages/private/Retail/Sell-in/SellInMaquettes'


export const SellInRetailMaquettesRouter = (modules: KaryonModules[]): routerType[] => [
    {
        path: 'dashboard/',
        element: <SellInRetailMaquettes />,
        title: PagesName.DASHBOARD,
    },

]
