import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Grid, Tab } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react"
import { SecondaryText } from "./Text";

interface OwnProps {
    data: {
        id: string
        label: string
        title?: string
        children: JSX.Element
    }[]
}


export const DetailTab = ({ data }: OwnProps) => {
    const [onglet, setOnglet] = useState("1");

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setOnglet(newValue);
    };

    return (
        <Box sx={{ width: '100%' }}>
            <TabContext value={onglet} >
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleChange}>
                        {data.map((el) =>
                            <Tab label={el.label} value={el.id} key={el.id} />
                        )}
                    </TabList>
                </Box>
                {data.map((el) =>
                    <TabPanel value={`${el.id}`} key={el.id}>
                        <Grid>
                            {el.title && <SecondaryText>
                                {el.title}
                            </SecondaryText>}
                            {el.children}
                        </Grid>

                    </TabPanel>
                )}
            </TabContext>
        </Box>
    )
}