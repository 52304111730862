import { Grid } from '@mui/material'
import React from 'react'
import ChartBlock from '../../../../../components/features/ChartBlock'
import { useAppSelector } from '../../../../../redux/hooks'
import { getFSInformationsListConfig, MultipleCards, UniqueCard } from '../../Pages/Dashboard/FsLineChart'
import LineChartCross from '../../../../../components/features/foodService/cross/LineChartCross'

const FsCrossLineChart = () => {
    const { productsTopFlop } = useAppSelector(
        (state) => state.global.fsCrossDashboardData
    )
    const { unit } = useAppSelector((state) => state.global)
    const tooltipTop = "Croissance maximale enregistrée par rapport à l’année précédente sur la même période."
    const tooltipFlop = "Recul maximal (ou croissance minimale) enregistré par rapport à l'année précédente sur la même période."
    return (
        <ChartBlock
            title={`Ventes par famille par mois (${unit})`}
        >
            <Grid container wrap="nowrap" height="100%">
                {productsTopFlop.length > 1 ? (
                    <MultipleCards
                        top={productsTopFlop[0]}
                        flop={productsTopFlop[productsTopFlop.length - 1]}
                        informationsListConfigTop={getFSInformationsListConfig(productsTopFlop[0])}
                        informationsListConfigFlop={getFSInformationsListConfig(productsTopFlop[productsTopFlop.length - 1])}
                        unit={unit}
                        tooltipTop={tooltipTop}
                        tooltipFlop={tooltipFlop}
                    />
                ) : (
                    <UniqueCard data={productsTopFlop[0]}
                        unit={unit}
                        informationsListConfig={getFSInformationsListConfig(productsTopFlop[0])} tooltipTop={tooltipTop} />
                )}
                <Grid item flex={3} p="0px 1vw 1vw 1vw">
                    <LineChartCross />
                </Grid>
            </Grid>
        </ChartBlock>
    )
}

export default FsCrossLineChart
