import { Grid } from '@mui/material'
import React from 'react'
import { DARK_BLUE, WHITE } from '../ui/Colors'
import { PrimaryText } from '../ui/Text'

interface OwnProps {
    name: string
    large?: boolean,
    xs?: boolean,
}

const Avatar = ({ name, large = false, xs = false }: OwnProps) => {
    return (
        <Grid
            container
            justifyContent="center"
            alignItems="center"
            sx={{
                background: DARK_BLUE,
                borderRadius: 50,
                width: large ? '5vw' : xs ? "1.4vw" : '2.2vw',
                height: large ? '5vw' : xs ? "1.4vw" : '2.2vw',
            }}
        >
            <PrimaryText
                color={WHITE}
            >
                {name && name[0].toUpperCase()}
            </PrimaryText>
        </Grid>
    )
}

export default Avatar
