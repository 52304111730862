import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { AppThunk } from '../store'
import client from '../../api/config'
import { EditValuesFs } from '../../components/features/addDistributor/FsDialogContent'
import { EditValuesRetail } from '../../components/features/addDistributor/RetailDialogContent'
import { updateUser } from '../user/userSlice'
import { CompanyData, CompanyState, Member } from './companiesTypes'

const initialState: CompanyState = {
    company: {
        _id: "",
        name: '',
        brands: [''],
        members: [],
        logo: '',
        companyModules: [],
        env: [],
    },
    multiAccountCompanies: []
}

const companiesSlice = createSlice({
    name: 'companies',
    initialState,
    reducers: {
        setCompany: (state, action: PayloadAction<CompanyData>) => {
            state.company = action.payload
        },
        setMembers: (state, action: PayloadAction<Member[]>) => {
            state.company.members = action.payload
        },
        updateCompany: (
            state,
            action: PayloadAction<{ update: Partial<CompanyData> }>
        ) => {
            state.company = { ...state.company, ...action.payload.update }
        },
        setMultiAccountCompanies: (state, action: PayloadAction<any>) => {
            state.multiAccountCompanies = action.payload
        },
        updateMembers: (state, action: PayloadAction<{ data: Partial<Member> }>) => {

            const updateMembers = state.company.members.map((member) => {
                if (member.email === action.payload.data.email) {
                    return { ...member, ...action.payload.data }
                }
                return member
            })
            state.company.members = updateMembers
        }
    },
})
export const { setCompany, setMembers, updateCompany, setMultiAccountCompanies, updateMembers } = companiesSlice.actions

export const fetchCompany =
    (): AppThunk<Promise<void>> => async (dispatch, getState) => {
        try {
            const company = await client
                .get(`/companies`)
                .then((response) => {
                    return response.data
                })
                .catch((err) => {
                    console.log('ERROR : ', err)
                    return null
                })

            dispatch(setCompany(company))
        } catch (e) {
            console.log('ERROR : ', e)
        }
    }

export const fetchMembers =
    (): AppThunk<Promise<void>> => async (dispatch, getState) => {
        try {
            const members = await client
                .get(`/companies/members`)
                .then((response) => {
                    return response.data
                })
                .catch((err) => {
                    console.log('ERROR : ', err)
                    return null
                })
            dispatch(setMembers(members.members))
        } catch (e) {
            console.log('ERROR : ', e)
        }
    }

export const fetchMultiAccountCompanies =
    (): AppThunk<Promise<void>> => async (dispatch, getState) => {

        try {
            const multiAccountCompanies = await client
                .get(`/multiAccountCompanies`)
                .then((response) => {
                    return response.data
                })
                .catch((err) => {
                    console.log('ERROR : ', err)
                    return null
                })

            dispatch(setMultiAccountCompanies(multiAccountCompanies))
        } catch (e) {
            console.log('ERROR : ', e)
        }
    }

export const fetchUpdateFsDistributor =
    (
        update: Partial<CompanyData> & EditValuesFs
    ): AppThunk<Promise<CompanyData[]>> =>
        async (dispatch, getState) => {
            const dataToUpdate = {
                fs: {
                    distributed: !!update.distributerFS.length,
                    distributer: update.distributerFS,
                    data: update.dataFS,
                    hasOthers: update.hasOthersFS,
                },
            }

            try {
                const company = await client
                    .put(`/companies`, dataToUpdate)
                    .then((response) => {
                        dispatch(
                            updateCompany({
                                update: dataToUpdate,
                            })
                        )
                        dispatch(
                            updateUser({
                                update: dataToUpdate,
                            })
                        )
                        return response.data
                    })
                    .catch((err) => {
                        console.log('ERROR : ', err)
                        return []
                    })

                return company
            } catch (e) {
                console.log('ERROR : ', e)
                return {}
            }
        }

export const fetchUpdateRetailDistributor =
    (
        update: Partial<CompanyData> & EditValuesRetail
    ): AppThunk<Promise<CompanyData[]>> =>
        async (dispatch, getState) => {
            const dataToUpdate = {
                retail: {
                    distributed: !!update.distributerRetail.length,
                    distributer: update.distributerRetail,
                    data: update.dataRetail,
                    hasOthers: update.hasOthersRetail,
                },
            }

            try {
                const user = await client
                    .put(`/companies`, dataToUpdate)
                    .then((response) => {
                        dispatch(
                            updateCompany({
                                update: dataToUpdate,
                            })
                        )
                        dispatch(
                            updateUser({
                                update: dataToUpdate,
                            })
                        )
                        return response.data
                    })
                    .catch((err) => {
                        console.log('ERROR : ', err)
                        return []
                    })

                return user
            } catch (e) {
                console.log('ERROR : ', e)
                return {}
            }
        }

export default companiesSlice.reducer
