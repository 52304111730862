import React, { useCallback } from 'react'
import {
    Form,
    type FormikBag,
    type FormikProps,
    withFormik,
} from 'formik'
import { Dialog, DialogContent, Grid } from '@mui/material'
import { object, string } from 'yup'
import { UsersRole } from '../../../../redux/user/userTypes'
import { PrimaryText } from '../../../../components/ui/Text'
import { BLACK } from '../../../../components/ui/Colors'
import { DialogFooter } from '../../../../components/features/Dialog/BasicFooterDialog'
import { roles } from './utilsAdminPage'
import { DialogHeader } from '../../../../components/features/Dialog/BasicDialog'
import { RadioButtonsGroup } from '../../../../components/ui/RadioButtonsGroup'
import { useAppDispatch } from '../../../../redux/hooks'
import { UpdateUserRole } from '../../../../redux/user/userSlice'
import { Member } from '../../../../redux/companies/companiesTypes'

interface FormValues {
    role: UsersRole
}

interface FormProps {
    isDialogOpen: boolean
    setIsDialogOpen: any
    initialValues: FormValues
    onSubmit: (
        values: FormValues,
        bag: FormikBag<FormProps, FormValues>
    ) => void
    userValues: any,
}


const PureSettingsForm = ({
    isDialogOpen,
    setIsDialogOpen,
    userValues,
    ...props
}: FormikProps<FormValues> & FormProps): JSX.Element => {

    return (
        <Dialog
            open={isDialogOpen}
            fullWidth={true}
            onClose={() => setIsDialogOpen(false)}
            PaperProps={{
                sx: {
                    borderRadius: '1em',
                    minWidth: '55vw',
                    padding: '2vh 2vw 2vh 2vw',
                },
            }}
        >
            <DialogHeader
                title={`${userValues.firstname} ${userValues.lastname}`}
                setOpen={() => setIsDialogOpen(false)}
            />
            <Form style={{ width: '100%' }}>
                <DialogContent sx={{ height: '42vh' }}>
                    <PrimaryText color={BLACK} mb="3vh" mt="3vh" bold>Sélectionnez le rôle de l'utilisateur</PrimaryText>
                    <Grid>
                        <RadioButtonsGroup
                            onChange={(event, value) => {
                                props.setFieldValue('role', value)
                            }}
                            values={roles}
                            label={`Rôle de l'utilisateur`}
                            defaultCheck={userValues.role}
                        />
                    </Grid>
                </DialogContent>
                <DialogFooter
                    leftButtonFunc={() => {
                        setIsDialogOpen(false)
                    }
                    }
                    leftButtonText={'ANNULER'}
                    rightButtonFunc={async () => {
                        await props.submitForm()
                    }}
                    rightButtonText={
                        `MODIFIER LE RÔLE`
                    }
                />
            </Form>
        </Dialog>
    )
}

const ControlledSettingsForm = withFormik<FormProps, FormValues>({
    mapPropsToValues: (props) => ({
        role: props.initialValues.role,
    }),
    validationSchema: () => {
        return object().shape({
            role: string().required(),
        })
    },
    handleSubmit: (
        values: FormValues,
        bag: FormikBag<FormProps, FormValues>
    ) => {
        bag.props.onSubmit(values, bag)
    },
})(PureSettingsForm)

const UpdateRoleDialog = ({ isDialogOpen, setIsDialogOpen, values, closeMenu }: { isDialogOpen: boolean, setIsDialogOpen: React.Dispatch<React.SetStateAction<boolean>>, values: Member, closeMenu: React.Dispatch<React.SetStateAction<boolean>> }) => {
    const dispatch = useAppDispatch()
    const _UpdateUserRole = useCallback((email: string, role: UsersRole,) => dispatch(UpdateUserRole(email, role)), [dispatch])

    const handleSubmit = (formValues: FormValues) => {
        _UpdateUserRole(values.email, formValues.role)
        setIsDialogOpen(false)
        closeMenu(false)
    }
    return (
        <ControlledSettingsForm
            setIsDialogOpen={setIsDialogOpen}
            onSubmit={handleSubmit}
            initialValues={{
                role: values.role
            }}
            isDialogOpen={isDialogOpen}
            userValues={values}
        />
    )
}

export default UpdateRoleDialog
