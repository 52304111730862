import React, { useState } from 'react'
import { supabase } from '../../../supabaseClient'
import ErrorMessage from '../../../components/ui/ErrorMessage'
import Box from '@mui/material/Box'
import {
    Field,
    Form,
    type FormikBag,
    type FormikProps,
    withFormik,
} from 'formik'
import { string, object } from 'yup'
import { InputPasswordReveal, TextInput } from '../../../components/ui/Inputs'
import { Grid } from '@mui/material'
import PublicPage from '../../../components/features/GlobalPages/PublicPage'
import PublicForm from '../../../components/features/PublicForm'
import { PrimaryText } from '../../../components/ui/Text'
import { LinkButton, PrimaryButton } from '../../../components/ui/Button'
import { PRIMARY, RED } from '../../../components/ui/Colors'
import { Link, useLocation } from 'react-router-dom'
import { useAppSelector } from '../../../redux/hooks'

export const PASSWORD_LENGTH = 8

const EMAIL_NOT_CONFIRMED = 'Email not confirmed'
const URL_CHECKER = 'Email+link+is+invalid+or+has+expired'

interface FormValues {
    email: string
    password: string
}

interface FormProps {
    error: string
    onSubmit: (
        values: FormValues,
        bag: FormikBag<FormProps, FormValues>
    ) => void
}

const PureSignInForm = (
    props: FormikProps<FormValues> & FormProps
): JSX.Element => {
    return (
        <Form>
            <Grid container direction="column">
                <Field
                    sx={{
                        '& label': {
                            fontSize: '1.6vmin',
                        },
                    }}
                    type="text"
                    margin="normal"
                    label="Email"
                    name="email"
                    autoFocus
                    component={TextInput}
                />
                <InputPasswordReveal name="password" label="Mot de passe" />
                {props.error ? <ErrorMessage msg={props.error} /> : null}
                <Box mt={5} width="100%">
                    <PrimaryButton fullWidth type="submit">
                        Connexion
                    </PrimaryButton>
                </Box>
                <Box alignSelf="flex-end">
                    <LinkButton to="/recovery">
                        MOT DE PASSE OUBLIÉ ?
                    </LinkButton>
                </Box>
            </Grid>
        </Form>
    )
}

const ControlledSignInForm = withFormik<FormProps, FormValues>({
    mapPropsToValues: () => ({
        email: '',
        password: '',
    }),
    validationSchema: () => {
        return object().shape({
            email: string()
                .email('L’email doit être une adresse valide.')
                .required('Le champ email est obligatoire.'),
            password: string()
                .min(
                    PASSWORD_LENGTH,
                    `Le champ mot de passe doit contenir au moins ${PASSWORD_LENGTH} caractères.`
                )
                .required('Le champ mot de passe est obligatoire.'),
        })
    },
    handleSubmit: (
        values: FormValues,
        bag: FormikBag<FormProps, FormValues>
    ) => {
        bag.props.onSubmit(values, bag)
    },
})(PureSignInForm)

const Subtitle = () => {
    const path = useLocation()

    return (
        <Grid>
            <Grid container justifyContent="center">
                <PrimaryText mr={1}>
                    Vous ne possédez pas de compte ?
                </PrimaryText>
                <Link
                    to="/signup"
                    style={{
                        textDecoration: 'none',
                    }}
                    data-testid="inscriptionLink"
                >
                    <PrimaryText color={PRIMARY} fontWeight={700}>
                        Inscription
                    </PrimaryText>
                </Link>
            </Grid>
            {path.hash.toString().includes(URL_CHECKER) && (
                <PrimaryText color={RED} fontWeight={700} paddingTop={2}>
                    Le lien a expiré, veuillez vous connecter ou réinitialiser
                    votre mot de passe
                </PrimaryText>
            )}
        </Grid>
    )
}

export default function SignIn() {
    const [signError, setSignError] = useState<string | null>(null)
    const userErrorConnexion = useAppSelector((state) => state.user.error)

    const handleSubmit = async (
        values: FormValues,
        bag: FormikBag<FormProps, FormValues>
    ) => {
        const { error } = await supabase.auth.signInWithPassword({
            email: values.email,
            password: values.password,
        })

        if (error) {
            switch (error.message) {
                case EMAIL_NOT_CONFIRMED:
                    setSignError(
                        'Veuillez valider votre compte avant de vous authentifier.'
                    )
                    break
                default:
                    setSignError('Veuillez vérifier vos identifiants')
            }

            bag.setSubmitting(false)
        }

        if (userErrorConnexion) {
            setSignError("Nous ne parvenons pas à vous connecter. Si vous possédez un compte, contactez votre administrateur interne. Sinon, cliquez sur Inscription.")
        }
    }

    return (
        <PublicPage>
            <PublicForm title="Connectez-vous" subtitle={Subtitle()}>
                <ControlledSignInForm
                    onSubmit={handleSubmit}
                    error={signError}
                />
            </PublicForm>
        </PublicPage>
    )
}
