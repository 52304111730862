import { PeriodGap } from '../global/globalTypes'

export interface FsProductState {
    clients: Clients
    warehouses: Warehouses
    products: Products
    drillDownProducts: ProductItemFS[]
    drillDownProductsNotDistributed: ProductNotDistribuedItemFS[]
    drillDownWarehouses: WarehouseItem[]
    drillDownWarehousesNotDistributors: { id: string; warehouse: string }[]
    drillDownClients: ClientItem[]
    drillDownPromoClients: DrillDownPromoClients
    drillDownPromoInformations: DrillDownPromoInformations
    drillDownCategories: DrillDownCategories[]
}

export interface DrillDownCategories {
    id: string
    volume: number
    volumeLastYear: number
}
export interface DrillDownPromoInformations {
    sellOutKPI: {
        clients: number
        clientsLastYear: number
        vmm: number
        vmmLastYear: number
        volume: number
        volumeLastYear: number
        warehouses: number
        warehousesLastYear: number
    }
    promoKPI: {
        investmentPerVolume: number
        investmentPerVolumeLastYear: number
    }
    sellInKPI: {
        netAveragePrice: number
        netAveragePriceLastYear: number
    }
}
export interface DrillDownPromoClients {
    list: PromoItem[]
    top3Warehouses: Top3Warehouses[]
    topCategoryPerSubSubType: TopCategory[]
}
export interface TopCategory {
    subSubType: string
    category: string
    percentage: number
}
export interface Top3Warehouses {
    subSubType: string
    warehouses: { warehouseName: string; percentage: number }[]
}
export interface PromoItem {
    id: string
    type: string
    products: number
    clients: number
    clientsSubSubType: number
    clientsSubSubTypeLastYear: number
    warehouses: number
    warehousesLastYear: number
    productsLastYear: number
    volume: number
    volumeLastYear: number
    differenceWeight: number
    differenceProducts: number
    differenceWarehouses: number
    differenceClients: number
}

export interface Products {
    id: string
    name: string
    ean: string
    promo: number
    netSellIn: number
    volSellIn: number
    volSellOut: number
    percentageVolSellIn: number
    percentageVolSellOut: number
    percentageNetSellIn: number
    percentagePromo: number
}
export interface Segmentation {
    labels: string[]
    data: number[]
    clients?: number[]
    percentage: number[]
}

export enum SegmentationType {
    TYPE = 'type',
    SUB_TYPE = 'subType',
    SUB_SUB_TYPE = 'subSubType',
}

export type ClientSegmentationFFR = {
    [key in SegmentationType]: Segmentation
}

export type ClientSegmentationTGM = {
    type: Segmentation
}
export interface ClientItem {
    type: string
    products: number
    clients: number
    volume: number
    valuesUVC: number
    volumeLastYear: number
    oldValuesUVC: number
    differenceUVC: number
    differenceWeight: number
}
export interface CategoriesItem {
    id: string
    volume: number
    volumeLastYear: number
}

export interface Clients {
    segmentation: ClientSegmentationFFR | ClientSegmentationTGM
    list: ClientItem[]
    period: PeriodGap
}

export interface WarehouseItem {
    warehouse: string
    products: number
    clients: number
    volume: number
    valuesUVC: number
    volumeLastYear: number
    oldValuesUVC: number
    differenceUVC: number
    differenceWeight: number
    ca?: number
    evolCa?: number
}

export interface Warehouses {
    list: WarehouseItem[]
    period: PeriodGap
}

export interface Chart {
    name: string
    labels: string[]
    data: number[]
}

interface Legend {
    family: string
    subFamily: { label: string; products: string[] }[]
}

export type ProductsChart = {
    chart: Chart[]
    legend: Legend[]
}

export interface ProductItemFS {
    type: string
    product: number
    productName: string
    warehouses: string
    clients: number
    volume: number
    volumeLastYear: number
    oldValuesUVC: number
    differenceUVC: number
    differenceWeight: number
}

export interface ProductNotDistribuedItemFS {
    productName: string
    vmm: number
}

export interface Products {
    chart: ProductsChart
    list: ProductItemFS[]
    period: string[]
}
