import React, { useCallback } from 'react'
import {
    Form,
    type FormikBag,
    type FormikProps,
    withFormik,
} from 'formik'
import { Dialog, DialogContent } from '@mui/material'
import { object, string } from 'yup'
import { PrimaryText, SecondaryText } from '../../../../components/ui/Text'
import { BLACK } from '../../../../components/ui/Colors'
import { DialogFooter } from '../../../../components/features/Dialog/BasicFooterDialog'
import { DialogHeader } from '../../../../components/features/Dialog/BasicDialog'
import { useAppDispatch } from '../../../../redux/hooks'
import { updateUserStatus } from '../../../../redux/user/userSlice'
import { UsersStatus } from '../../../../redux/user/userTypes'
import { Member } from '../../../../redux/companies/companiesTypes'

interface FormValues {

}

interface FormProps {
    isDialogOpen: boolean
    setIsDialogOpen: any
    onSubmit: () => void
    userValues: any,
}


const PureSettingsForm = ({
    isDialogOpen,
    setIsDialogOpen,
    userValues,
    ...props
}: FormikProps<FormValues> & FormProps): JSX.Element => {

    return (
        <Dialog
            open={isDialogOpen}
            fullWidth={true}
            onClose={() => setIsDialogOpen(false)}
            PaperProps={{
                sx: {
                    borderRadius: '1em',
                    minWidth: '55vw',
                    padding: '2vh 2vw 2vh 2vw',
                },
            }}
        >
            <DialogHeader
                title={`${userValues.firstname} ${userValues.lastname}`}
                setOpen={() => setIsDialogOpen(false)}
            />
            <Form style={{ width: '100%' }}>
                <DialogContent sx={{ height: '42vh' }}>
                    <PrimaryText color={BLACK} mb="3vh" mt="3vh" bold>{`Êtes-vous sûr de vouloir désactiver le compte KaryonFood de ${userValues.firstname} ${userValues.lastname} ?`}</PrimaryText>
                    <SecondaryText>Si vous valider la désactivation, la personne n'aura plus accès à son compte</SecondaryText>
                </DialogContent>
                <DialogFooter
                    leftButtonFunc={() => {
                        setIsDialogOpen(false)
                    }
                    }
                    leftButtonText={'ANNULER'}
                    rightButtonFunc={async () => {
                        await props.submitForm()
                    }}
                    rightButtonText={
                        `DÉSACTIVER L'UTILISATEUR`
                    }
                />
            </Form>
        </Dialog>
    )
}

const ControlledSettingsForm = withFormik<FormProps, FormValues>({
    mapPropsToValues: (props) => ({

    }),
    validationSchema: () => {
        return object().shape({
            email: string()
        })
    },
    handleSubmit: (
        value: string,
        bag: FormikBag<FormProps, FormValues>
    ) => {
        bag.props.onSubmit()
    },
})(PureSettingsForm)

const UpdateStatusDialog = ({ isDialogOpen, setIsDialogOpen, values, closeMenu }: { isDialogOpen: boolean, setIsDialogOpen: React.Dispatch<React.SetStateAction<boolean>>, values: Member, closeMenu: React.Dispatch<React.SetStateAction<boolean>> }) => {
    const dispatch = useAppDispatch()

    const _disableMember = useCallback((email: string) => dispatch(updateUserStatus(email, UsersStatus.DEACTIVATED)), [dispatch])

    const handleSubmit = () => {

        _disableMember(values.email)
        setIsDialogOpen(false)
        closeMenu(false)
    }
    return (
        <ControlledSettingsForm
            setIsDialogOpen={setIsDialogOpen}
            onSubmit={handleSubmit}
            isDialogOpen={isDialogOpen}
            userValues={values}
        />
    )
}

export default UpdateStatusDialog
