// IMAGES
import Auchan from '../assets/retailLogo/auchan.jpg'
import Intermarché from '../assets/retailLogo/intermarche.jpg'
import Monoprix from '../assets/retailLogo/monoprix-logo.jpg'
import Casino from '../assets/retailLogo/casino.png'
import Franprix from '../assets/retailLogo/franprix.png'
import MagasinsU from '../assets/retailLogo/MagasinsU.jpg'
import Carrefour from '../assets/retailLogo/Carrefour.png'
import Leclerc from '../assets/retailLogo/leclerc.jpg'
import Cora from '../assets/retailLogo/cora.jpg'
import Carrefourmarket from '../assets/retailLogo/CM.jpg'
import Metro from '../assets/FSlogo/metro.png'
import FranceFrais from '../assets/FSlogo/france-frais-logo.svg'
import ProaPro from '../assets/FSlogo/proapro.png'
import Distriboissons from '../assets/FSlogo/logo-distriboissons.png'
import Sysco from '../assets/FSlogo/Sysco-Logo.svg.png'
import Vivalya from '../assets/FSlogo/thumbnail_logo-vivalya-.jpg'
import C10 from '../assets/FSlogo/logo-c10.png'
import Transgourmet from '../assets/FSlogo/logo-TGM.png'
import Episaveurs from '../assets/FSlogo/epiSaveurs.png'
import RelaisdOr from "../assets/FSlogo/Logo Relais d'OrV2.png"
import donutIcon from '../assets/icon/donutIcon.svg'
import PassionFroid from '../assets/FSlogo/passionFroid.png'
import Sodexo from '../assets/FSlogo/sodexo.png'
import CercleVert from '../assets/FSlogo/Logo Cercle Vert.jpg'
import FranceBoissons from '../assets/FSlogo/Logo_France_Boissons.svg'
import SellIn from '../assets/FSlogo/truck.svg'
import GBH from '../assets/retailLogo/logo-gbh.png'

export const retailDistributor = [
    'Auchan',
    'Carrefour',
    'Carrefourmarket',
    'Casino',
    'Cora',
    'Franprix',
    'Intermarché',
    'Leclerc',
    'Monoprix',
    'Magasins U',
    'sell-in',
    'GBH'
]

export const fsDistributor = [
    'C10',
    'Distriboissons',
    'France Frais',
    'Metro',
    'PassionFroid',
    'Pro à pro',
    "Relais d'Or",
    'Sysco',
    'Transgourmet',
    'Transgourmet plus',
    'Vivalya',
    'EpiSaveurs',
    'Sodexo',
    'Cercle vert',
    'FranceBoissons'
]

export const distributorImages = {
    Auchan: Auchan,
    Carrefour: Carrefour,
    Intermarché: Intermarché,
    Monoprix: Monoprix,
    Casino: Casino,
    Franprix: Franprix,
    'Magasins U': MagasinsU,
    Leclerc: Leclerc,
    Cora: Cora,
    Carrefourmarket: Carrefourmarket,
    'France Frais': FranceFrais,
    Sysco: Sysco,
    Transgourmet: Transgourmet,
    'Transgourmet plus': Transgourmet,
    EpiSaveurs: Episaveurs,
    PassionFroid: PassionFroid,
    Metro: Metro,
    'Pro à pro': ProaPro,
    Distriboissons: Distriboissons,
    Vivalya: Vivalya,
    C10: C10,
    "Relais d'Or": RelaisdOr,
    Commandes: donutIcon,
    Sodexo: Sodexo,
    'Cercle vert': CercleVert,
    'France Boissons': FranceBoissons,
    'Sell-in': SellIn,
    GBH: GBH
}

export const MINIMUM_CHARACTER_FOR_OTHERS = 1
