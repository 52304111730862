import React from 'react'
import { Box, Grid, Link } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'
import KaryonLogo from '../../../assets/Logo-KaryonFood-Normal.png'
import { LIGHT_GREY, WHITE } from '../../ui/Colors'
import { PrimaryText, SecondaryText } from '../../ui/Text'
import Illustration from '../../../assets/sign-in.gif'

interface OwnProps {
    children: JSX.Element
    staticComponent?: JSX.Element
}

const Logo = () => {
    return (
        <Box position="absolute" top="1em">
            <RouterLink to="/">
                <Box
                    component="img"
                    sx={{ width: { xs: '150px', md: '12vw' } }}
                    src={KaryonLogo}
                    alt="Logo Karyon"
                />
            </RouterLink>
        </Box>
    )
}

const Copyright = () => {
    return (
        <Box
            width="20vw"
            position="absolute"
            bottom={5}
            sx={{ right: { xs: 'calc(50% - 10vw)', md: 'calc(75% - 10vw)' } }}
        >
            <SecondaryText>
                {'Copyright © '}
                <Link color="inherit" href="https://www.karyonfood.com/">
                    KaryonFood
                </Link>
                {new Date().getFullYear()}
            </SecondaryText>
        </Box>
    )
}

const LeftSide = ({ staticComponent }: { staticComponent?: JSX.Element }) => {
    return (
        <Grid
            container
            direction="column"
            justifyContent="center"
            wrap="nowrap"
            style={{ background: LIGHT_GREY, height: '100%' }}
        >
            <Logo />
            <Grid
                item
                style={{ maxWidth: '80%', margin: 'auto', textAlign: 'center' }}
            >
                {staticComponent || (
                    <Box>
                        <PrimaryText textAlign="center" mb={8}>
                            Pilotez les données de tous vos distributeurs
                        </PrimaryText>
                        <img
                            src={Illustration}
                            alt="Illustration"
                            style={{ width: '45vw', height: '50vh' }}
                        />
                    </Box>
                )}
                <Copyright />
            </Grid>
        </Grid>
    )
}

const PublicPage = ({ children, staticComponent }: OwnProps) => {
    return (
        <Grid container height="100vh">
            <Grid item flex={1} sx={{ display: { xs: 'none', md: 'block' } }}>
                <LeftSide staticComponent={staticComponent} />
            </Grid>
            <Grid
                container
                flex={1}
                sx={{
                    background: WHITE,
                    paddingBottom: { xs: '40px', md: 0 },
                    position: { xs: 'relative', md: 'unset' },
                }}
            >
                <Grid item sx={{ display: { xs: 'block', md: 'none' } }}>
                    <Logo />
                </Grid>
                <Grid
                    item
                    sx={{
                        width: { xs: '80%', md: '60%' },
                        margin: 'auto',
                        marginTop: { xs: '80px', md: 'auto' },
                    }}
                >
                    {children}
                </Grid>
            </Grid>
        </Grid>
    )
}

export default PublicPage
