import React from 'react'
import { Navigate, useParams } from 'react-router-dom'
import { useAppSelector } from '../../../redux/hooks'
import PrivatePage from '../GlobalPages/Private/PrivatePage'
import Loading from '../screens/LoadingScreen'
import { ViewsLayout } from './ViewLayout'
import Filters, { FiltersType } from '../filters/Filters'


export const ViewPage = () => {
    const { viewId } = useParams()
    const { views } = useAppSelector((state) => state.views)
    const view = views.length > 0 ? views.find((view) => view._id === viewId) : null

    if (views.length === 0) {
        return <PrivatePage><Loading /></PrivatePage>
    }

    if (!view) {
        return <Navigate to="/" />
    } else {
        return (
            <PrivatePage title={view.name} period onViewPage>
                <Filters filtersType={FiltersType.VIEWSFS} filters={view.filters} />
                <ViewsLayout view={view} />
            </PrivatePage >)

    }
}
